import { useCallback, useState } from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useSelector } from "react-redux";
import { StaticHeightDialogContent } from "../../styles/assets/ChooseAssetImagePage.styles";
import { useTranslation } from "react-i18next";
import { getTranslation, showValidationError } from "../../../util/utils";
import { messageError, messageSuccess } from "../../../util/notification";
import { selectPageInfo } from "../../../store/slices/appSlice";
import {
  CancelButton,
  CreateButton,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { selectUser } from "../../../store/slices/authSlice";
import ErrorHandling from "../../common/ErrorHandling";
import { useDispatch } from "react-redux";
import ResourceAttachmentForm from "./ResourceAttachmentForm";
import {
  addResourceAttachment,
  DEFAULT_RESOURCE_ATTACHMENT,
} from "../../../store/slices/resourceAttachmentSlice";
import { useUploadResourceAttachmentMutation } from "../../../store/slices/api/resourceAttachmentsApiSlice";

const AddLocalAttachmentDialog = ({ resourceId, open, setOpen }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const { isActionButtonDisabled } = useSelector(selectPageInfo);

  // States
  const [resourceAttachmentInput, setResourceAttachmentInput] = useState(
    DEFAULT_RESOURCE_ATTACHMENT
  );

  const [name, setName] = useState("");
  const [extension, setExtension] = useState("");

  // Mutations
  const [uploadResourceAttachment, { isLoading: isLoadingUpload }] =
    useUploadResourceAttachmentMutation();

  // Handlers
  const handleClose = () => {
    setOpen(false);
    setResourceAttachmentInput(DEFAULT_RESOURCE_ATTACHMENT);
  };

  const handleSubmit = useCallback(async () => {
    try {
      if (!resourceAttachmentInput.selectedFile) {
        messageError(getTranslation("UPLOAD_IMAGE", t, i18n));
        return;
      }

      if (resourceId) {
        const formData = new FormData();

        const { file, description } = resourceAttachmentInput;

        formData.append("file", file);
        formData.append("fileName", name + extension);
        formData.append("description", description);

        await uploadResourceAttachment({
          formData,
          resourceid: resourceId,
          organizationId,
        }).unwrap();

        messageSuccess(
          getTranslation("RESOURCE_ATTACHMENT_CREATED_SUCCESSFULLY", t, i18n)
        );
      } else {
        const input = {
          ...resourceAttachmentInput,
          name: name + extension,
        };

        dispatch(addResourceAttachment(input));
      }
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to upload resource attachment", error);
    }

    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceAttachmentInput, name]);

  return (
    <ErrorHandling isLoading={isLoadingUpload}>
      <Dialog
        id="add-local-attachment-dialog"
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle>{getTranslation("ADD_ATTACHMENT", t, i18n)}</DialogTitle>
        <StaticHeightDialogContent>
          <ResourceAttachmentForm
            resourceAttachmentInput={resourceAttachmentInput}
            setResourceAttachmentInput={setResourceAttachmentInput}
            name={name}
            setName={setName}
            setExtension={setExtension}
          />
        </StaticHeightDialogContent>
        <DialogActions>
          <CancelButton variant="outlined" onClick={handleClose}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelButton>
          <CreateButton
            disabled={isActionButtonDisabled || isLoadingUpload}
            onClick={handleSubmit}
          >
            {getTranslation("SUBMIT", t, i18n)}
          </CreateButton>
        </DialogActions>
      </Dialog>
    </ErrorHandling>
  );
};

export default AddLocalAttachmentDialog;
