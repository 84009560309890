import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import {
  MIN_STEP,
  nextWizardStep,
  previousWizardStep,
  resetWidgets,
  resetWizard,
  selectDescription,
  selectName,
  selectQuery,
  selectReportingFields,
  selectResultFields,
  selectTypeId,
  selectWidgets,
  selectWizardStep,
  setDescription,
  setName,
  setQuery,
  setReportDefinitionAggregations,
  setReportingFields,
  setResultFields,
  setWidgets,
} from "../../../store/slices/reportsSlice";
import {
  useCreateReportDefinitionFilterMutation,
  useGetReportDefinitionFiltersQuery,
  useGetReportDefinitionWidgetsQuery,
  useReportingFieldsByTypeQuery,
  useUpdateReportDefinitionAggregationsMutation,
  useUpdateReportDefinitionFieldsMutation,
  useUpdateReportDefinitionFilterMutation,
  useUpdateReportDefinitionMutation,
  useUpdateReportDefinitionWidgetsMutation,
} from "../../../store/slices/api/reportsApiSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { getTranslation } from "../../../util/utils";
import {
  WIDGET_TYPE,
  addPropertyToChildren,
  checkQueryValidity,
  constructAggregations,
  constructQuery,
  parseQuery,
} from "../../../util/reports-utils";
import ReportsWizardTimeline from "../create-report/ReportsWizardTimeline";
import BasicDataWizardStep from "../create-report/BasicDataWizardStep";
import DefineQueryWizardStep from "../create-report/DefineQueryWizardStep";
import FormatResultWizardStep from "../create-report/FormatResultWizardStep";
import AddWidgetWizardStep from "../create-report/AddWidgetWizardStep";
import { messageError } from "../../../util/notification";
import {
  CreateReportTypography,
  CreateReportWrapper,
  ReportWizardTimeLineContainer,
  ReportsWizardBackButton,
  ReportsWizardContinueButton,
  ReportsWizardGridContainer,
  ReportsWizardRightSectionGridContainer,
  SaveAndContinueWrapper,
} from "../../styles/reports/Reports.styles";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";

const UpdateReportsWizard = ({
  handleClose,
  reportDefinitionData,
  reportDefinitionFields,
  reportDefinitionAggregations,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const wizardStep = useSelector(selectWizardStep);
  const name = useSelector(selectName);
  const description = useSelector(selectDescription);
  const reportingFields = useSelector(selectReportingFields);
  const resultFields = useSelector(selectResultFields);
  const query = useSelector(selectQuery);
  const typeId = useSelector(selectTypeId);
  const widgets = useSelector(selectWidgets);

  // States
  const [openAlertWidgets, setOpenAlertWidgets] = useState(false);
  const [openAlertAggregations, setOpenAlertAggregations] = useState(false);

  // Other variables
  const alert = {
    content: getTranslation("WIDGETS_WARNING", t, i18n),
    confirmTitle: getTranslation("CONFIRM_WIDGETS_WARNING", t, i18n),
    closeTitle: getTranslation("CANCEL_WIDGETS_WARNING", t, i18n),
    showConfirm: true,
  };

  const alertAggregations = {
    content: getTranslation("AGGREGATIONS_WARNING", t, i18n),
    confirmTitle: getTranslation("CONFIRM_AGGREGATIONS_WARNING", t, i18n),
    closeTitle: getTranslation("CANCEL_AGGREGATIONS_WARNING", t, i18n),
    showConfirm: true,
  };

  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const filters = [parseQuery(query, reportingFields)];
  const isQueryValid = checkQueryValidity(query);
  const continueButtonDisabled =
    !name || resultFields?.some((field) => !field.displayName);

  // Mutations
  const [
    createReportDefinitionFilter,
    { isLoading: isLoadingCreateReportDefinitionFilter },
  ] = useCreateReportDefinitionFilterMutation();
  const [
    updateReportDefinition,
    { isLoading: isLoadingUpdateReportDefinition },
  ] = useUpdateReportDefinitionMutation();
  const [
    updateReportDefinitionFilter,
    { isLoading: isLoadingUpdateReportDefinitionFilter },
  ] = useUpdateReportDefinitionFilterMutation();
  const [
    updateReportDefinitionFields,
    { isLoading: isLoadingUpdateReportDefinitionFields },
  ] = useUpdateReportDefinitionFieldsMutation();
  const [
    updateReportDefinitionAggregations,
    { isLoading: isLoadingUpdateReportDefinitionAggregations },
  ] = useUpdateReportDefinitionAggregationsMutation();
  const [
    updateReportDefinitionWidgets,
    { isLoading: isLoadingUpdateReportDefinitionWidgets },
  ] = useUpdateReportDefinitionWidgetsMutation();

  // Queries
  const { data: reportingFieldsData } = useReportingFieldsByTypeQuery({
    organizationId,
    typeId: reportDefinitionData?.reportTypeId,
  });

  const { data: reportDefinitionFilterData } =
    useGetReportDefinitionFiltersQuery({
      organizationId,
      reportDefinitionId: reportDefinitionData?.id,
    });

  const { data: reportDefinitionWidgetsData } =
    useGetReportDefinitionWidgetsQuery({
      organizationId,
      reportDefinitionId: reportDefinitionData?.id,
    });

  // Handlers
  const getFormatResultData = () => {
    let resultReportDefinitionFields = [];
    let resultReportDefinitionAggregations = [];

    for (let i = 0; i < resultFields.length; i++) {
      const {
        field,
        usedForGrouping,
        aggregationTypes,
        orderIndex,
        sortMethod,
        displayName,
      } = resultFields[i];

      const reportingField = reportingFields.find(
        (reportingField) => reportingField.name === field
      );

      if (!reportingField) {
        continue;
      }

      const { id: reportFieldId } = reportingField;

      const existingReportDefinitionField = resultReportDefinitionFields.find(
        (f) => f.fieldId === reportFieldId
      );

      if (!Boolean(existingReportDefinitionField)) {
        const reportDefinitionField = {
          fieldId: reportFieldId,
          usedForGrouping,
          orderIndex,
          sortMethod,
          index: i,
          displayName,
        };

        aggregationTypes.forEach((aggregationType) => {
          resultReportDefinitionAggregations.push({
            fieldId: reportFieldId,
            aggregationType,
          });
        });

        resultReportDefinitionFields.push(reportDefinitionField);
      } else {
        if (usedForGrouping) {
          existingReportDefinitionField.usedForGrouping = usedForGrouping;
        }
      }
    }

    return {
      resultReportDefinitionAggregations,
      resultReportDefinitionFields,
    };
  };

  const handleMoveBack = () => dispatch(previousWizardStep());

  const handleMoveNext = async () => {
    try {
      if (wizardStep === 1) {
        const reportDefinition = {
          reportTypeId: typeId,
          name,
          description,
        };

        await updateReportDefinition({
          organizationId,
          reportDefinitionId: reportDefinitionData.id,
          reportDefinition,
        }).unwrap();
      } else if (wizardStep === 2) {
        const modifiedFilters = addPropertyToChildren(
          filters,
          "reportDefinitionId",
          reportDefinitionData.id
        );

        for (let i = 0; i < modifiedFilters.length; i++) {
          const { logicalOperator, conditions, children } = modifiedFilters[i];
          const reportDefinitionFilter = {
            logicalOperator,
            conditions,
            children,
          };

          if (reportDefinitionFilterData.length >= 1) {
            await updateReportDefinitionFilter({
              organizationId,
              reportDefinitionId: reportDefinitionData.id,
              reportDefinitionFilterId: reportDefinitionFilterData[i].id,
              reportDefinitionFilter,
            }).unwrap();
          } else {
            await createReportDefinitionFilter({
              organizationId,
              reportDefinitionId: reportDefinitionData.id,
              reportDefinitionFilter,
            }).unwrap();
          }
        }
      } else if (wizardStep === 3) {
        const {
          resultReportDefinitionFields,
          resultReportDefinitionAggregations,
        } = getFormatResultData();

        if (
          !resultFields?.some((field) => field?.aggregationTypes?.length > 0)
        ) {
          handleOpenAlertAggregations();
          return;
        }

        const hasWidgets = reportDefinitionWidgetsData?.length > 0;

        if (hasWidgets) {
          // Check by groups
          const oldGroupFieldsLength = reportDefinitionFields.filter(
            (f) => f.usedForGrouping
          ).length;
          const newGroupFieldsLength = resultReportDefinitionFields.filter(
            (f) => f.usedForGrouping
          ).length;

          const groupFieldsAtFirstPlace =
            oldGroupFieldsLength >= 1 && newGroupFieldsLength === 0;
          const noGroupFieldsAtFirstPlace =
            oldGroupFieldsLength === 0 && newGroupFieldsLength >= 1;

          const groupFieldsCheck =
            groupFieldsAtFirstPlace || noGroupFieldsAtFirstPlace;

          // check some of the aggregations in data source are not available in new aggregations
          const hasMissingAggregationsInWidgets =
            reportDefinitionWidgetsData.some((widget) =>
              widget.dataSources.some((dataSource) => {
                const dbAggregation = reportDefinitionAggregations.find(
                  (dbAgg) =>
                    dbAgg.id === dataSource.reportDefinitionAggregationId
                );

                return !resultReportDefinitionAggregations.some(
                  (resultAgg) =>
                    dbAggregation?.aggregationType === resultAgg.aggregationType
                );
              })
            );

          const dependentWidgets =
            groupFieldsCheck || hasMissingAggregationsInWidgets;

          // Check by aggregations
          if (dependentWidgets) {
            handleOpenAlertWidgets();
            return;
          }
        }

        await updateReportDefinitionFields({
          organizationId,
          reportDefinitionId: reportDefinitionData.id,
          reportDefinitionFields: resultReportDefinitionFields,
        }).unwrap();

        const response = await updateReportDefinitionAggregations({
          organizationId,
          reportDefinitionId: reportDefinitionData.id,
          reportDefinitionAggregations: resultReportDefinitionAggregations,
        }).unwrap();

        dispatch(setReportDefinitionAggregations(response));
      } else if (wizardStep === 4) {
        const widgetsPayload = widgets?.map((widget) => {
          const { title, widgetTypeId, dataSources } = widget;

          return {
            title,
            widgetTypeId,
            dataSources: dataSources?.map((ds, index) => {
              const { reportDefinitionAggregationId, color, paletteId } = ds;

              return {
                reportDefinitionAggregationId,
                index,
                color,
                paletteId,
              };
            }),
          };
        });

        await updateReportDefinitionWidgets({
          organizationId,
          reportDefinitionId: reportDefinitionData.id,
          widgets: widgetsPayload,
        }).unwrap();

        dispatch(resetWizard());
        handleClose();
        return;
      }

      dispatch(nextWizardStep());
    } catch (error) {
      console.error("Error moving next", error);
      messageError(error?.data?.message);
    }
  };

  const renderWizardContent = () => {
    if (wizardStep === 1) {
      return <BasicDataWizardStep showPrivate={false} />;
    } else if (wizardStep === 2) {
      return <DefineQueryWizardStep />;
    } else if (wizardStep === 3) {
      return <FormatResultWizardStep />;
    } else if (wizardStep === 4) {
      return <AddWidgetWizardStep />;
    }

    return <p>Invalid wizard step</p>;
  };

  const handleOpenAlertWidgets = () => {
    setOpenAlertWidgets(true);
  };

  const handleOpenAlertAggregations = () => {
    setOpenAlertAggregations(true);
  };

  // Its for 3rd step of the wizard
  const handleConfirm = async () => {
    try {
      const {
        resultReportDefinitionFields,
        resultReportDefinitionAggregations,
      } = getFormatResultData();

      await updateReportDefinitionFields({
        organizationId,
        reportDefinitionId: reportDefinitionData.id,
        reportDefinitionFields: resultReportDefinitionFields,
      }).unwrap();

      await updateReportDefinitionAggregations({
        organizationId,
        reportDefinitionId: reportDefinitionData.id,
        reportDefinitionAggregations: resultReportDefinitionAggregations,
      }).unwrap();

      await updateReportDefinitionWidgets({
        organizationId,
        reportDefinitionId: reportDefinitionData.id,
        widgets: [],
      });
      dispatch(resetWidgets());
      dispatch(nextWizardStep());
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmAggregations = async () => {
    try {
      const {
        resultReportDefinitionFields,
        resultReportDefinitionAggregations,
      } = getFormatResultData();

      await updateReportDefinitionFields({
        organizationId,
        reportDefinitionId: reportDefinitionData.id,
        reportDefinitionFields: resultReportDefinitionFields,
      }).unwrap();

      await updateReportDefinitionAggregations({
        organizationId,
        reportDefinitionId: reportDefinitionData.id,
        reportDefinitionAggregations: resultReportDefinitionAggregations,
      }).unwrap();

      await updateReportDefinitionWidgets({
        organizationId,
        reportDefinitionId: reportDefinitionData.id,
        widgets: [],
      });

      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // First Step
    dispatch(setDescription(reportDefinitionData.description));
    dispatch(setName(reportDefinitionData.name));

    // Second Step
    // filters
    if (reportDefinitionFilterData && reportDefinitionFilterData.length > 0) {
      const constructedQuery = constructQuery(reportDefinitionFilterData[0]);
      dispatch(setQuery(constructedQuery));
    }

    // Third step
    // fields

    if (reportingFieldsData) {
      const formattedResultFields = reportDefinitionFields
        ?.slice()
        ?.sort((a, b) => a.index - b.index)
        .map((reportDefinitionField) => {
          const {
            field: { id: reportDefinitionFieldId },
            displayName,
            usedForGrouping,
            orderIndex,
            sortMethod,
          } = reportDefinitionField;
          const reportingField = reportingFieldsData.find(
            (f) => f.id === reportDefinitionFieldId
          );

          const aggregationTypes = reportDefinitionAggregations
            .filter((aggregation) => aggregation.field.id === reportingField.id)
            .map((a) => a.aggregationType);

          return {
            uniqueId: uuidv4(),
            displayName: getTranslation(
              displayName ?? reportingField.name,
              t,
              i18n
            ),
            field: reportingField.name,
            orderIndex,
            sortMethod,
            usedForGrouping: usedForGrouping,
            aggregationTypes,
          };
        });

      const formattedReportingFields = reportingFieldsData.map((field) => {
        return {
          id: field.id,
          name: field.name,
          label: field.name,
          validator: ({ value }) => Boolean(value),
        };
      });

      dispatch(setReportingFields(formattedReportingFields));
      dispatch(setResultFields(formattedResultFields));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportDefinitionData,
    reportingFieldsData,
    reportDefinitionFields,
    reportDefinitionAggregations,
    reportDefinitionFilterData,
    reportDefinitionWidgetsData,
  ]);

  useEffect(() => {
    if (reportDefinitionAggregations) {
      dispatch(setReportDefinitionAggregations(reportDefinitionAggregations));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDefinitionAggregations]);

  useEffect(() => {
    let constructedAggregations = [];
    if (resultFields) {
      constructedAggregations = constructAggregations(resultFields);
    }

    if (
      reportDefinitionWidgetsData &&
      reportDefinitionAggregations &&
      constructedAggregations.length > 0
    ) {
      const mappedWidgets = reportDefinitionWidgetsData.map((widget) => {
        // sort the dataSources ascending by index
        const clonedDataSources = widget.dataSources
          ?.slice()
          ?.sort((a, b) => a.index - b.index);

        const palette = clonedDataSources[0].paletteName.toLowerCase();

        // in widgetForm aggregations have generated ids so we need to map loaded data in similar structure
        const mappedDataSources = clonedDataSources.map((ds, index) => {
          const dbAggregation = reportDefinitionAggregations.find(
            (a) => a.id === ds.reportDefinitionAggregationId
          );
          const constructedAggregation = constructedAggregations?.find(
            (localAggregation) =>
              localAggregation?.field?.name === dbAggregation?.field?.name &&
              localAggregation?.aggregationType ===
                dbAggregation?.aggregationType
          );

          return {
            ...ds,
            generatedAggregationId: constructedAggregation?.id,
            uniqueId: uuidv4(),
          };
        });

        let resultWidget = {
          title: widget.title,
          widgetTypeId: widget.widgetTypeId,
          palette,
          aggregationId: "",
          maxAggregationId: "",
          avgAggregationId: "",
          minAggregationId: "",
          aggregationIds: mappedDataSources.map(
            (ds) => ds.generatedAggregationId
          ),
          dataSources: mappedDataSources,
        };

        if (widget.widgetTypeId === WIDGET_TYPE.MIN_MAX_AVERAGE) {
          resultWidget = {
            ...resultWidget,
            maxAggregationId: mappedDataSources[0]?.generatedAggregationId,
            avgAggregationId: mappedDataSources[1]?.generatedAggregationId,
            minAggregationId: mappedDataSources[2]?.generatedAggregationId,
          };
        } else if (
          widget.widgetTypeId === WIDGET_TYPE.SINGLE_COUNT ||
          widget.widgetTypeId === WIDGET_TYPE.SUM
        ) {
          resultWidget = {
            ...resultWidget,
            aggregationId: mappedDataSources[0]?.generatedAggregationId,
          };
        } else if (widget.widgetTypeId === WIDGET_TYPE.FUNNEL_CHART) {
          resultWidget = {
            ...resultWidget,
            aggregationId: mappedDataSources[0]?.generatedAggregationId,
          };
        }

        return resultWidget;
      });

      dispatch(setWidgets(mappedWidgets));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDefinitionWidgetsData, resultFields]);

  const isLoading =
    isLoadingCreateReportDefinitionFilter ||
    isLoadingUpdateReportDefinition ||
    isLoadingUpdateReportDefinitionFilter ||
    isLoadingUpdateReportDefinitionFields ||
    isLoadingUpdateReportDefinitionAggregations ||
    isLoadingUpdateReportDefinitionWidgets;

  return (
    <>
      <ConfirmAlert
        isOpen={openAlertWidgets}
        setIsOpen={setOpenAlertWidgets}
        alert={alert}
        label="loose-widgets"
        handleConfirm={handleConfirm}
      />

      <ConfirmAlert
        isOpen={openAlertAggregations}
        setIsOpen={setOpenAlertAggregations}
        alert={alertAggregations}
        label="aggregations-alert"
        handleConfirm={handleConfirmAggregations}
      />

      <Box>
        <ReportsWizardGridContainer container>
          <Grid item xs={10}>
            <CreateReportWrapper>
              <CreateReportTypography variant="h5">
                {getTranslation("UPDATE_REPORT", t, i18n)}
              </CreateReportTypography>
              {renderWizardContent()}
            </CreateReportWrapper>
          </Grid>

          <ReportsWizardRightSectionGridContainer item xs={2}>
            <ReportWizardTimeLineContainer>
              <Box>
                <ReportsWizardTimeline isEdit={true} />
              </Box>

              <SaveAndContinueWrapper>
                {wizardStep > MIN_STEP && (
                  <ReportsWizardBackButton
                    id="back"
                    disabled={wizardStep <= MIN_STEP}
                    onClick={handleMoveBack}
                    variant="outlined"
                  >
                    {getTranslation("back", t, i18n)}
                  </ReportsWizardBackButton>
                )}

                <ReportsWizardContinueButton
                  id="save"
                  disabled={
                    (!isQueryValid && wizardStep === 2) ||
                    continueButtonDisabled ||
                    isLoading
                  }
                  onClick={handleMoveNext}
                  variant="contained"
                  endIcon={isLoading && <CircularProgress size="20px" />}
                >
                  {getTranslation("SAVE_AND_CONTINUE", t, i18n)}
                </ReportsWizardContinueButton>
              </SaveAndContinueWrapper>
            </ReportWizardTimeLineContainer>
          </ReportsWizardRightSectionGridContainer>
        </ReportsWizardGridContainer>
      </Box>
    </>
  );
};

export default UpdateReportsWizard;
