import { Box, Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import { messageError } from "../../util/notification";
import {
  ALLOWED_EXTENSIONS,
  DEFAULT_IMAGE_SIZE,
  getNormalizedFile,
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
} from "../../util/utils";
import fileTypeChecker from "file-type-checker";
import CropEasy from "../CropEasy";
import {
  DesktopProfileAvatar,
  DesktopProfileAvatarContainer,
  RemoveImageButton,
} from "../styles/profile/ProfileDesktop.styles";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import { getSvgIcon } from "../../util/icons";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { PrimaryText } from "../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { SecondaryContrastButton } from "../styles/general/General.styles";

const UploadOrganizationLogoDesktop = ({
  initialFile,
  organizationDisplayName,
  uploadTitle,
  submitUploadedImage,
  isUserOnboarding,
  isOrganizationOnboarding,
  checker,
  permissions,
  userRoles,
  deleteImage,
  disabled,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const editIconSize = globalFontSize * 1.2;

  // State
  const [selectedFile, setSelectedFile] = useState(
    initialFile ? initialFile : null
  );
  const [photoURL, setPhotoURL] = useState(initialFile ? initialFile : null);
  const [openCrop, setOpenCrop] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  // Handlers
  const readFile = useCallback((file) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        getNormalizedFile(file)
          .then((normalizedFile) => reader.readAsDataURL(normalizedFile))
          .catch((error) => reject(error));
      } catch (error) {
        reject(error);
      }
    });
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    e.target.value = null;

    if (file) {
      if (file.size > DEFAULT_IMAGE_SIZE / 2) {
        messageError(getTranslation("TOO_LARGE_FILE_VALIDATION", t, i18n));
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const buffer = reader.result;
        const uintArray = new Uint8Array(buffer);

        const isImage = fileTypeChecker.validateFileType(
          uintArray,
          ALLOWED_EXTENSIONS
        );

        if (!isImage) {
          messageError(getTranslation("NOT_ALLOWED_FILE_TYPE", t, i18n));
          setPhotoURL(null);
          setOpenCrop(false);
        }
      };

      reader.readAsArrayBuffer(file);

      const url = await readFile(file);
      setPhotoURL(url);
      setOpenCrop(true);
    }
  };

  const handleConfirm = () => {
    deleteImage();
  };

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  useEffect(() => {
    setSelectedFile(initialFile);
  }, [initialFile]);

  useEffect(() => {
    setPhotoURL(initialFile);
  }, [initialFile]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={{
          content: getTranslation("ASSET_IMAGE_DELETE_MESSAGE", t, i18n),
          confirmTitle: getTranslation("DELETE_ASSET_IMAGE", t, i18n),
          closeTitle: getTranslation("CANCEL", t, i18n),
          showConfirm: true,
        }}
        label="delete-image"
        handleConfirm={handleConfirm}
      />

      <CropEasy
        openCrop={openCrop}
        setOpenCrop={setOpenCrop}
        photoURL={photoURL}
        setPhotoURL={setPhotoURL}
        setSelectedFile={setSelectedFile}
        submitUploadedImage={submitUploadedImage}
        isUserOnboarding={isUserOnboarding}
        isOrganizationOnboarding={isOrganizationOnboarding}
        handleCloseEdit={handleCloseEdit}
      />

      {openEdit ? (
        <Grid container>
          <Grid display="flex" justifyContent="start" item xs={7}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                width: "100%",
              }}
            >
              <DesktopProfileAvatarContainer>
                <DesktopProfileAvatar
                  id="profile-avatar"
                  alt={user?.shortUsername}
                  src={selectedFile}
                  sx={{ height: 100, width: 100 }}
                />
              </DesktopProfileAvatarContainer>

              <Box sx={{ maxWidth: "90%" }}>
                <PrimaryText sx={{ marginTop: "15px" }} variant="h4">
                  {organizationDisplayName}
                </PrimaryText>
              </Box>
            </Box>
          </Grid>

          <Grid display="flex" justifyContent="start" item xs={5}>
            {(permissions
              ? hasAccess(
                  checker,
                  permissions,
                  getPermissionsFromUserRoles(userRoles)
                )
              : true) && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "20px",
                }}
              >
                <RemoveImageButton
                  id="upload-image-button"
                  data-testid="upload-image-button"
                  aria-label="upload picture"
                  component="label"
                  src={selectedFile}
                  variant="text"
                  disabled={disabled}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleFileChange}
                    data-testid="upload-image-input"
                  />
                  {getTranslation(uploadTitle, t, i18n)}
                </RemoveImageButton>

                <Button
                  sx={{ marginTop: "20px" }}
                  disabled={!selectedFile}
                  onClick={() => setOpenConfirm(true)}
                  variant="text"
                  color="error"
                >
                  {getTranslation("REMOVE_LOGO", t, i18n)}
                </Button>

                <SecondaryContrastButton
                  sx={{ marginTop: "20px" }}
                  id="close-edit-organization-logo"
                  onClick={handleCloseEdit}
                >
                  {getTranslation("CLOSE", t, i18n)}
                </SecondaryContrastButton>
              </Box>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              width: "100%",
            }}
          >
            <DesktopProfileAvatarContainer>
              <DesktopProfileAvatar
                id="profile-avatar"
                alt={user?.shortUsername}
                src={selectedFile}
                sx={{ height: 100, width: 100 }}
              />
            </DesktopProfileAvatarContainer>

            <Box sx={{ maxWidth: "90%" }}>
              <PrimaryText sx={{ marginTop: "15px" }} variant="h4">
                {organizationDisplayName}
              </PrimaryText>
            </Box>
          </Box>

          {(permissions
            ? hasAccess(
                checker,
                permissions,
                getPermissionsFromUserRoles(userRoles)
              )
            : true) && (
            <Box sx={{ position: "absolute", top: "20px", right: "5px" }}>
              <SecondaryContrastButton
                id="edit-organization-logo"
                onClick={handleOpenEdit}
                startIcon={getSvgIcon(
                  "EDIT",
                  editIconSize,
                  editIconSize,
                  theme.palette.secondary.contrastText
                )}
              ></SecondaryContrastButton>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default UploadOrganizationLogoDesktop;
