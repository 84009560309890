import { useTranslation } from "react-i18next";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  transitionDirections,
} from "../../../util/utils";
import {
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectPageView,
} from "../../../store/slices/appSlice";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useDeleteResourceMutation,
  useGetResourcesQuery,
} from "../../../store/slices/api/assetManagementSlice";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { messageError, messageSuccess } from "../../../util/notification";
import {
  deleteItem,
  setIsInDuplicate,
  setResourceId,
  setTabletTabValue,
} from "../../../store/slices/assetListSlice";
import EditAsset from "../asset-form/EditAsset";
import { setCurrentResourceId } from "../../../store/slices/longPressSlice";
import { getSvgIcon } from "../../../util/icons";
import {
  setDeletedResourceId,
  setParentId,
} from "../../../store/slices/columnViewSlice";
import DuplicateAsset from "../asset-form/DuplicateAsset";

const LongPressAssetsItemMenu = ({
  resourceFunction,
  anchorEl,
  handleClose,
  resourceId,
  parentId,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const user = useSelector(selectUser);
  const pageView = useSelector(selectPageView);

  // State
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);

  // Other variables
  const editPermissionsKey = `ASSET_MANAGEMENT_${
    resourceFunction?.category === "HARDWARE_ASSET"
      ? "HARDWARE"
      : resourceFunction?.category
  }_EDIT`;

  const deletePermissionsKey = `ASSET_MANAGEMENT_${
    resourceFunction?.category === "HARDWARE_ASSET"
      ? "HARDWARE"
      : resourceFunction?.category
  }_DELETE`;

  const createPermissionsKey = `ASSET_MANAGEMENT_${
    resourceFunction?.category === "HARDWARE_ASSET"
      ? "HARDWARE"
      : resourceFunction?.category
  }_ADD`;

  const userId = user?.id;
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const open = Boolean(anchorEl);
  const iconSize = globalFontSize * 1.5;
  const isInAssets = pathname?.includes("resources");

  // Mutations
  const [
    deleteResource,
    { isLoading: isLoadingDeleteResource, isSuccess: isSuccessDeleteResource },
  ] = useDeleteResourceMutation();

  // Queries
  const { data: userRoles } = useUserRolePermissionsQuery({
    organizationId,
    userId,
  });

  const { data: resourceChildrenData } = useGetResourcesQuery(
    {
      parentId: resourceId,
      organizationId: organizationId,
      size: 1,
      index: 1,
    },
    {
      skip:
        !Boolean(resourceId) ||
        !open ||
        isLoadingDeleteResource ||
        isSuccessDeleteResource,
    }
  );

  // Other variables
  const haveChildren = resourceChildrenData?.totalRecord > 0;

  const alert = {
    content: haveChildren
      ? getTranslation("ASSET_DELETE_WITH_CHILDREN_MESSAGE", t, i18n)
      : getTranslation("ASSET_DELETE_MESSAGE", t, i18n),
    confirmTitle: getTranslation("DELETE_ASSET", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  // Handlers
  const handleEdit = () => {
    if (mobileMatches) {
      navigate(
        `/resources/${resourceId}/edit?direction=${transitionDirections.RIGHT_TO_LEFT}`
      );
    } else {
      handleClose();
      setOpenEdit(true);
      dispatch(setCurrentResourceId(resourceId));
    }
  };

  const handleDuplicate = () => {
    if (mobileMatches) {
      navigate(
        `/resources/${resourceId}/duplicate?direction=${transitionDirections.RIGHT_TO_LEFT}`
      );
    } else {
      handleClose();
      setOpenDuplicate(true);
      dispatch(setIsInDuplicate(true));
    }
  };

  const handleOpenDeleteConfirm = () => {
    handleClose();
    setOpenConfirm(true);
  };

  const deleteConfirm = async () => {
    try {
      if (pageView !== "column" && isInAssets) {
        navigate(`/resources${parentId ? `/${parentId}` : ""}`);
      }

      dispatch(setDeletedResourceId(resourceId));
      dispatch(setResourceId(null));

      await deleteResource({
        resourceid: resourceId,
        organizationId,
        category: resourceFunction?.category?.toLowerCase(),
      }).unwrap();

      dispatch(setParentId(parentId));
      dispatch(deleteItem(resourceId));

      dispatch(setTabletTabValue(0));
      messageSuccess(getTranslation("ASSET_DELETED_SUCCESSFULLY", t, i18n));
    } catch (error) {
      dispatch(setResourceId(resourceId));
      messageError(getTranslation("ASSET_DELETE_FAILED", t, i18n));
    }
  };

  return (
    <>
      {openConfirm && (
        <ConfirmAlert
          isOpen={openConfirm}
          setIsOpen={setOpenConfirm}
          alert={alert}
          handleConfirm={deleteConfirm}
          label="delete"
        />
      )}

      {open && (
        <Menu
          sx={{
            "& .MuiMenu-paper": {
              width: "250px",
              maxWidth: "100%",
              borderRadius: "5px",
              marginTop: "4px",
              marginRight: "4px",
              left: mobileMatches ? "10px !important" : "16px",
            },
          }}
          id="longpress-assets-item-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={2}
        >
          <MenuList sx={{ padding: 0 }}>
            {hasAccess(
              "all",
              [permissions[editPermissionsKey]],
              getPermissionsFromUserRoles(userRoles)
            ) && (
              <MenuItem id="edit-asset" onClick={handleEdit}>
                <ListItemIcon>
                  {getSvgIcon(
                    "EDIT",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </ListItemIcon>

                <ListItemText>{getTranslation("EDIT", t, i18n)}</ListItemText>
              </MenuItem>
            )}

            {hasAccess(
              "all",
              [permissions[deletePermissionsKey]],
              getPermissionsFromUserRoles(userRoles)
            ) && (
              <MenuItem id="delete-asset" onClick={handleOpenDeleteConfirm}>
                <ListItemIcon>
                  {getSvgIcon(
                    "DELETE",
                    iconSize,
                    iconSize,
                    theme.palette.error.main
                  )}
                </ListItemIcon>

                <ListItemText>{getTranslation("DELETE", t, i18n)}</ListItemText>
              </MenuItem>
            )}

            {hasAccess(
              "all",
              [permissions[createPermissionsKey]],
              getPermissionsFromUserRoles(userRoles)
            ) && (
              <MenuItem id="duplicate-asset" onClick={handleDuplicate}>
                <ListItemIcon>
                  {getSvgIcon(
                    "DUPLICATE",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </ListItemIcon>

                <ListItemText>
                  {getTranslation("DUPLICATE", t, i18n)}
                </ListItemText>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}

      {openEdit && (
        <EditAsset
          resourceId={resourceId}
          open={openEdit}
          setOpen={setOpenEdit}
        />
      )}

      {openDuplicate && (
        <DuplicateAsset
          resourceId={resourceId}
          open={openDuplicate}
          setOpen={setOpenDuplicate}
        />
      )}
    </>
  );
};

export default LongPressAssetsItemMenu;
