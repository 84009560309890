import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  alpha,
  useTheme,
  Box,
  IconButton,
} from "@mui/material";
import { USER_STATUS, getTranslation } from "../../util/utils";
import { getMappedUsers } from "../../util/user-utils";
import OrganizationUserOptionsDesktop from "./OrganizationUserOptionsDesktop";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import { SecondaryContrastText } from "../styles/general/General.styles";
import { PrimaryText } from "../styles/assets/ListGridView.styles";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

import { getSvgIcon } from "../../util/icons";
import { selectGlobalFontSize } from "../../store/slices/appSlice";

const headCells = [
  {
    id: "name",
    numeric: false,
    label: "name",
  },
  {
    id: "roleName",
    numeric: false,
    label: "role",
  },
  {
    id: "status",
    numeric: false,
    label: "STATUS",
  },
];

function getComparator(order, orderBy) {
  return (a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  };
}

// Enhanced comparator for multiple sorting criteria
function getMultiComparator(sortCriteria) {
  return (a, b) => {
    for (const { orderBy, order } of sortCriteria) {
      const result = getComparator(order, orderBy)(a, b);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };
}

function getFilter(filter) {
  return (u) => {
    const hasRole = filter.role ? u.role.id === filter.role.id : true;
    const hasStatus = filter.status ? u.status === filter.status : true;
    const hasSearchValue = filter.searchValue
      ? u.name?.includes(filter.searchValue)
      : true;

    return hasRole && hasStatus && hasSearchValue;
  };
}

function TablePaginationActions(props) {
  // General hooks
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  //Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const iconSize = globalFontSize;

  // Handlers
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handlePageClick = (event, index) => {
    onPageChange(event, index);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const pages = Math.max(0, Math.ceil(count / rowsPerPage));
  console.log(page);

  return (
    <Box sx={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <SkipNextIcon sx={{ width: iconSize, height: iconSize }} />
        ) : (
          <SkipPreviousIcon sx={{ width: iconSize, height: iconSize }} />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl"
          ? getSvgIcon(
              "ARROW_RIGHT",
              iconSize,
              iconSize,
              page === 0
                ? theme.palette.action.disabled
                : theme.palette.primary.main
            )
          : getSvgIcon(
              "ARROW_LEFT",
              iconSize,
              iconSize,
              page === 0
                ? theme.palette.action.disabled
                : theme.palette.primary.main
            )}
      </IconButton>
      {Array.from({ length: pages }).map((_, index) => (
        <IconButton
          sx={{
            backgroundColor:
              page === index ? theme.palette.secondary.active : "",
            width: iconSize * 1.5,
            height: iconSize * 1.5,
          }}
          onClick={(e) => handlePageClick(e, index)}
          key={index}
        >
          <PrimaryText>{index + 1}</PrimaryText>
        </IconButton>
      ))}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl"
          ? getSvgIcon(
              "ARROW_LEFT",
              iconSize,
              iconSize,
              page >= Math.ceil(count / rowsPerPage) - 1
                ? theme.palette.action.disabled
                : theme.palette.primary.main
            )
          : getSvgIcon(
              "ARROW_RIGHT",
              iconSize,
              iconSize,
              page >= Math.ceil(count / rowsPerPage) - 1
                ? theme.palette.action.disabled
                : theme.palette.primary.main
            )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <SkipPreviousIcon sx={{ width: iconSize, height: iconSize }} />
        ) : (
          <SkipNextIcon sx={{ width: iconSize, height: iconSize }} />
        )}
      </IconButton>
    </Box>
  );
}

const OrganizationUserListTable = ({
  organization,
  organizationUsers = [],
  invitedUsers = [],
  filter,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);

  // States
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Handlers
  const createSortHandler = (property, index) => (event) => {
    const sortOrder = sort.find((s) => s.orderBy === property);
    if (Boolean(sortOrder)) {
      if (sortOrder.order === "asc") {
        const newSort = sort.map((s) =>
          s.orderBy === property ? { ...sortOrder, order: "desc" } : s
        );
        setSort(newSort);
      } else {
        const updatedSort = sort.filter((s) => s.orderBy !== sortOrder.orderBy);
        setSort(updatedSort);
      }
    } else {
      const newSortOrder = { orderBy: property, order: "asc" };
      const updatedSort = [...sort];
      updatedSort.splice(index, 0, newSortOrder);

      setSort(updatedSort);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Other variables
  const rows = useMemo(
    () => getMappedUsers(organizationUsers, invitedUsers),
    [organizationUsers, invitedUsers]
  );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      rows
        .filter(getFilter(filter))
        .sort(getMultiComparator(sort))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [rows, filter, sort, page, rowsPerPage]
  );

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index) => {
                const sortOrder = sort.find((s) => s.orderBy === headCell.id);

                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    sortDirection={Boolean(sortOrder) ? sortOrder.order : false}
                  >
                    <TableSortLabel
                      sx={{}}
                      active={Boolean(sortOrder)}
                      direction={Boolean(sortOrder) ? sortOrder.order : "asc"}
                      onClick={createSortHandler(headCell.id, index)}
                    >
                      <SecondaryContrastText>
                        {getTranslation(headCell.label, t, i18n)}
                      </SecondaryContrastText>
                    </TableSortLabel>
                  </TableCell>
                );
              })}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.length === 0 && (
              <TableRow>
                <TableCell colSpan={headCells.length + 1} align="center">
                  <PrimaryText variant="body1" color="textSecondary">
                    No results found.
                  </PrimaryText>
                </TableCell>
              </TableRow>
            )}
            {visibleRows.map((row) => {
              const { id, name, role, status } = row;
              const isCurrentUser = user?.id === id;
              const resultName = `${name} ${isCurrentUser ? "(You)" : ""}`;
              const isActive = status === USER_STATUS.ACTIVE;
              const statusStyles = {
                color: !isActive ? alpha(theme.palette.primary.main, 0.4) : "",
              };

              return (
                <TableRow key={id}>
                  <TableCell sx={statusStyles}>{resultName}</TableCell>
                  <TableCell sx={statusStyles}>
                    {getTranslation(role.name, t, i18n)}
                  </TableCell>
                  <TableCell sx={statusStyles}>
                    {getTranslation(status, t, i18n)}
                  </TableCell>
                  <TableCell>
                    <OrganizationUserOptionsDesktop
                      organization={organization}
                      user={row}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          display: "flex",
          ".MuiToolbar-root": {
            paddingLeft: 0,
          },
        }}
        rowsPerPageOptions={[]}
        labelDisplayedRows={(props) => <></>}
        labelRowsPerPage=""
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
};

export default OrganizationUserListTable;
