import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import { selectUser } from "../../store/slices/authSlice";
import { getPermissionsFromUserRoles, getTranslation } from "../../util/utils";
import ErrorHandling from "./ErrorHandling";
import { useTranslation } from "react-i18next";
import { messageError } from "../../util/notification";

const Access = ({
  children,
  all,
  oneOf,
  not,
  organizationId,
  redirectUri = "/",
}) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const activeOrganization = user?.organizations?.find((o) => o.default);
  const calculatedOrganizationId = organizationId || activeOrganization?.id;

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError,
  } = useUserRolePermissionsQuery(
    {
      userId: user.id,
      organizationId: calculatedOrganizationId,
    },
    { skip: !calculatedOrganizationId }
  );

  // Other variables
  const permissions = getPermissionsFromUserRoles(userRoles);

  const checkAll = !!all?.every((item) => permissions?.includes(item));
  const checkOneOf = !!oneOf?.some((item) => permissions?.includes(item));

  const hasAll = !!all && checkAll;
  const hasOneOf = !!oneOf && checkOneOf;

  let hasAccess = all && oneOf ? hasAll && hasOneOf : hasAll || hasOneOf;

  if (not) {
    hasAccess = !hasAccess;
  }

  if (userRoles && !hasAccess) {
    messageError(getTranslation("CANNOT_ACCESS_PAGE", t, i18n), {
      toastId: "page-access-restricted",
    });
  }

  return (
    <ErrorHandling isLoading={isLoadingUserRoles} isError={isError}>
      {hasAccess ? <>{children}</> : <Navigate to={redirectUri} />}
    </ErrorHandling>
  );
};

export default Access;
