import { Box, Button, Dialog, Grid, Typography, useTheme } from "@mui/material";
import { getTranslation } from "../../../util/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectExecutionResultFields,
  selectFurtherResultFields,
  selectGroupResultFields,
  selectQuery,
  selectResultFields,
  selectShouldExecute,
  selectTypeId,
  setExecutionResultFields,
  setResultFields,
  setShouldExecute,
  setShouldRefreshDialogTable,
} from "../../../store/slices/reportsSlice";
import {
  constructAggregations,
  parseQueryExecute,
} from "../../../util/reports-utils";
import { useReportExecutionBaseQuery } from "../../../store/slices/api/reportsApiSlice";
import { DragDropContext } from "react-beautiful-dnd";
import GroupFieldsList from "./GroupFieldsList";
import ResultFieldsList from "./ResultFieldsList";
import { FormatResultWizardStepGridContainer } from "../../styles/reports/Reports.styles";
import FormatResultPreviewTable from "../create-report/FormatResultPreviewTable";
import AddConditionDialogHeader from "./query-builder/AddConditionDialogHeader";
import { Transition } from "./MobileCreateReportDialog";
import { useEffect, useState } from "react";
import AbsoluteLoadingSpinner from "../../common/AbsoluteLoadingSpinner";
import { messageError } from "../../../util/notification";

const FormatResultMobileStep = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const query = useSelector(selectQuery);
  const shouldExecute = useSelector(selectShouldExecute);
  const resultFields = useSelector(selectResultFields);
  const furtherResultFields = useSelector(selectFurtherResultFields);
  const groupResultFields = useSelector(selectGroupResultFields);
  const executionResultFields = useSelector(selectExecutionResultFields);
  const typeId = useSelector(selectTypeId);

  // State
  const [open, setOpen] = useState(false);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  const fields = executionResultFields.map((field, i) => {
    return {
      field: {
        name: field.field,
      },
      displayName: field.displayName,
      index: i,
      orderIndex: field.orderIndex,
      sortMethod: field.sortMethod,
      usedForGrouping: field.usedForGrouping,
    };
  });

  const finalAggregations = constructAggregations(executionResultFields);
  const filters = [parseQueryExecute(query)];
  const continueButtonDisabled = resultFields?.some(
    (field) => !field.displayName
  );

  // Queries
  const {
    data: reportExecutionData,
    isLoading,
    isFetching,
    isError,
  } = useReportExecutionBaseQuery(
    {
      organizationId,
      typeId,
      fields,
      filters,
      aggregations: finalAggregations,
    },
    { skip: !shouldExecute }
  );

  // Handlers
  const handleLoadPreview = () => {
    dispatch(setShouldExecute(true));
    dispatch(setExecutionResultFields(resultFields));
    handleOpen();
  };

  const handleRearangeArr = (arr, sourceIndex, destIndex) => {
    const arrCopy = [...arr];
    const [removed] = arrCopy.splice(sourceIndex, 1);
    arrCopy.splice(destIndex, 0, removed);

    return arrCopy;
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId !== source.droppableId) {
      // Find the source in items array and change with destination droppable id
      if (destination.droppableId === "result-fields") {
        const newResultFields = resultFields.map((field) =>
          field.uniqueId === result.draggableId
            ? { ...field, usedForGrouping: false }
            : { ...field }
        );

        dispatch(setResultFields(newResultFields));
      } else if (destination.droppableId === "group-fields") {
        const newResultFields = resultFields.map((field) =>
          field.uniqueId === result.draggableId
            ? { ...field, usedForGrouping: true }
            : { ...field }
        );

        dispatch(setResultFields(newResultFields));
      }
    } else {
      // Rearrange the array if it is in the same category
      if (destination.droppableId === "result-fields") {
        const items = handleRearangeArr(
          furtherResultFields,
          source.index,
          destination.index
        );

        dispatch(setResultFields([...groupResultFields, ...items]));
      } else if (destination.droppableId === "group-fields") {
        const items = handleRearangeArr(
          groupResultFields,
          source.index,
          destination.index
        );

        dispatch(setResultFields([...items, ...furtherResultFields]));
      }
    }

    dispatch(setShouldExecute(false));
    dispatch(setShouldRefreshDialogTable(false));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setShouldExecute(false));
  };

  useEffect(() => {
    if (isError) {
      messageError(getTranslation("REPORT_FAILED_EXECUTION", t, i18n));
    }
  }, [isError]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: "bold" }} variant="h5">
            {getTranslation("FORMAT_RESULT", t, i18n)}
          </Typography>
        </Box>

        <Box>
          <Button
            id="open-preview"
            variant="text"
            disabled={continueButtonDisabled || shouldExecute}
            onClick={handleLoadPreview}
            sx={{
              color: theme.palette.secondary.contrastText,
            }}
          >
            Open preview
          </Button>
        </Box>
      </Box>

      <Box>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <GroupFieldsList />
              </Box>
            </Grid>
          </Grid>

          <FormatResultWizardStepGridContainer container>
            <Grid item xs={12}>
              <Box>
                <ResultFieldsList />
              </Box>
            </Grid>
          </FormatResultWizardStepGridContainer>
        </DragDropContext>
      </Box>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            paddingBlock: "10px",
          }}
        >
          <Box>
            <Box sx={{ paddingInline: "10px" }}>
              <AddConditionDialogHeader
                handleClose={handleClose}
                title="Preview"
                showDone={false}
              />
            </Box>

            <Box sx={{ marginTop: "20px", paddingInline: "10px" }}>
              {isLoading || isFetching ? (
                <AbsoluteLoadingSpinner />
              ) : (
                reportExecutionData?.data &&
                reportExecutionData.data.length > 0 && (
                  <FormatResultPreviewTable rows={reportExecutionData.data} />
                )
              )}
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default FormatResultMobileStep;
