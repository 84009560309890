import { Autocomplete, Box, TextField } from "@mui/material";

const FormikCountriesAutocomplete = ({
  name,
  label,
  placeholder = "",
  options,
  formik,
}) => {
  return (
    <Autocomplete
      id="country-select"
      value={formik.values[name] || null}
      onChange={(_, newValue) => formik.setFieldValue(name, newValue)}
      autoHighlight
      options={options.map((option) => option.code)}
      getOptionLabel={(option) =>
        options?.find((o) => o.code === option)?.label || ""
      }
      renderOption={(props, code) => {
        const { key, ...optionProps } = props;

        const option = options?.find((o) => o.code === code) ?? {};

        return (
          <Box
            key={key}
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...optionProps}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
              alt=""
            />
            {option?.label} ({option?.code}) +{option?.phone}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={label}
          placeholder={placeholder}
          name={name}
          onBlur={formik.handleBlur}
          error={formik.touched[name] && Boolean(formik.errors[name])}
          helperText={formik.touched[name] && formik.errors[name]}
        />
      )}
    />
  );
};

export default FormikCountriesAutocomplete;
