import { useState } from "react";
import { SecondaryText } from "../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import {
  Alert,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import DoubleValueWidgetContent from "./create-report/categorized-widgets/DoubleValueWidgetContent";
import MinMaxAverageWidgetContent from "./create-report/categorized-widgets/MinMaxAverageWidgetContent";
import PercentageWidgetContent from "./create-report/categorized-widgets/PercentageWidgetContent";
import StatusWidgetContent from "./create-report/categorized-widgets/StatusWidgetContent";
import PieChartWidgetContent from "./create-report/categorized-widgets/PieChartWidgetContent";
import DonutChartWidgetContent from "./create-report/categorized-widgets/DonutChartWidgetContent";
import LineChartWidgetContent from "./create-report/categorized-widgets/LineChartWidgetContent";
import BarChartWidgetContent from "./create-report/categorized-widgets/BarChartWidgetContent";
import FunnelChartWidgetContent from "./create-report/categorized-widgets/FunnelChartWidgetContent";
import AreaChartWidgetContent from "./create-report/categorized-widgets/AreaChartWidgetContent";
import {
  DEFAULT_ASSIGNMENT,
  WIDGET_MODES,
  WIDGET_TYPE,
  compatibilityCheck,
  getChartData,
  getChartGroups,
  getChartPortions,
  getMeasurementUnitForField,
  getPaletteColor,
} from "../../util/reports-utils";
import { useTranslation } from "react-i18next";
import SingleValueWidgetContent from "./create-report/categorized-widgets/SingleValueWidgetContent";
import { getSvgIcon } from "../../util/icons";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  measurementDisplay,
  permissions,
} from "../../util/utils";
import { useDeleteReportDefinitionWidgetMutation } from "../../store/slices/api/reportsApiSlice";
import { messageError, messageSuccess } from "../../util/notification";
import { selectUser } from "../../store/slices/authSlice";
import UpdateWidgetDialog from "./update-report/UpdateWidgetDialog";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import {
  WidgetContainer,
  WidgetTitle,
  WidgetWrapper,
} from "../styles/reports/ChartWidgets.styles";

const WidgetCard = ({
  widget,
  reportId,
  reportTypeId,
  rows,
  aggregations,
  fields,
}) => {
  const { id, title, widgetTypeId, dataSources, showHome } = widget;

  // General hooks
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const userId = user?.id;
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  // Mutations
  const [deleteReportDefinitionWidget] =
    useDeleteReportDefinitionWidgetMutation();

  const { data: userRoles } = useUserRolePermissionsQuery({
    organizationId,
    userId,
  });

  // Handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleConfirmDelete = async () => {
    handleCloseDelete();
    await handleDelete();
  };

  const handleDelete = async () => {
    try {
      handleClose();
      await deleteReportDefinitionWidget({
        organizationId,
        reportDefinitionWidgetId: id,
      }).unwrap();
      messageSuccess(getTranslation("WIDGET_DELETED_SUCCESSFULLY", t, i18n));
    } catch (error) {
      messageError(getTranslation("WIDGET_DELETED_FAILED", t, i18n));
    }
  };

  // Other variables
  const open = Boolean(anchorEl);
  const iconSize = globalFontSize;
  const warningIconSize = globalFontSize * 1.5;

  const clonedDataSource = dataSources?.slice();
  clonedDataSource?.sort((a, b) => a.index - b.index);

  const aggregationIds = clonedDataSource?.map(
    (a) => a.reportDefinitionAggregationId
  );

  const alert = {
    content: getTranslation("WIDGET_DELETE_MESSAGE", t, i18n),
    confirmTitle: getTranslation("DELETE_WIDGET", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  let data = null;
  let values = null;
  const hasGroupFields =
    fields.filter((field) => field.usedForGrouping).length > 0;
  const compatibility = compatibilityCheck(widgetTypeId, hasGroupFields);

  if (compatibility && aggregationIds?.length > 0) {
    const groupFields = fields
      .filter((field) => field.usedForGrouping)
      .map((field) => ({ field: field.field.name }));

    if (groupFields?.length > 0) {
      const objGroups = getChartGroups(
        rows,
        groupFields,
        aggregations,
        aggregationIds
      );

      const objOfPortions = getChartPortions(objGroups);

      const shouldUseGroups =
        widgetTypeId === WIDGET_TYPE.BAR_CHART ||
        widgetTypeId === WIDGET_TYPE.LINE_CHART ||
        widgetTypeId === WIDGET_TYPE.AREA_CHART;

      data = getChartData(
        shouldUseGroups ? objGroups : objOfPortions,
        aggregations,
        theme.palette.mode,
        widgetTypeId,
        t,
        i18n,
        dataSources,
        WIDGET_MODES.FETCH,
        user?.region
      );

      if (widgetTypeId === WIDGET_TYPE.FUNNEL_CHART) {
        const singleClonedDataSource =
          clonedDataSource?.length > 0 ? clonedDataSource[0] : {};

        const palette =
          singleClonedDataSource?.paletteName?.toLowerCase() ?? "pastel";

        const label = data?.labels?.length > 0 ? data?.labels[0] : "";

        const datasets =
          data?.datasets?.length > 0 ? data?.datasets[0]?.data : [];

        const aggregation = aggregations?.find(
          (a) => a.id === singleClonedDataSource?.reportDefinitionAggregationId
        );

        data = datasets?.map((d, i) => {
          const { unit, value } = measurementDisplay({
            value: d,
            unit: getMeasurementUnitForField(
              aggregation?.field?.name,
              aggregation?.aggregationType
            ),
            region: user?.region,
          });

          const unitLabel = unit ? ` (in ${unit})` : "";

          return {
            value: value,
            name: label + unitLabel,
            fill: getPaletteColor(palette, theme.palette.mode, i),
          };
        });
      }
    } else {
      const row = rows?.length > 0 ? rows[0] : {};

      const mappedDataSources = clonedDataSource?.map((d) => {
        const aggregation = aggregations?.find(
          (a) => a.id === d.reportDefinitionAggregationId
        );

        return aggregation;
      });

      values = mappedDataSources?.map((aggregation) => {
        const key =
          aggregation?.field?.name +
          `.aggregation-${aggregation?.aggregationType?.toLowerCase()}`;

        const { unit, value } = measurementDisplay({
          value: row[key],
          unit: getMeasurementUnitForField(
            aggregation?.field?.name,
            aggregation?.aggregationType
          ),
          region: user?.region,
        });

        const unitLabel = unit ? ` (in ${unit})` : "";

        return value + unitLabel;
      });
    }
  }

  if (!data && !values) {
    return (
      <Paper
        key={id}
        sx={{
          height: "180px",
          backgroundColor: "#F1F1F1",
        }}
      >
        <Stack sx={{ height: "100%", position: "relative" }}>
          <SecondaryText
            sx={{ padding: "8px 24px", wordBreak: "break-word" }}
            variant="body2"
            textAlign="center"
          >
            {title}
          </SecondaryText>
          <Stack
            sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Tooltip
              title={getTranslation("WIDGETS_INCOMPATIBLE_WARNING", t, i18n)}
              placement="bottom"
            >
              {getSvgIcon(
                "INFO",
                warningIconSize,
                warningIconSize,
                theme.palette.secondary.dark,
                {
                  color: theme.palette.primary.contrastText,
                }
              )}
            </Tooltip>
          </Stack>
        </Stack>
      </Paper>
    );
  }

  return (
    <>
      {openEdit && (
        <UpdateWidgetDialog
          open={openEdit}
          handleClose={handleCloseEdit}
          widget={widget}
          reportId={reportId}
          reportTypeId={reportTypeId}
        />
      )}
      <ConfirmAlert
        isOpen={openDelete}
        setIsOpen={setOpenDelete}
        alert={alert}
        label="delete-widget"
        handleConfirm={handleConfirmDelete}
      />
      <WidgetWrapper key={id}>
        <WidgetContainer sx={{ paddingInline: "34px" }}>
          <WidgetTitle variant="body2" textAlign="center">
            {title}
          </WidgetTitle>
          <IconButton
            id="widget-action-button"
            aria-controls={open ? "widget-action-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              position: "absolute",
              top: "4px",
              right: "4px",
            }}
          >
            {getSvgIcon(
              "MORE",
              iconSize,
              iconSize,
              theme.palette.secondary.dark
            )}
          </IconButton>
          <Menu
            id="widget-action-menu"
            aria-labelledby="widget-action-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              "& .MuiMenu-paper": {
                width: "fit-content",
                borderRadius: "5px",
                marginTop: "4px",
                marginRight: "4px",
              },
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuList sx={{ padding: 0 }}>
              {hasAccess(
                "all",
                [permissions["REPORTING_USER_DEFINED_DELETE"]],
                getPermissionsFromUserRoles(userRoles)
              ) && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleOpenDelete();
                  }}
                >
                  <ListItemIcon>
                    {getSvgIcon(
                      "DELETE",
                      iconSize,
                      iconSize,
                      theme.palette.error.main
                    )}
                  </ListItemIcon>

                  <ListItemText>
                    {getTranslation("DELETE", t, i18n)}
                  </ListItemText>
                </MenuItem>
              )}
              {hasAccess(
                "all",
                [permissions["REPORTING_USER_DEFINED_EDIT"]],
                getPermissionsFromUserRoles(userRoles)
              ) && (
                <MenuItem onClick={handleOpenEdit}>
                  <ListItemIcon>
                    {getSvgIcon(
                      "EDIT",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.contrastText
                    )}
                  </ListItemIcon>

                  <ListItemText>{getTranslation("EDIT", t, i18n)}</ListItemText>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
          {/* {showHome && (
          <HomeIcon
            sx={{
              position: "absolute",
              top: "4px",
              right: "4px",
              color: theme.palette.secondary.dark,
            }}
          />
        )} */}

          {widgetTypeId === WIDGET_TYPE.DOUBLE_VALUE && (
            <DoubleValueWidgetContent
              value1={values[0] ?? 0}
              value2={values[1] ?? 0}
              convertToUnit={true}
            />
          )}

          {widgetTypeId === WIDGET_TYPE.SINGLE_VALUE && (
            <SingleValueWidgetContent
              value={values[0] ?? 0}
              convertToUnit={true}
            />
          )}
          {widgetTypeId === WIDGET_TYPE.MIN_MAX_AVERAGE && (
            <MinMaxAverageWidgetContent
              max={values[0] ?? 0}
              avg={values[1] ?? 0}
              min={values[2] ?? 0}
              convertToUnit={true}
            />
          )}
          {/* {widgetTypeId === WIDGET_TYPE.PERCENTAGE && (
          <PercentageWidgetContent percentage={20} color={"darkblue"} />
        )} */}

          {widgetTypeId === WIDGET_TYPE.STATUS && (
            <StatusWidgetContent
              dangerCount={values[0] ?? 0}
              warningCount={values[1] ?? 0}
              successCount={values[2] ?? 0}
              convertToUnit={true}
            />
          )}

          {/* Summary report (with grouping)*/}
          {widgetTypeId === WIDGET_TYPE.PIE_CHART && (
            <PieChartWidgetContent data={data} />
          )}

          {widgetTypeId === WIDGET_TYPE.DONUT_CHART && (
            <DonutChartWidgetContent data={data} />
          )}

          {widgetTypeId === WIDGET_TYPE.LINE_CHART && (
            <LineChartWidgetContent
              data={data}
              assignment={DEFAULT_ASSIGNMENT}
            />
          )}

          {widgetTypeId === WIDGET_TYPE.BAR_CHART && (
            <BarChartWidgetContent
              data={data}
              assignment={DEFAULT_ASSIGNMENT}
            />
          )}

          {widgetTypeId === WIDGET_TYPE.FUNNEL_CHART && (
            <FunnelChartWidgetContent data={data} />
          )}

          {widgetTypeId === WIDGET_TYPE.AREA_CHART && (
            <AreaChartWidgetContent data={data} />
          )}
        </WidgetContainer>
      </WidgetWrapper>
    </>
  );
};

export default WidgetCard;
