import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetOrganizationByIdQuery,
  useGetOrganizationDataStorageLocationQuery,
} from "../../store/slices/api/organizationsApiSlice";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { selectUser } from "../../store/slices/authSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  transitionDirections,
} from "../../util/utils";
import OrganizationDetailsHeader from "../../navigation/header/organization/OrganizationDetailsHeader";
import {
  HomePagePadding,
  SecondaryContrastButton,
} from "../../components/styles/general/General.styles";
import { PrimaryText } from "../../components/styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { getSvgIcon } from "../../util/icons";
import ErrorHandling from "../../components/common/ErrorHandling";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import { useGetAddressesByOrganizationIdQuery } from "../../store/slices/api/addressesApiSlice";
import AppAccess from "../../components/common/AppAccess";
import Access from "../../components/common/Access";
import Layer2AccessOrganization from "../../components/common/Layer2AccessOrganization";

const OrganizationMoreDetailsPage = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const editIconSize = globalFontSize * 1.2;

  // Queries
  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolePermissionsQuery({
      organizationId,
      userId: user.id,
    });

  const { data: organizationData, isLoading: isLoadingOrganization } =
    useGetOrganizationByIdQuery(organizationId);

  // Other variables
  const org = user?.organizations?.find((o) => o.id === organizationId);

  // Custom hooks
  const { isLoading, isRestricted } = useCheckOrganizationRestricted(org);

  // Other variables
  const orgDisabled = hasAccess(
    "all",
    [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
    getPermissionsFromUserRoles(userRoles)
  )
    ? false
    : isRestricted;

  // Queries
  const {
    data: orgDataStorageLocation,
    isLoading: isLoadingOrgDataStorageLocation,
  } = useGetOrganizationDataStorageLocationQuery(
    { organizationId },
    {
      skip:
        orgDisabled ||
        !hasAccess(
          "all",
          [permissions.ORG_MANAGEMENT_EDIT],
          getPermissionsFromUserRoles(userRoles)
        ),
    }
  );

  const { data: addressesData, isLoading: isLoadingAddresses } =
    useGetAddressesByOrganizationIdQuery(
      { organizationId },
      { skip: !Boolean(organizationId) }
    );

  // Handlers
  const goBackHandler = () =>
    navigate(
      `/profile/organization/${organizationId}?direction=${transitionDirections.LEFT_TO_RIGHT}`
    );

  const handleNavigateToEdit = () =>
    navigate(
      `/profile/organization/${organizationId}/edit?direction=${transitionDirections.LEFT_TO_RIGHT}`
    );

  const getAddress = () => {
    let address = {};

    if (addressesData && addressesData.length > 0) {
      address = addressesData[0];
    }

    return address;
  };

  // Other variables
  const { addressLine1, addressLine2, city, state, country } = getAddress();

  const dataStorageLocation = orgDataStorageLocation
    ? i18n.exists(orgDataStorageLocation.displayId)
      ? t(orgDataStorageLocation.displayId)
      : orgDataStorageLocation.name
    : "";

  return (
    <AppAccess>
      <Access all={[permissions["ORG_MANAGEMENT_EDIT"]]}>
        <Layer2AccessOrganization organization={org}>
          <ErrorHandling
            isLoading={
              isLoadingUserRoles ||
              isLoadingOrganization ||
              isLoading ||
              isLoadingOrgDataStorageLocation ||
              isLoadingAddresses
            }
            isError={false}
          >
            <Box>
              <OrganizationDetailsHeader
                goBackHandler={goBackHandler}
                title={getTranslation("DETAILS", t, i18n)}
              />

              <HomePagePadding>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Box>
                    <PrimaryText variant="h5">
                      {getTranslation("ORGANIZATION_DETAILS", t, i18n)}
                    </PrimaryText>
                  </Box>

                  {hasAccess(
                    "all",
                    [permissions.ORG_MANAGEMENT_EDIT],
                    getPermissionsFromUserRoles(userRoles)
                  ) &&
                    !orgDisabled && (
                      <Box>
                        <SecondaryContrastButton
                          id="edit-organization-subscription"
                          onClick={handleNavigateToEdit}
                          startIcon={getSvgIcon(
                            "EDIT",
                            editIconSize,
                            editIconSize,
                            theme.palette.secondary.contrastText
                          )}
                        >
                          {getTranslation("EDIT", t, i18n)}
                        </SecondaryContrastButton>
                      </Box>
                    )}
                </Box>

                <Box sx={{ marginTop: "20px" }}>
                  <PrimaryText>
                    {getTranslation("ORGANIZATION_NAME", t, i18n)}:{" "}
                    {organizationData?.name}
                  </PrimaryText>

                  <PrimaryText sx={{ marginTop: "10px" }}>
                    {getTranslation("NICKNAME", t, i18n)}:{" "}
                    {Boolean(organizationData?.nickname)
                      ? organizationData?.nickname
                      : "(Empty)"}
                  </PrimaryText>

                  {!orgDisabled &&
                    hasAccess(
                      "all",
                      [permissions.ORG_MANAGEMENT_EDIT],
                      getPermissionsFromUserRoles(userRoles)
                    ) && (
                      <PrimaryText sx={{ marginTop: "10px" }}>
                        {getTranslation("DATA_STORAGE_LOCATION", t, i18n)}:{" "}
                        {dataStorageLocation}
                      </PrimaryText>
                    )}
                </Box>

                <Box>
                  <PrimaryText sx={{ marginTop: "10px" }}>
                    {getTranslation("ADDRESS_LINE_1", t, i18n)}: {addressLine1}
                  </PrimaryText>

                  <PrimaryText sx={{ marginTop: "10px" }}>
                    {getTranslation("ADDRESS_LINE_2", t, i18n)}:{" "}
                    {Boolean(addressLine2) ? addressLine2 : "(Empty)"}
                  </PrimaryText>

                  <PrimaryText sx={{ marginTop: "10px" }}>
                    {getTranslation("STATE", t, i18n)}:{" "}
                    {Boolean(state) ? state : "(Empty)"}
                  </PrimaryText>

                  <PrimaryText sx={{ marginTop: "10px" }}>
                    {getTranslation("CITY", t, i18n)}: {city}
                  </PrimaryText>

                  <PrimaryText sx={{ marginTop: "10px" }}>
                    {getTranslation("COUNTRY", t, i18n)}: {country}
                  </PrimaryText>

                  <PrimaryText sx={{ marginTop: "10px" }}>
                    {getTranslation("CONTACT_PERSON", t, i18n)}:{" "}
                    {organizationData?.contactPerson}
                  </PrimaryText>

                  <PrimaryText sx={{ marginTop: "10px" }}>
                    {getTranslation("phone", t, i18n)}:{" "}
                    {organizationData?.contactPhone}
                  </PrimaryText>

                  <PrimaryText sx={{ marginTop: "10px" }}>
                    {getTranslation("CONTACT_EMAIL_ADDRESS", t, i18n)}:{" "}
                    {organizationData?.contactEmail}
                  </PrimaryText>
                </Box>
              </HomePagePadding>
            </Box>
          </ErrorHandling>
        </Layer2AccessOrganization>
      </Access>
    </AppAccess>
  );
};

export default OrganizationMoreDetailsPage;
