import { useCallback, useState } from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useSelector } from "react-redux";
import { StaticHeightDialogContent } from "../../styles/assets/ChooseAssetImagePage.styles";
import { useTranslation } from "react-i18next";
import { getTranslation, showValidationError } from "../../../util/utils";
import { messageError, messageSuccess } from "../../../util/notification";
import { selectPageInfo } from "../../../store/slices/appSlice";
import {
  CancelButton,
  CreateButton,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { selectUser } from "../../../store/slices/authSlice";
import ErrorHandling from "../../common/ErrorHandling";
import ResourceAttachmentForm from "./ResourceAttachmentForm";
import { useUpdateResourceAttachmentMutation } from "../../../store/slices/api/resourceAttachmentsApiSlice";
import { DEFAULT_RESOURCE_ATTACHMENT } from "../../../store/slices/resourceAttachmentSlice";

const EditAttachmentDialog = ({
  resourceId,
  open,
  setOpen,
  resourceAttachment,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const { isActionButtonDisabled } = useSelector(selectPageInfo);

  // States
  const [resourceAttachmentInput, setResourceAttachmentInput] =
    useState(resourceAttachment);

  const [name, setName] = useState(
    resourceAttachmentInput?.name?.substring(
      0,
      resourceAttachmentInput?.name?.lastIndexOf(".")
    )
  );

  const [extension, setExtension] = useState(
    resourceAttachmentInput?.name?.substring(
      resourceAttachmentInput?.name?.lastIndexOf(".")
    )
  );

  // Mutations
  const [updateResourceAttachment, { isLoading: isLoadingUpdate }] =
    useUpdateResourceAttachmentMutation();

  // Handlers
  const handleClose = () => {
    setOpen(false);
    setResourceAttachmentInput(DEFAULT_RESOURCE_ATTACHMENT);
  };

  const handleSubmit = useCallback(async () => {
    try {
      if (!resourceAttachmentInput.selectedFile) {
        messageError(getTranslation("ATTACHMENT_IS_REQUIRED", t, i18n));
        return;
      }

      const formData = new FormData();

      const { file, description } = resourceAttachmentInput;

      formData.append("file", file);
      formData.append("fileName", name + extension);
      formData.append("description", description);

      await updateResourceAttachment({
        formData,
        resourceid: resourceId,
        organizationId,
        updateAttachment: Boolean(file),
        resourceAttachmentId: resourceAttachment?.id,
      }).unwrap();

      messageSuccess(
        getTranslation("RESOURCE_ATTACHMENT_UPDATED_SUCCESSFULLY", t, i18n)
      );
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to update resource attachment", error);
    }

    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceAttachmentInput, name]);

  return (
    <ErrorHandling isLoading={isLoadingUpdate}>
      <Dialog
        id="edit-attachment-dialog"
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle>
          {getTranslation("CHOOSE_ATTACHMENT", t, i18n)}
        </DialogTitle>
        <StaticHeightDialogContent>
          <ResourceAttachmentForm
            resourceAttachmentInput={resourceAttachmentInput}
            setResourceAttachmentInput={setResourceAttachmentInput}
            name={name}
            setName={setName}
            setExtension={setExtension}
          />
        </StaticHeightDialogContent>
        <DialogActions>
          <CancelButton variant="outlined" onClick={handleClose}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelButton>
          <CreateButton
            disabled={isActionButtonDisabled || isLoadingUpdate}
            onClick={handleSubmit}
          >
            {getTranslation("SUBMIT", t, i18n)}
          </CreateButton>
        </DialogActions>
      </Dialog>
    </ErrorHandling>
  );
};

export default EditAttachmentDialog;
