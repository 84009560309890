import { Stack } from "@mui/material";
import React from "react";
import {
  AreaStack,
  StyledLine,
} from "../../../styles/reports/ChartWidgets.styles";

const AreaChartWidgetContent = ({ data }) => {
  return (
    <AreaStack id="area-chart-widget">
      <StyledLine
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
            },
            interaction: {
              mode: "nearest",
              axis: "x",
              intersect: false,
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Month",
                },
              },
              y: {
                stacked: true,
                title: {
                  display: true,
                  text: "Value",
                },
              },
            },
          },
        }}
        data={data}
      />
    </AreaStack>
  );
};

export default AreaChartWidgetContent;
