import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../../store/slices/authSlice";
import {
  useGetUserProfileImageQuery,
  useUserRolePermissionsQuery,
} from "../../../store/slices/api/userManagementSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  transitionDirections,
} from "../../../util/utils";
import {
  BackPageTitle,
  HeaderGridContainer,
  HeaderWrapper,
  StyledAvatar,
} from "../../../components/styles/header/Header.styles";
import { Grid, Stack } from "@mui/material";
import AssetsImportExport from "../../../components/assets/asset-list/AssetsImportExport";

const HomeHeader = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const { data: selectedFile } = useGetUserProfileImageQuery(
    {
      avatarUri: user?.avatarUri,
      thumbnail: true,
    },
    {
      skip: !Boolean(user?.avatarUri),
    }
  );

  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolePermissionsQuery({
      userId: user.id,
      organizationId:
        organizationId ?? user?.organizations?.find((o) => o.default).id,
    });

  // Handlers
  const onAvatarClickHandler = () => {
    navigate(`/profile?direction=${transitionDirections.RIGHT_TO_LEFT}`);
  };

  return (
    <HeaderWrapper id="sticky-header">
      <HeaderGridContainer container>
        <Grid item xs>
          <BackPageTitle variant="h4" id="title">
            {getTranslation("home", t, i18n)}
          </BackPageTitle>
        </Grid>
        <Grid item>
          <Stack spacing={2} direction="row" alignItems="center">
            {hasAccess(
              "oneOf",
              [
                permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
                permissions.ASSET_MANAGEMENT_RACK_ADD,
                permissions.ASSET_MANAGEMENT_LOCATION_ADD,
              ],
              getPermissionsFromUserRoles(userRoles)
            ) && <AssetsImportExport />}
            <StyledAvatar
              id="profile-menu-link"
              src={selectedFile}
              onClick={onAvatarClickHandler}
            >
              {user?.shortUsername}
            </StyledAvatar>
          </Stack>
        </Grid>
      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

export default HomeHeader;
