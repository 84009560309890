import { useTranslation } from "react-i18next";
import SectionHeader from "../../components/SectionHeader";
import { ChartsContainer } from "../../components/styles/home/ChartPage.styles";
import { useReportExecutionQuery } from "../../store/slices/api/reportsApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  ArcElement,
} from "chart.js";
import ReportAssetCard from "../../components/reports/ReportAssetCard";
import ReportAssetChart from "../../components/reports/ReportAssetChart";
import { getTranslation } from "../../util/utils";
import ReportStatisticCard from "../../components/reports/ReportStatisticCard";
import {
  AGGREGATION_COUNT_KEY,
  ASSET_COUNT_PER_LOCATION_WIDGET_REPORT_DEFINITION_ID,
  ASSET_COUNT_PER_RACK_WIDGET_REPORT_DEFINITION_ID,
  HARDWARE_ASSETS_BY_FUNCTION_WIDGET_REPORT_DEFINITION_ID,
  HARDWARE_ASSETS_BY_VENDOR_WIDGET_REPORT_DEFINITION_ID,
  HARDWARE_ASSET_WIDGET_REPORT_DEFINITION_ID,
  RACK_WIDGET_REPORT_DEFINITION_ID,
  ZONE_WIDGET_REPORT_DEFINITION_ID,
} from "../../Constants";
import {
  AGGREGATION_RESULT_CONTENT,
  getAverage,
  getMax,
  getMin,
} from "../../util/reports-utils";
import ErrorHandling from "../../components/common/ErrorHandling";
import { useDispatch } from "react-redux";
import { setReportId } from "../../store/slices/reportSlice";
import { useMediaQuery } from "@mui/material";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  ArcElement
);

const ChartSection = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const {
    data: hardwareAssetsWidgetData,
    isLoading: isLoadingHardwareAssetsWidget,
    isError: isErrorHardwareAssetsWidget,
  } = useReportExecutionQuery(
    {
      organizationId,
      reportDefinitionId: HARDWARE_ASSET_WIDGET_REPORT_DEFINITION_ID,
      aggregationResultContent: `?aggregation-result-content=${AGGREGATION_RESULT_CONTENT.AGGREGATION}`,
    },
    {
      skip: !Boolean(organizationId),
    }
  );

  const {
    data: rackWidgetData,
    isLoading: isLoadingRackWidget,
    isError: isErrorRackWidget,
  } = useReportExecutionQuery(
    {
      organizationId,
      reportDefinitionId: RACK_WIDGET_REPORT_DEFINITION_ID,
      aggregationResultContent: `?aggregation-result-content=${AGGREGATION_RESULT_CONTENT.AGGREGATION}`,
    },
    {
      skip: !Boolean(organizationId),
    }
  );

  const {
    data: zonesWidgetData,
    isLoading: isLoadingZonesWidget,
    isError: isErrorZonesWidget,
  } = useReportExecutionQuery(
    {
      organizationId,
      reportDefinitionId: ZONE_WIDGET_REPORT_DEFINITION_ID,
      aggregationResultContent: `?aggregation-result-content=${AGGREGATION_RESULT_CONTENT.AGGREGATION}`,
    },
    {
      skip: !Boolean(organizationId),
    }
  );

  const {
    data: assetCountPerLocationWidgetData,
    isLoading: isLoadingAssetCountPerLocationWidget,
    isError: isErrorAssetCountPerLocationWidget,
  } = useReportExecutionQuery(
    {
      organizationId,
      reportDefinitionId: ASSET_COUNT_PER_LOCATION_WIDGET_REPORT_DEFINITION_ID,
      aggregationResultContent: `?aggregation-result-content=${AGGREGATION_RESULT_CONTENT.AGGREGATION}`,
    },
    {
      skip: !Boolean(organizationId),
    }
  );

  const {
    data: assetCountPerRackWidgetData,
    isLoading: isLoadingAssetCountPerRackWidget,
    isError: isErrorAssetCountPerRackWidget,
  } = useReportExecutionQuery(
    {
      organizationId,
      reportDefinitionId: ASSET_COUNT_PER_RACK_WIDGET_REPORT_DEFINITION_ID,
      aggregationResultContent: `?aggregation-result-content=${AGGREGATION_RESULT_CONTENT.AGGREGATION}`,
    },
    {
      skip: !Boolean(organizationId),
    }
  );

  const {
    data: hardwareAssetsByFunctionsData,
    isLoading: isLoadingHardwareAssetsByFunctionsData,
    isError: isErrorHardwareAssetsByFunctions,
  } = useReportExecutionQuery(
    {
      organizationId,
      reportDefinitionId:
        HARDWARE_ASSETS_BY_FUNCTION_WIDGET_REPORT_DEFINITION_ID,
      aggregationResultContent: `?aggregation-result-content=${AGGREGATION_RESULT_CONTENT.AGGREGATION}`,
    },
    {
      skip: !Boolean(organizationId),
    }
  );

  const {
    data: hardwareAssetsByManufacturersData,
    isLoading: isLoadingHardwareAssetsByManufacturersData,
    isError: isErrorHardwareAssetsByManufacturers,
  } = useReportExecutionQuery(
    {
      organizationId,
      reportDefinitionId: HARDWARE_ASSETS_BY_VENDOR_WIDGET_REPORT_DEFINITION_ID,
      aggregationResultContent: `?aggregation-result-content=${AGGREGATION_RESULT_CONTENT.AGGREGATION}`,
    },
    {
      skip: !Boolean(organizationId),
    }
  );

  // Handlers
  const handleNavigateToReports = () => navigate("/reports");

  const handleNavigateToReportsDetails = (reportId) =>
    navigate(`/reports/${reportId}`);

  const handleNavigateToHardwareAssetsReport = () => {
    if (desktopMatches) {
      dispatch(setReportId(HARDWARE_ASSET_WIDGET_REPORT_DEFINITION_ID));
      handleNavigateToReports();
    } else {
      handleNavigateToReportsDetails(
        HARDWARE_ASSET_WIDGET_REPORT_DEFINITION_ID
      );
    }
  };

  const handleNavigateToRacksReport = () => {
    if (desktopMatches) {
      dispatch(setReportId(RACK_WIDGET_REPORT_DEFINITION_ID));
      handleNavigateToReports();
    } else {
      handleNavigateToReportsDetails(RACK_WIDGET_REPORT_DEFINITION_ID);
    }
  };

  const handleNavigateToZoneReport = () => {
    if (desktopMatches) {
      dispatch(setReportId(ZONE_WIDGET_REPORT_DEFINITION_ID));
      handleNavigateToReports();
    } else {
      handleNavigateToReportsDetails(ZONE_WIDGET_REPORT_DEFINITION_ID);
    }
  };

  const handleNavigateToAssetListPerLocationReport = () => {
    if (desktopMatches) {
      dispatch(
        setReportId(ASSET_COUNT_PER_LOCATION_WIDGET_REPORT_DEFINITION_ID)
      );
      handleNavigateToReports();
    } else {
      handleNavigateToReportsDetails(
        ASSET_COUNT_PER_LOCATION_WIDGET_REPORT_DEFINITION_ID
      );
    }
  };

  const handleNavigateToAssetListPerRackReport = () => {
    if (desktopMatches) {
      dispatch(setReportId(ASSET_COUNT_PER_RACK_WIDGET_REPORT_DEFINITION_ID));
      handleNavigateToReports();
    } else {
      handleNavigateToReportsDetails(
        ASSET_COUNT_PER_RACK_WIDGET_REPORT_DEFINITION_ID
      );
    }
  };

  const handleNavigateToHardwareAssetsByFunctionReport = () => {
    if (desktopMatches) {
      dispatch(
        setReportId(HARDWARE_ASSETS_BY_FUNCTION_WIDGET_REPORT_DEFINITION_ID)
      );

      handleNavigateToReports();
    } else {
      handleNavigateToReportsDetails(
        HARDWARE_ASSETS_BY_FUNCTION_WIDGET_REPORT_DEFINITION_ID
      );
    }
  };

  const handleNavigateToHardwareAssetsByVendorReport = () => {
    if (desktopMatches) {
      dispatch(
        setReportId(HARDWARE_ASSETS_BY_VENDOR_WIDGET_REPORT_DEFINITION_ID)
      );

      handleNavigateToReports();
    } else {
      handleNavigateToReportsDetails(
        HARDWARE_ASSETS_BY_VENDOR_WIDGET_REPORT_DEFINITION_ID
      );
    }
  };

  // Pie Chart Assets By Category
  const transformedReportWidgetByCategory =
    hardwareAssetsByFunctionsData?.data?.map((rw) => {
      return {
        label: rw["resource.function.name"],
        count: rw[AGGREGATION_COUNT_KEY],
      };
    });

  const transformReportWidgetManufacturers =
    hardwareAssetsByManufacturersData?.data?.map((rw) => {
      return {
        label: rw["resource.characteristic.manufacturer"],
        count: rw[AGGREGATION_COUNT_KEY],
      };
    });

  return (
    <ErrorHandling
      isLoading={false}
      isError={
        isErrorHardwareAssetsWidget ||
        isErrorRackWidget ||
        isErrorZonesWidget ||
        isErrorAssetCountPerLocationWidget ||
        isErrorAssetCountPerRackWidget ||
        isErrorHardwareAssetsByFunctions ||
        isErrorHardwareAssetsByManufacturers
      }
    >
      <div id="reports-container">
        <SectionHeader
          title="REPORTS"
          titleId="reportsHeader"
          actionName="openReports"
          actionNameId="open-reports"
          action={handleNavigateToReports}
          actionNameVisible={true}
        />
        <ChartsContainer id="reports-section">
          <ReportAssetCard
            displayId={"hardware-assets-chart"}
            onClick={handleNavigateToHardwareAssetsReport}
            title={getTranslation("HARDWARE_ASSETS", t, i18n)}
            assetCount={
              hardwareAssetsWidgetData?.data[0]?.[
                "resource.id.aggregation-count"
              ]
            }
            recentCreatedCount={
              hardwareAssetsWidgetData?.data[0]?.[
                "resource.id.aggregation-count-1"
              ]
            }
            isLoading={isLoadingHardwareAssetsWidget}
          />

          <ReportAssetCard
            displayId="racks-chart"
            onClick={handleNavigateToRacksReport}
            title={getTranslation("RACKS", t, i18n)}
            assetCount={
              rackWidgetData?.data[0]?.["resource.id.aggregation-count"]
            }
            recentCreatedCount={
              rackWidgetData?.data[0]?.["resource.id.aggregation-count-1"]
            }
            isLoading={isLoadingRackWidget}
          />

          <ReportAssetChart
            displayId="hardware-assets-by-function-chart"
            onClick={handleNavigateToHardwareAssetsByFunctionReport}
            title={getTranslation("HARDWARE_ASSETS_BY_FUNCTION", t, i18n)}
            data={transformedReportWidgetByCategory}
            isLoading={isLoadingHardwareAssetsByFunctionsData}
          />

          <ReportAssetChart
            displayId="hardware-assets-by-vendor-chart"
            onClick={handleNavigateToHardwareAssetsByVendorReport}
            title={getTranslation("HARDWARE_ASSETS_BY_VENDOR", t, i18n)}
            data={transformReportWidgetManufacturers?.filter((item) =>
              Boolean(item.label)
            )}
            isLoading={isLoadingHardwareAssetsByManufacturersData}
          />

          <ReportAssetCard
            displayId="zone-list-chart"
            onClick={handleNavigateToZoneReport}
            title={getTranslation("ZONE_LIST", t, i18n)}
            assetCount={
              zonesWidgetData?.data[0]?.["resource.id.aggregation-count"]
            }
            recentCreatedCount={
              zonesWidgetData?.data[0]?.["resource.id.aggregation-count-1"]
            }
            isLoading={isLoadingZonesWidget}
          />

          <ReportStatisticCard
            displayId="asset-list-per-location-chart"
            onClick={handleNavigateToAssetListPerLocationReport}
            title={getTranslation("ASSET_LIST_PER_LOCATION", t, i18n)}
            min={getMin(assetCountPerLocationWidgetData?.data) ?? 0}
            max={getMax(assetCountPerLocationWidgetData?.data) ?? 0}
            average={getAverage(assetCountPerLocationWidgetData?.data) ?? 0}
            isLoading={isLoadingAssetCountPerLocationWidget}
          />

          <ReportStatisticCard
            displayId="asset-list-per-rack-chart"
            onClick={handleNavigateToAssetListPerRackReport}
            title={getTranslation("ASSET_LIST_PER_RACK", t, i18n)}
            min={getMin(assetCountPerRackWidgetData?.data) ?? 0}
            max={getMax(assetCountPerRackWidgetData?.data) ?? 0}
            average={getAverage(assetCountPerRackWidgetData?.data) ?? 0}
            isLoading={isLoadingAssetCountPerRackWidget}
          />
        </ChartsContainer>
      </div>
    </ErrorHandling>
  );
};

export default ChartSection;
