import React from "react";
import {
  LineStack,
  StyledLine,
} from "../../../styles/reports/ChartWidgets.styles";

const LineChartWidgetContent = ({ data, assignment = "x" }) => {
  return (
    <LineStack id="line-chart-widget">
      <StyledLine
        options={{
          indexAxis: assignment,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
            },
          },
          scales: {
            x: {
              beginAtZero: true,
            },
          },
        }}
        data={data}
      />
    </LineStack>
  );
};

export default LineChartWidgetContent;
