import { List, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import AssetResourceAttachment from "./AssetResourceAttachment";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectUser } from "../../../store/slices/authSlice";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import {
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  RESOURCE_CATEGORIES,
  transitionDirections,
} from "../../../util/utils";
import { ResourceAttachmentsListContainer } from "../../styles/assets/asset-attachments/ResourceAttachmentsList.styles";
import {
  selectGlobalFontSize,
  selectPageView,
  selectTheme,
} from "../../../store/slices/appSlice";
import ErrorHandling from "../../common/ErrorHandling";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { useEffect, useRef, useState } from "react";
import AddAttachmentDialog from "../asset-form/AddAttachmentDialog";
import { selectParentId } from "../../../store/slices/columnViewSlice";
import { SecondaryContrastButton } from "../../styles/general/General.styles";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { selectCurrentResourceId } from "../../../store/slices/longPressSlice";
import { getSvgIcon } from "../../../util/icons";

const ResourceAttachmentList = ({ attachments }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const { resourceid } = useParams();
  const navigate = useNavigate();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);
  const parentId = useSelector(selectParentId);
  const currentTheme = useSelector(selectTheme);
  const currentResourceId = useSelector(selectCurrentResourceId);
  const currentPageView = useSelector(selectPageView);

  // Refs
  const textRef = useRef(null);
  const iconRef = useRef(null);

  // State
  const [openAttachmentDialog, setOpenAttachmentDialog] = useState(false);
  const [borderWidth, setBorderWidth] = useState("25%");

  // Other variables
  const leftPadding = 16;
  const calculatedResourceId = currentResourceId || resourceid || parentId;
  const iconSize = globalFontSize * 1.2;

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  const {
    data: resourceData,
    isLoading: isLoadingResourceData,
    isError: isErrorResourceData,
  } = useGetResourceDetailsQuery(
    {
      resourceid: calculatedResourceId,
      organizationId,
    },
    { skip: !Boolean(resourceid) && !Boolean(parentId) }
  );

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Other variables
  const isLoading =
    currentPageView !== "column"
      ? isLoadingUserRoles || isLoadingResourceData || isLoadingFunctions
      : false;

  // Handlers
  const handlePermissions = (key) => {
    if (!Boolean(resourceid) && !Boolean(parentId)) {
      return true;
    }

    const functionDetails = allFunctionsData?.find(
      (f) => f.id === resourceData?.functionId
    );

    const permissionsKey = `ASSET_MANAGEMENT_${
      functionDetails?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET
        ? "HARDWARE"
        : functionDetails?.category
    }_${key.toUpperCase()}`;

    if (
      hasAccess(
        "all",
        [permissions[permissionsKey]],
        getPermissionsFromUserRoles(userRoles)
      )
    ) {
      return true;
    }

    return false;
  };

  const handleAddResourceAttachmentClick = () => {
    if (mobileMatches) {
      const resourceId = calculatedResourceId;

      navigate(
        `/resources/${resourceId}/add-attachment?direction=${transitionDirections.RIGHT_TO_LEFT}`
      );
    } else {
      setOpenAttachmentDialog(true);
    }
  };

  // Effects
  useEffect(() => {
    if (textRef.current && iconRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const totalWidth = textRef.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRef.current.offsetWidth) / totalWidth) *
        100;

      setBorderWidth(`${percentage}%`);
    }
  }, []);

  return (
    <ErrorHandling
      isLoading={isLoading}
      isError={isErrorUserRoles || isErrorResourceData || isErrorFunctions}
    >
      <StyledAccordion defaultExpanded>
        <StyledAccordionSummary
          value={borderWidth}
          expandIcon={
            <div style={{ marginTop: "5px", marginRight: "5px" }} ref={iconRef}>
              {getSvgIcon(
                "ARROW_RIGHT",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </div>
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <SectionTitle variant="body2" istablet={1} ref={textRef}>
            {getTranslation("ATTACHMENTS", t, i18n)}
          </SectionTitle>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <ResourceAttachmentsListContainer id="attachments">
            <List id="resource-attachment-list">
              {attachments
                ?.slice()
                ?.sort((a, b) =>
                  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                )
                ?.map((resourceAttachment, index) => {
                  const attachment = {
                    selectedFile: resourceAttachment.uri,
                    resourceid: resourceData?.id,
                    id: resourceAttachment.id,
                    name: resourceAttachment.name,
                    description: resourceAttachment.description,
                    mimeType: resourceAttachment.name.substring(
                      resourceAttachment.name.lastIndexOf(".")
                    ),
                    file: null,
                  };

                  return (
                    <AssetResourceAttachment
                      id="resource-attachment"
                      key={index}
                      attachment={attachment}
                      resourceid={calculatedResourceId}
                    />
                  );
                })}

              {handlePermissions("add") && (
                <SecondaryContrastButton
                  id="add-attachment-btn"
                  sx={{ marginTop: "10px" }}
                  onClick={handleAddResourceAttachmentClick}
                >
                  <div style={{ marginRight: "5px", marginTop: "5px" }}>
                    {getSvgIcon(
                      "CREATE_NEW",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.contrastText
                    )}
                  </div>

                  {getTranslation("ADD_ATTACHMENT", t, i18n)}
                </SecondaryContrastButton>
              )}
            </List>

            {openAttachmentDialog && (
              <AddAttachmentDialog
                open={openAttachmentDialog}
                setOpen={setOpenAttachmentDialog}
                resourceId={calculatedResourceId}
              />
            )}
          </ResourceAttachmentsListContainer>
        </StyledAccordionDetails>
      </StyledAccordion>
    </ErrorHandling>
  );
};

export default ResourceAttachmentList;
