import { Grid, Stack } from "@mui/material";
import React from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";

const MinMaxAverageWidgetContent = ({ title, min, max, avg }) => {
  return (
    <Stack
      id="min-max-average-widget"
      sx={{
        flexGrow: "1",
        width: "100%",
        justifyContent: "center",
      }}
      spacing={2}
    >
      <Grid container sx={{ paddingInline: "16px" }}>
        <Grid item xs>
          <SecondaryText>Max</SecondaryText>
        </Grid>
        <Grid item>
          <SecondaryText>{max && max !== "undefined" ? max : 0}</SecondaryText>
        </Grid>
      </Grid>
      <Grid container sx={{ paddingInline: "16px" }}>
        <Grid item xs>
          <SecondaryText fontWeight="bolder">Avg</SecondaryText>
        </Grid>
        <Grid item>
          <SecondaryText fontWeight="bolder">
            {avg && avg !== "undefined" ? avg : 0}
          </SecondaryText>
        </Grid>
      </Grid>
      <Grid container sx={{ paddingInline: "16px" }}>
        <Grid item xs>
          <SecondaryText>Min</SecondaryText>
        </Grid>
        <Grid item>
          <SecondaryText>{min && min !== "undefined" ? min : 0}</SecondaryText>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default MinMaxAverageWidgetContent;
