import {
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CancelButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../styles/general/General.styles";
import { getTranslation } from "../../util/utils";
import { StickySearchStyledDialogListItemButton } from "../styles/assets/StickySearch.styles";
import { getSvgIcon } from "../../util/icons";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import { StyledDivider } from "../styles/profile/Profile.styles";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const RoleChip = ({ organizationRoles, role, onChangeRole }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [open, setOpen] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  // Other variables
  const iconSize = globalFontSize;
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleOpenOptionsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptionsMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangeRole = (role) => {
    onChangeRole(role);
  };

  return (
    <>
      <Chip
        label={`${getTranslation("role", t, i18n)}: ${getTranslation(
          role?.name ?? "ALL",
          t,
          i18n
        )}`}
        sx={{
          backgroundColor: theme.palette.secondary.active,
          color: theme.palette.primary.main,
        }}
        onClick={(e) => {
          if (mobileMatches) {
            handleOpen();
          } else {
            handleOpenOptionsMenu(e);
          }
        }}
      />
      <Menu
        slotProps={{
          paper: {
            elevation: 1,
            sx: {
              borderRadius: "8px",
            },
          },
        }}
        aria-labelledby="more-icon"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id="menu-options"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseOptionsMenu}
      >
        <MenuItem
          sx={{
            backgroundColor:
              role === null ? theme.palette.secondary.contrastText : "",
            "&:hover": {
              backgroundColor:
                role === null ? theme.palette.secondary.contrastText : "",
            },
          }}
          onClick={() => {
            handleCloseOptionsMenu();
            handleChangeRole(null);
          }}
        >
          <ListItemIcon>
            {getSvgIcon(
              "CHECK",
              iconSize,
              iconSize,
              theme.palette.secondary.dark,
              {
                visibility: role === null ? "visible" : "hidden",
                padding: "8px",
              }
            )}
          </ListItemIcon>
          <ListItemText>{getTranslation("ALL", t, i18n)}</ListItemText>
        </MenuItem>
        <StyledDivider />
        {organizationRoles?.length > 0 &&
          organizationRoles.map((r) => {
            const isActive = role?.id === r.id;
            return (
              <MenuItem
                sx={{
                  backgroundColor: isActive
                    ? theme.palette.secondary.contrastText
                    : "",
                  "&:hover": {
                    backgroundColor: isActive
                      ? theme.palette.secondary.contrastText
                      : "",
                  },
                }}
                onClick={() => {
                  handleCloseOptionsMenu();
                  handleChangeRole(r);
                }}
              >
                <ListItemIcon>
                  {getSvgIcon(
                    "CHECK",
                    iconSize,
                    iconSize,
                    theme.palette.primary.main,
                    {
                      visibility: isActive ? "visible" : "hidden",
                      padding: "8px",
                    }
                  )}
                </ListItemIcon>
                <ListItemText>{getTranslation(r.name, t, i18n)}</ListItemText>
              </MenuItem>
            );
          })}
      </Menu>
      <StyledDialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <StyledDialogContent>
          <StyledDialogTitle>
            <List>
              <ListItem
                onClick={() => {
                  handleClose();
                  handleChangeRole(null);
                }}
                disablePadding
                secondaryAction={getSvgIcon(
                  "CHECK",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.dark,
                  {
                    visibility: role === null ? "visible" : "hidden",
                  }
                )}
              >
                <StickySearchStyledDialogListItemButton>
                  {getSvgIcon(
                    "CHECK",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.dark,
                    {
                      visibility: "hidden",
                      padding: "8px",
                    }
                  )}
                  <ListItemText align="center">
                    {getTranslation("ALL", t, i18n)}
                  </ListItemText>
                </StickySearchStyledDialogListItemButton>
              </ListItem>
              {organizationRoles?.length > 0 &&
                organizationRoles.map((r) => (
                  <ListItem
                    onClick={() => {
                      handleClose();
                      handleChangeRole(r);
                    }}
                    disablePadding
                    secondaryAction={getSvgIcon(
                      "CHECK",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.dark,
                      {
                        visibility: role?.id === r.id ? "visible" : "hidden",
                      }
                    )}
                  >
                    <StickySearchStyledDialogListItemButton>
                      {getSvgIcon(
                        "CHECK",
                        iconSize,
                        iconSize,
                        theme.palette.secondary.dark,
                        {
                          visibility: "hidden",
                          padding: "8px",
                        }
                      )}
                      <ListItemText align="center">
                        {getTranslation(r.name, t, i18n)}
                      </ListItemText>
                    </StickySearchStyledDialogListItemButton>
                  </ListItem>
                ))}
            </List>
          </StyledDialogTitle>
        </StyledDialogContent>
        <CancelButton id={`cancel-button`} onClick={handleClose}>
          {getTranslation("CANCEL", t, i18n)}
        </CancelButton>
      </StyledDialog>
    </>
  );
};
