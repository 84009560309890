import {
  Box,
  FormControlLabel,
  MenuList,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ProfilePreferenceMenuItem from "../../components/ProfilePreferenceItem";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../../store/slices/authSlice";
import {
  useDeleteProfileImageMutation,
  useGetUserDataStorageLocationQuery,
  useGetUserProfileImageQuery,
  useUploadProfileImageMutation,
} from "../../store/slices/api/userManagementSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import UploadImage from "../../components/UploadImage";
import { StyledDivider } from "../../components/styles/profile/Profile.styles";
import { messageError, messageSuccess } from "../../util/notification";
import KeycloakService from "../../services/KeycloakService";
import PageTransition from "../../components/PageTransition";
import { transitionDirections, getTranslation } from "../../util/utils";
import UserDetailHeader from "../../navigation/header/profile/UserDetailHeader";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import ErrorHandling from "../../components/common/ErrorHandling";
import {
  HomePagePadding,
  SecondaryDarkText,
  StyledSwitch,
} from "../../components/styles/general/General.styles";
import AppAccess from "../../components/common/AppAccess";

const UserDetailPage = () => {
  // Global hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const user = useSelector(selectUser);

  // Queries
  const { data: profileImage } = useGetUserProfileImageQuery(
    {
      avatarUri: user?.avatarUri,
      thumbnail: true,
    },
    {
      skip: !Boolean(user?.avatarUri),
    }
  );

  const {
    data: orgDataStorageLocation,
    isLoading: isLoadingOrgDataStorageLocation,
  } = useGetUserDataStorageLocationQuery({ userId: user.id });

  // Mutations
  const [uploadImage, { isLoading: isLoadingUploadImage }] =
    useUploadProfileImageMutation();

  const [deleteProfileImage, { isLoading: isLoadingDeleteProfileImage }] =
    useDeleteProfileImageMutation();

  // Other variables
  const transitionDirection = searchParams.get("direction");

  // Handlers
  const handleUpdateNameClick = () => {
    navigate(
      `/profile/update-user-name?direction=${transitionDirections.BOTTOM_TO_TOP}`
    );
  };

  // Handlers
  const handleUpdateNicknameClick = () => {
    navigate(
      `/profile/update-user-nickname?direction=${transitionDirections.BOTTOM_TO_TOP}`
    );
  };

  const handleChangePassword = () => {
    const authUrl = KeycloakService.getAuthUrl();
    const frontEndUrl = window.location.protocol + "//" + window.location.host;
    const redirectUrl = mobileMatches ? "/profile/userdetail" : "/profile";

    const urlForChangePassword =
      authUrl +
      "?response_type=code&client_id=web-app&redirect_uri=" +
      frontEndUrl +
      `${redirectUrl}&kc_action=UPDATE_PASSWORD`;
    window.open(urlForChangePassword, "_self", "noreferrer");
  };

  const submitUploadedImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      let resp = await uploadImage({ formData, userId: user.id }).unwrap();
      dispatch(setUser({ ...user, avatarUri: resp.avatarUri }));
      messageSuccess(getTranslation("successfulUpdateUserImage", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedUpdateUserImage", t, i18n));
    }
  };

  const handleDeleteProfileImage = async () => {
    try {
      await deleteProfileImage({ userId: user.id }).unwrap();
      dispatch(setUser({ ...user, avatarUri: "" }));
      messageSuccess(getTranslation("PROFILE_IMAGE_DELETED", t, i18n));
    } catch (error) {
      console.error("Failed to delete profile image");
    }
  };

  const goBackHandler = () =>
    navigate(`/profile?direction=${transitionDirections.LEFT_TO_RIGHT}`);

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={
          isLoadingUploadImage ||
          isLoadingDeleteProfileImage ||
          isLoadingOrgDataStorageLocation
        }
        isError={false}
      >
        <PageTransition direction={transitionDirection}>
          <UserDetailHeader goBackHandler={goBackHandler} />
          <HomePagePadding>
            <UploadImage
              initialFile={user?.avatarUri ? profileImage : null}
              submitUploadedImage={submitUploadedImage}
              uploadTitle={
                profileImage && user?.avatarUri ? "EDIT_IMAGE" : "ADD_IMAGE"
              }
              isLoadingImage={isLoadingUploadImage}
              deleteImage={handleDeleteProfileImage}
            />

            <MenuList dense>
              <StyledDivider />
              {/* Profile preference menu item about theme */}
              <ProfilePreferenceMenuItem
                handleClick={handleUpdateNameClick}
                label={getTranslation("name", t, i18n)}
                labelId="btn-user-name"
                menuId="btn-menu-item-user-name"
                value={user.firstName + " " + user.lastName}
              />
              <StyledDivider />
              <ProfilePreferenceMenuItem
                label={getTranslation("WORKMAIL", t, i18n)}
                labelId="btn-user-email"
                menuId="btn-menu-item-user-email"
                value={user.email}
              />
              <StyledDivider />
              <ProfilePreferenceMenuItem
                handleClick={handleUpdateNicknameClick}
                label={getTranslation("nickname", t, i18n)}
                labelId="btn-user-nickname"
                menuId="btn-menu-item-user-nickname"
                value={user.nickname}
              />
              <StyledDivider />
              <ProfilePreferenceMenuItem
                handleClick={() => {}}
                label={getTranslation("DATA_LOCATION", t, i18n)}
                labelId="btn-user-data-location"
                menuId="btn-menu-item-data-location"
                value={
                  orgDataStorageLocation
                    ? i18n.exists(orgDataStorageLocation.displayId)
                      ? t(orgDataStorageLocation.displayId)
                      : orgDataStorageLocation.name
                    : ""
                }
                showrighticon={false}
              />
              <SecondaryDarkText marginLeft="8px" variant="body2">
                {getTranslation("GEO_LOCATION_STORING", t, i18n)}
              </SecondaryDarkText>
              <StyledDivider />
              <ProfilePreferenceMenuItem
                label={getTranslation("change-password", t, i18n)}
                labelId="btn-change-password"
                menuId="btn-menu-item-change-password"
                handleClick={handleChangePassword}
              />
              <StyledDivider />
              <Box
                sx={{
                  paddingInline: "8px",
                }}
              >
                <FormControlLabel
                  sx={{
                    width: "100%",
                    marginLeft: 0,
                    justifyContent: "space-between",
                    color: theme.palette.primary.main,
                  }}
                  id="checkbox-keep-me-updated-label"
                  labelPlacement="start"
                  control={
                    <StyledSwitch
                      id="checkbox-keep-me-updated"
                      defaultChecked={true}
                    />
                  }
                  label={getTranslation("KEEP_ME_UPDATED", t, i18n)}
                />
              </Box>

              <StyledDivider />
            </MenuList>
          </HomePagePadding>
        </PageTransition>
      </ErrorHandling>
    </AppAccess>
  );
};

export default UserDetailPage;
