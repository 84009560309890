import { Grid } from "@mui/material";
import WidgetCard from "./WidgetCard";
import { useGetReportDefinitionWidgetsQuery } from "../../store/slices/api/reportsApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import ErrorHandling from "../common/ErrorHandling";

const WidgetList = ({
  reportId,
  reportTypeId,
  rows,
  aggregations,
  fields,
  fullscreen,
}) => {
  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const { data: widgetsData, isLoading: isLoadingWidgets } =
    useGetReportDefinitionWidgetsQuery(
      {
        organizationId,
        reportDefinitionId: reportId,
      },
      {
        skip: !reportId,
      }
    );

  return (
    <ErrorHandling isLoading={isLoadingWidgets}>
      <Grid container spacing={2}>
        {widgetsData?.map((widget) => (
          <Grid key={widget.id} item xs={12} md={fullscreen ? 4 : 6}>
            <WidgetCard
              widget={widget}
              reportId={reportId}
              reportTypeId={reportTypeId}
              rows={rows}
              aggregations={aggregations}
              fields={fields}
            />
          </Grid>
        ))}
      </Grid>
    </ErrorHandling>
  );
};

export default WidgetList;
