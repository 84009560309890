import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  setOrganizationId,
} from "../../../store/slices/appSlice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { selectUser } from "../../../store/slices/authSlice";
import {
  useGetInvitedUsersQuery,
  useUserRolePermissionsQuery,
} from "../../../store/slices/api/userManagementSlice";
import { Grid, IconButton, Stack, TextField, useTheme } from "@mui/material";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  transitionDirections,
} from "../../../util/utils";
import { useTranslation } from "react-i18next";
import PageTransition from "../../../components/PageTransition";
import UserManagementHeader from "../../../navigation/header/organization/UserManagementHeader";
import {
  useGetOrganizationRolesQuery,
  useGetOrganizationUsersQuery,
} from "../../../store/slices/api/organizationsApiSlice";
import Access from "../../../components/common/Access";
import { useEffect, useState } from "react";
import {
  HomePagePadding,
  SecondaryContrastButton,
} from "../../../components/styles/general/General.styles";
import ErrorHandling from "../../../components/common/ErrorHandling";
import { useGetCurrentSubscriptionActualUsageQuery } from "../../../store/slices/api/subscriptionsApiSlice";
import { INVITATION_STATUS } from "../../../components/InvitationForm";
import useCheckOrganizationRestricted from "../../../hooks/useCheckOrganizationRestricted";
import AppAccess from "../../../components/common/AppAccess";
import { getSvgIcon } from "../../../util/icons";
import { PrimaryText } from "../../../components/styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import OrganizationUserAvatarsList from "../../../components/organizations/OrganizationUserAvatarsList";
import { RoleChip } from "../../../components/organizations/RoleChip";
import StatusChip from "../../../components/organizations/StatusChip";
import OrganizationUserList from "../../../components/organizations/OrganizationUserList";
import { useDispatch } from "react-redux";
import Layer2AccessOrganization from "../../../components/common/Layer2AccessOrganization";

const DEFAULT_FILTER = {
  searchValue: "",
  role: null,
  status: null,
  isActive30Days: false,
};

const UserManagementPage = () => {
  // Global hooks
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [openSearch, setOpenSearch] = useState(false);

  // Other variables
  const iconSize = globalFontSize * 1.2;

  const organization = user?.organizations?.find(
    (o) => o.id === organizationId
  );

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(organization);

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery(
    {
      organizationId,
      userId: user.id,
    },
    { skip: !organizationId }
  );

  const isOrganizationRestricted = hasAccess(
    "all",
    [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
    getPermissionsFromUserRoles(userRoles)
  )
    ? false
    : isRestricted;

  const {
    data: invitedUsers,
    isLoading: isLoadingInvitedUsers,
    isError: isErrorInvitedUsers,
  } = useGetInvitedUsersQuery(organizationId, {
    skip:
      !hasAccess(
        "all",
        [permissions.USER_MANAGEMENT_VIEW],
        getPermissionsFromUserRoles(userRoles)
      ) ||
      !organizationId ||
      isOrganizationRestricted,
  });

  const {
    data: organizationUsers,
    isLoading: isLoadingOrganizationUsers,
    isError: isErrorOrganizationUsers,
  } = useGetOrganizationUsersQuery(organizationId, {
    skip:
      !hasAccess(
        "all",
        [permissions.USER_MANAGEMENT_VIEW],
        getPermissionsFromUserRoles(userRoles)
      ) ||
      !organizationId ||
      isOrganizationRestricted,
  });

  const {
    data: actualUsage,
    isLoading: isLoadingActualUsage,
    isError: isErrorSubscriptionActualUsage,
  } = useGetCurrentSubscriptionActualUsageQuery(
    {
      organizationId,
    },
    { skip: !organizationId || isRestricted }
  );

  const {
    data: organizationRoles,
    isLoading: isLoadingOrganizationRoles,
    isError: isErrorOrganizationRoles,
  } = useGetOrganizationRolesQuery(organizationId, {
    skip:
      !organizationId ||
      !hasAccess(
        "all",
        [permissions.ORG_MANAGEMENT_EDIT],
        getPermissionsFromUserRoles(userRoles)
      ) ||
      isOrganizationRestricted,
  });

  // Other variables
  const transitionDirection = searchParams.get("direction");
  const facts = actualUsage?.facts;
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue ?? 0);

  const sentInvitationsCount =
    invitedUsers?.filter((iu) => iu.status === INVITATION_STATUS.SENT)
      ?.length ?? 0;

  const organizationUsersCount = organizationUsers?.length ?? 0;
  const usersCurrentValue = sentInvitationsCount + organizationUsersCount;

  const isInviteDisabled =
    isRestricted || usersCurrentValue >= usersSubscriptionValue;

  const redirectUri =
    "/profile/organization/" +
    organizationId +
    `?direction=${transitionDirections.TOP_TO_BOTTOM}`;

  // Handlers
  const goBackHandler = () => navigate(redirectUri);

  const handleNavigateToInviteUsers = () => {
    navigate(
      `/profile/organization/${organizationId}/user-management/invite?direction=${transitionDirections.BOTTOM_TO_TOP}`
    );
  };

  const handleChangeRole = (role) => {
    setFilter({ ...filter, role });
  };

  const handleChangeStatus = (status) => {
    setFilter({ ...filter, status });
  };

  const handleChangeSearchValue = (event) => {
    const newSearchValue = event.target.value;

    setFilter({ ...filter, searchValue: newSearchValue });
  };

  const handleOpenSearch = () => {
    setOpenSearch(true);
  };

  const handleCloseSearch = () => {
    setOpenSearch(false);
    setFilter({ ...filter, searchValue: "" });
  };

  // Effects
  useEffect(() => {
    dispatch(setOrganizationId(organizationId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={
          isLoadingInvitedUsers ||
          isLoadingOrganizationUsers ||
          isLoadingActualUsage ||
          isLoadingOrganizationRoles ||
          isLoadingUserRoles ||
          !organizationId
        }
        isError={
          isErrorInvitedUsers ||
          isErrorOrganizationUsers ||
          isErrorUserRoles ||
          isErrorOrganizationRoles ||
          isErrorSubscriptionActualUsage
        }
      >
        <Access
          all={[
            permissions.USER_MANAGEMENT_VIEW,
            permissions.ORG_MANAGEMENT_EDIT,
          ]}
          organizationId={organizationId}
          redirectUri={redirectUri}
        >
          <Layer2AccessOrganization organization={organization}>
            <PageTransition direction={transitionDirection}>
              <UserManagementHeader goBackHandler={goBackHandler} />
              <HomePagePadding>
                <Stack rowGap={1}>
                  {openSearch ? (
                    <TextField
                      focused={true}
                      sx={{
                        ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme.palette.secondary.contrastText,
                        },
                        ".MuiInputBase-input": {
                          paddingInline: "8px",
                        },
                      }}
                      id="search"
                      variant="outlined"
                      onChange={handleChangeSearchValue}
                      value={filter.searchValue}
                      InputProps={{
                        startAdornment: getSvgIcon(
                          "SEARCH",
                          iconSize,
                          iconSize,
                          theme.palette.secondary.dark
                        ),
                        endAdornment: (
                          <IconButton
                            sx={{ padding: 0 }}
                            onClick={handleCloseSearch}
                          >
                            {getSvgIcon(
                              "CLEAR",
                              iconSize,
                              iconSize,
                              theme.palette.secondary.dark
                            )}
                          </IconButton>
                        ),
                      }}
                      placeholder={getTranslation("SEARCH", t, i18n)}
                    />
                  ) : (
                    <Grid container sx={{ alignItems: "center" }}>
                      <Grid item xs>
                        <PrimaryText variant="h5">
                          {getTranslation("USERS", t, i18n)}
                        </PrimaryText>
                      </Grid>
                      <Grid item>
                        <SecondaryContrastButton
                          disabled={isInviteDisabled}
                          onClick={handleNavigateToInviteUsers}
                          variant="text"
                          startIcon={getSvgIcon(
                            "CREATE_NEW",
                            iconSize,
                            iconSize,
                            isInviteDisabled
                              ? theme.palette.action.disabled
                              : theme.palette.secondary.contrastText
                          )}
                        >
                          {getTranslation("INVITE_USERS", t, i18n)}
                        </SecondaryContrastButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={handleOpenSearch}
                          id="search-users-icon"
                          data-testid="search-users-icon"
                        >
                          {getSvgIcon(
                            "SEARCH",
                            iconSize,
                            iconSize,
                            theme.palette.secondary.contrastText
                          )}
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                  <OrganizationUserAvatarsList
                    users={organizationUsers?.slice() ?? []}
                    organizationId={organizationId}
                    total={usersCurrentValue}
                  />
                  {actualUsage && (
                    <PrimaryText>
                      {getTranslation("ACCOUNTS_BEING_USED", t, i18n)
                        .replace("{0}", organizationUsersCount)
                        .replace("{1}", usersSubscriptionValue)}
                    </PrimaryText>
                  )}

                  <Stack direction="row" spacing={1}>
                    <RoleChip
                      organizationRoles={organizationRoles}
                      role={filter.role}
                      onChangeRole={handleChangeRole}
                    />
                    <StatusChip
                      status={filter.status}
                      onChangeStatus={handleChangeStatus}
                    />
                  </Stack>
                  <OrganizationUserList
                    organizationUsers={organizationUsers ?? []}
                    invitedUsers={invitedUsers ?? []}
                    filter={filter}
                  />
                </Stack>
              </HomePagePadding>
            </PageTransition>
          </Layer2AccessOrganization>
        </Access>
      </ErrorHandling>
    </AppAccess>
  );
};

export default UserManagementPage;
