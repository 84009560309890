import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  alpha,
  useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { USER_STATUS, getTranslation } from "../../util/utils";
import { PrimaryText } from "../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { SecondaryText } from "../styles/assets/ListInlineView.styles";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { SecondaryContrastButton } from "../styles/general/General.styles";
import OrganizationUserOptions from "./OrganizationUserOptions";
import { getMappedUsers } from "../../util/user-utils";

const OrganizationUserList = ({
  organizationUsers = [],
  invitedUsers = [],
  filter,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);

  // States
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  // Handlers

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleSort = (property) => {
    if (orderBy === property) {
      if (order === "asc") {
        setOrder("desc");
      } else {
        setOrder("asc");
      }
    } else {
      setOrderBy(property);
      setOrderBy("asc");
    }
  };

  // Other variables
  const users = useMemo(() => {
    const result = getMappedUsers(organizationUsers, invitedUsers);
    const filteredUsers = result.filter((u) => {
      const hasRole = filter.role ? u.role.id === filter.role.id : true;
      const hasStatus = filter.status ? u.status === filter.status : true;
      const hasSearchValue = filter.searchValue
        ? u.name?.includes(filter.searchValue)
        : true;

      return hasRole && hasStatus && hasSearchValue;
    });

    return filteredUsers.sort(getComparator(order, orderBy));
  }, [organizationUsers, invitedUsers, order, orderBy, filter]);

  return (
    <Box>
      <Grid container sx={{ padding: 2 }}>
        <Grid item xs>
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <SecondaryContrastButton
              onClick={() => handleSort("name")}
              variant="text"
              endIcon={<ArrowDownwardIcon />}
            >
              {getTranslation("NAME", t, i18n)}
            </SecondaryContrastButton>
          </Stack>
        </Grid>
      </Grid>
      <Stack rowGap={2}>
        {users?.length > 0 ? (
          users?.map((u) => {
            const { id, name, role, status } = u;
            const isCurrentUser = user?.id === id;
            const resultName = `${name} ${isCurrentUser ? "(You)" : ""}`;
            const isActive = status === USER_STATUS.ACTIVE;
            const statusStyles = {
              color: !isActive ? alpha(theme.palette.primary.main, 0.4) : "",
            };

            return (
              <Card>
                <CardContent>
                  <Grid container sx={{ alignItems: "center" }}>
                    <Grid item xs>
                      <PrimaryText sx={statusStyles}>{resultName}</PrimaryText>
                      <SecondaryText sx={statusStyles} variant="body2">
                        {`${getTranslation("role", t, i18n)}: ${getTranslation(
                          role.name,
                          t,
                          i18n
                        )}`}
                      </SecondaryText>
                      <SecondaryText sx={statusStyles} variant="body2">
                        {`${getTranslation(
                          "STATUS",
                          t,
                          i18n
                        )}: ${getTranslation(status, t, i18n)}`}
                      </SecondaryText>
                    </Grid>
                    <Grid item>
                      <OrganizationUserOptions user={u} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          })
        ) : (
          <PrimaryText textAlign="center">No users found</PrimaryText>
        )}
      </Stack>
    </Box>
  );
};

export default OrganizationUserList;
