import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePWAInstall } from "react-use-pwa-install";
import { selectUser } from "../store/slices/authSlice";
import { useUserRolePermissionsQuery } from "../store/slices/api/userManagementSlice";
import {
  getPermissionsFromUserRoles,
  hasAccess,
  permissions,
  transitionDirections,
  getTranslation,
} from "../util/utils";
import { MenuList, useTheme } from "@mui/material";
import MoreHeader from "../navigation/header/assets/MoreHeader";
import ProfilePreferenceItem from "../components/ProfilePreferenceItem";
import { StyledDivider } from "../components/styles/profile/Profile.styles";
import ProfilePreferenceMenuItem from "../components/ProfilePreferenceItem";
import PageTransition from "../components/PageTransition";
import useCheckOrganizationRestricted from "../hooks/useCheckOrganizationRestricted";
import AppAccess from "../components/common/AppAccess";
import { getSvgIcon } from "../util/icons";
import { selectGlobalFontSize } from "../store/slices/appSlice";
import { AIRFOCUS_URL } from "../Constants";

const MorePage = () => {
  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const install = usePWAInstall();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const activeOrganization = user?.organizations?.find((o) => o.default);

  const hasOrganizations =
    user?.organizations && user?.organizations?.length > 0;

  const iconSize = globalFontSize * 1.2;

  // Queries
  const {
    data: userRolePermissionsData,
    isLoading: isLoadingUserRolePermissions,
  } = useUserRolePermissionsQuery(
    {
      organizationId: user?.organizations?.find((o) => o.default).id,
      userId: user?.id,
    },
    { skip: !activeOrganization }
  );

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(activeOrganization);

  // Other variables
  let transitionDirection = searchParams.get("direction");

  const showNavigation = hasOrganizations && !isRestricted;

  // Handlers
  const onProfileClickHandler = () =>
    navigate(`/profile?direction=${transitionDirections.RIGHT_TO_LEFT}`);

  const onCommunityPortal = async () => {
    navigate("/community");
  };

  const onRaiseFeatureRequestClick = () => {
    window.open(AIRFOCUS_URL, "_blank");
  };

  const onLastUsedClickHandler = () => navigate("/recents");

  const onAboutClickHandler = () =>
    navigate(`/about?direction=${transitionDirections.RIGHT_TO_LEFT}`);

  const onLibraryClickHandler = () =>
    navigate(`/library?direction=${transitionDirections.RIGHT_TO_LEFT}`);

  const handleOrganizationClick = (organizationId) =>
    navigate(
      "/profile/organization/" +
        organizationId +
        `?direction=${transitionDirections.RIGHT_TO_LEFT}&quick_access=true`,
      {
        state: { from: location.pathname + location.search },
      }
    );

  // Other variables
  const requiredPermissions = [permissions.ORG_MANAGEMENT_EDIT];
  const defaultOrganization = user?.organizations?.find((o) => o.default);

  return (
    <AppAccess>
      <PageTransition direction={transitionDirection}>
        <MoreHeader />
        <MenuList dense>
          <ProfilePreferenceItem
            handleClick={onProfileClickHandler}
            icon={getSvgIcon(
              "PROFILE",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
            label="profile"
            labelId="btn-more-profile"
            menuId="btn-menu-item-more-profile"
          />

          {showNavigation && (
            <>
              {!isLoadingUserRolePermissions &&
                hasAccess(
                  "all",
                  requiredPermissions,
                  getPermissionsFromUserRoles(userRolePermissionsData)
                ) && (
                  <>
                    <StyledDivider />
                    <ProfilePreferenceMenuItem
                      handleClick={() =>
                        handleOrganizationClick(defaultOrganization?.id)
                      }
                      icon={getSvgIcon(
                        "ORGANIZATION",
                        iconSize,
                        iconSize,
                        theme.palette.primary.main
                      )}
                      label={getTranslation("organization", t, i18n)}
                      labelId="btn-organization-name"
                      menuId="btn-menu-item-organization-name"
                      value={defaultOrganization?.name}
                    />
                  </>
                )}
              <StyledDivider />
              <ProfilePreferenceItem
                handleClick={onLibraryClickHandler}
                icon={getSvgIcon(
                  "MODEL_LIBRARY",
                  iconSize,
                  iconSize,
                  theme.palette.primary.main
                )}
                label="MODEL_LIBRARY"
                labelId="btn-more-library"
                menuId="btn-menu-item-more-library"
              />
              <StyledDivider />
              <ProfilePreferenceItem
                handleClick={onLastUsedClickHandler}
                icon={getSvgIcon(
                  "LAST_USED",
                  iconSize,
                  iconSize,
                  theme.palette.primary.main
                )}
                label="RECENTS"
                labelId="btn-more-last-used"
                menuId="btn-menu-item-more-last-used"
              />
            </>
          )}
          <StyledDivider />
          <ProfilePreferenceItem
            handleClick={onCommunityPortal}
            icon={getSvgIcon(
              "FORUM",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
            label="FORUM"
            labelId="btn-more-community-portal"
            menuId="btn-menu-item-community-portal"
          />
          <StyledDivider />
          <ProfilePreferenceItem
            handleClick={onRaiseFeatureRequestClick}
            icon={getSvgIcon(
              "RAISE_FEATURE_REQUEST",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
            label="RAISE_FEATURE_REQUEST"
            labelId="btn-more-community-portal"
            menuId="btn-menu-item-community-portal"
          />
          <StyledDivider />
          <ProfilePreferenceItem
            handleClick={onAboutClickHandler}
            icon={getSvgIcon(
              "ABOUT",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
            label="ABOUT"
            labelId="btn-more-about"
            menuId="btn-menu-item-more-about"
          />
          <StyledDivider />
          {install && (
            <ProfilePreferenceItem
              handleClick={install}
              icon={getSvgIcon(
                "INSTALL",
                iconSize,
                iconSize,
                theme.palette.primary.main
              )}
              label="INSTALL"
              labelId="btn-more-install"
              menuId="btn-menu-item-more-install"
              showrighticon={false}
            />
          )}

          {install && <StyledDivider />}
        </MenuList>
      </PageTransition>
    </AppAccess>
  );
};

export default MorePage;
