import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { selectUser, setUser } from "../../../store/slices/authSlice";
import {
  useGetOrganizationByIdQuery,
  useUpdateOrganizationMutation,
} from "../../../store/slices/api/organizationsApiSlice";
import {
  transitionDirections,
  getTranslation,
  testInput,
  permissions,
  hasAccess,
  getPermissionsFromUserRoles,
} from "../../../util/utils";
import { messageError, messageSuccess } from "../../../util/notification";
import { Box, TextField } from "@mui/material";
import UpdateOrganizationNameHeader from "../../../navigation/header/organization/UpdateOrganizationNameHeader";
import Access from "../../../components/common/Access";
import PageTransition from "../../../components/PageTransition";
import ErrorHandling from "../../../components/common/ErrorHandling";
import { HomePagePadding } from "../../../components/styles/general/General.styles";
import { ValidationText } from "../../../components/styles/inputs/NamesGroup.styles";
import AppAccess from "../../../components/common/AppAccess";
import useCheckOrganizationRestricted from "../../../hooks/useCheckOrganizationRestricted";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";

const UpdateOrganizationName = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { organizationId } = useParams();

  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const org = user?.organizations?.find((o) => o.id === organizationId);

  const redirectUri =
    "/profile/organization/" +
    organizationId +
    `?direction=${transitionDirections.TOP_TO_BOTTOM}`;

  // Queries
  const { data, isLoading, isError } =
    useGetOrganizationByIdQuery(organizationId);

  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isFetching: isFetchingUserRoles,
  } = useUserRolePermissionsQuery(
    {
      organizationId: org?.id,
      userId: user?.id,
    },
    { skip: !org?.id }
  );

  // Mutations
  const [updateOrganization, { isLoading: isLoadingUpdateOrganization }] =
    useUpdateOrganizationMutation();

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(org);

  // State
  const [organization, setOrganization] = useState({ name: "", nickname: "" });

  // Handlers
  const handleSubmit = async () => {
    const isValid = testInput.ORGANIZATION_NAME(data.name);

    if (!isValid) {
      messageError(getTranslation("organizationNameValidation", t, i18n));
      return;
    }

    try {
      const data = await updateOrganization({
        organizationData: {
          name: organization.name,
          nickname: organization.nickname,
        },
        organizationId,
      }).unwrap();

      if (data) {
        let filteredOrganizations = user?.organizations?.filter(
          (o) => o.id !== data.id
        );

        filteredOrganizations.push(data);
        dispatch(
          setUser({
            ...user,
            organizations: filteredOrganizations,
          })
        );
      }

      messageSuccess(
        getTranslation("successfulUpdateOrganizationName", t, i18n)
      );

      navigate(redirectUri);
    } catch (error) {
      messageError(getTranslation("failedUpdateOrganizationName", t, i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const cancelHandler = () => {
    navigate(redirectUri);
  };

  const handleOrganizationNameChange = (event) => {
    setOrganization({
      ...organization,
      name: event.target.value,
    });
  };

  const handleOrganizationNicknameChange = (event) => {
    setOrganization({
      ...organization,
      nickname: event.target.value,
    });
  };

  // Other variables
  const transitionDirection = searchParams.get("direction");
  const organizationNameValid = testInput.ORGANIZATION_NAME(organization.name);
  const isUpdateOrganizationRestricted = hasAccess(
    "all",

    [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
    getPermissionsFromUserRoles(userRoles)
  )
    ? false
    : isRestricted;

  useEffect(() => {
    if (data) {
      setOrganization({
        name: data.name,
        nickname: data.nickname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={
          isLoadingUpdateOrganization || isLoading || isLoadingUserRoles
        }
        isError={isError}
      >
        <Access
          oneOf={[permissions.ORG_MANAGEMENT_EDIT]}
          redirectUri={redirectUri}
        >
          <PageTransition direction={transitionDirection}>
            <UpdateOrganizationNameHeader
              handleAction={handleSubmit}
              handleCancelAction={cancelHandler}
              disabledDone={
                !organizationNameValid || isUpdateOrganizationRestricted
              }
            />
            <HomePagePadding>
              {/* First name input */}
              <TextField
                id="organization-name-input"
                type="text"
                label={getTranslation("organizationName", t, i18n)}
                placeholder={getTranslation("organizationName", t, i18n)}
                name="organizationName"
                value={organization.name}
                onChange={handleOrganizationNameChange}
                error={!organizationNameValid}
                required
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  "data-testid": "organization-name-input-onboarding",
                }}
              />
              <Box>
                <ValidationText>
                  {!organizationNameValid &&
                    getTranslation("organizationNameValidation", t, i18n)}
                </ValidationText>
              </Box>
              <TextField
                id="organization-nickname-input"
                inputProps={{
                  "data-testid": "organization-nickname-input",
                }}
                type="text"
                label={getTranslation("ORGANIZATION_NICKNAME", t, i18n)}
                placeholder={getTranslation("ORGANIZATION_NICKNAME", t, i18n)}
                name="organizationNickname"
                value={organization.nickname}
                onChange={handleOrganizationNicknameChange}
                InputLabelProps={{ shrink: true }}
              />
            </HomePagePadding>
          </PageTransition>
        </Access>
      </ErrorHandling>
    </AppAccess>
  );
};

export default UpdateOrganizationName;
