import React, { forwardRef, useState } from "react";
import {
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getSvgIcon } from "../../util/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { USER_STATUS, getTranslation } from "../../util/utils";
import { StickySearchStyledDialogListItemButton } from "../styles/assets/StickySearch.styles";
import {
  CancelButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../styles/general/General.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import { StyledDivider } from "../styles/profile/Profile.styles";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StatusChip = ({ onChangeStatus, status }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Other variables
  const iconSize = globalFontSize;
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleOpenOptionsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptionsMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatus = (value) => {
    onChangeStatus(value);
  };

  return (
    <>
      <Chip
        onClick={(e) => {
          if (mobileMatches) {
            handleOpen();
          } else {
            handleOpenOptionsMenu(e);
          }
        }}
        label={`${getTranslation("STATUS", t, i18n)}: ${getTranslation(
          status ?? "ALL",
          t,
          i18n
        )}`}
        sx={{
          backgroundColor: theme.palette.secondary.active,
          color: theme.palette.primary.main,
        }}
      />
      <Menu
        slotProps={{
          paper: {
            elevation: 1,
            sx: {
              borderRadius: "8px",
            },
          },
        }}
        aria-labelledby="more-icon"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id="menu-options"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseOptionsMenu}
      >
        <MenuItem
          sx={{
            backgroundColor:
              status === null ? theme.palette.secondary.contrastText : "",
            "&:hover": {
              backgroundColor:
                status === null ? theme.palette.secondary.contrastText : "",
            },
          }}
          onClick={() => {
            handleCloseOptionsMenu();
            handleChangeStatus(null);
          }}
        >
          <ListItemIcon>
            {getSvgIcon(
              "CHECK",
              iconSize,
              iconSize,
              theme.palette.primary.main,
              {
                visibility: status === null ? "visible" : "hidden",
                padding: "8px",
              }
            )}
          </ListItemIcon>
          <ListItemText>{getTranslation("ALL", t, i18n)}</ListItemText>
        </MenuItem>
        <StyledDivider />
        {Object.values(USER_STATUS).map((value) => {
          const isActive = status === value;

          return (
            <MenuItem
              sx={{
                backgroundColor: isActive
                  ? theme.palette.secondary.contrastText
                  : "",
                "&:hover": {
                  backgroundColor: isActive
                    ? theme.palette.secondary.contrastText
                    : "",
                },
              }}
              onClick={() => {
                handleCloseOptionsMenu();
                handleChangeStatus(value);
              }}
            >
              <ListItemIcon>
                {getSvgIcon(
                  "CHECK",
                  iconSize,
                  iconSize,
                  theme.palette.primary.main,
                  {
                    visibility: isActive ? "visible" : "hidden",
                    padding: "8px",
                  }
                )}
              </ListItemIcon>
              <ListItemText>{getTranslation(value, t, i18n)}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
      <StyledDialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <StyledDialogContent>
          <StyledDialogTitle>
            <List>
              <ListItem
                onClick={() => handleChangeStatus(null)}
                disablePadding
                secondaryAction={getSvgIcon(
                  "CHECK",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.dark,
                  {
                    visibility: status === null ? "visible" : "hidden",
                  }
                )}
              >
                <StickySearchStyledDialogListItemButton>
                  {getSvgIcon(
                    "CHECK",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.dark,
                    {
                      visibility: "hidden",
                      padding: "8px",
                    }
                  )}
                  <ListItemText align="center">
                    {getTranslation("ALL", t, i18n)}
                  </ListItemText>
                </StickySearchStyledDialogListItemButton>
              </ListItem>
              {Object.values(USER_STATUS).map((value) => (
                <ListItem
                  onClick={() => handleChangeStatus(value)}
                  disablePadding
                  secondaryAction={getSvgIcon(
                    "CHECK",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.dark,
                    {
                      visibility: status === value ? "visible" : "hidden",
                    }
                  )}
                >
                  <StickySearchStyledDialogListItemButton>
                    {getSvgIcon(
                      "CHECK",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.dark,
                      {
                        visibility: "hidden",
                        padding: "8px",
                      }
                    )}
                    <ListItemText align="center">
                      {getTranslation(value, t, i18n)}
                    </ListItemText>
                  </StickySearchStyledDialogListItemButton>
                </ListItem>
              ))}
            </List>
          </StyledDialogTitle>
        </StyledDialogContent>
        <CancelButton id={`cancel-button`} onClick={handleClose}>
          {getTranslation("CANCEL", t, i18n)}
        </CancelButton>
      </StyledDialog>
    </>
  );
};

export default StatusChip;
