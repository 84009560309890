import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { Grid, Stack, useTheme, IconButton, TextField } from "@mui/material";
import LoadingSpinner from "../common/LoadingSpinner";
import { getTranslation } from "../../util/utils";
import { getSvgIcon } from "../../util/icons";
import { useGetCurrentSubscriptionActualUsageQuery } from "../../store/slices/api/subscriptionsApiSlice";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import { INVITATION_STATUS } from "../InvitationForm";
import {
  useGetOrganizationRolesQuery,
  useGetOrganizationUsersQuery,
} from "../../store/slices/api/organizationsApiSlice";
import { useGetInvitedUsersQuery } from "../../store/slices/api/userManagementSlice";
import { PrimaryText } from "../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import InviteMembers from "../profile/InviteMembers";
import OrganizationUserAvatarsList from "./OrganizationUserAvatarsList";
import StatusChip from "./StatusChip";
import { RoleChip } from "./RoleChip";
import OrganizationUserListTable from "./OrganizationUserListTable";

const DEFAULT_FILTER = {
  searchValue: "",
  role: null,
  status: null,
  isActive30Days: false,
};

const OrganizationUsersCard = ({ organization }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(organization);

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [openSearch, setOpenSearch] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_FILTER);

  // Queries
  const { data: invitedUsers, isLoading: isLoadingInvitedUsers } =
    useGetInvitedUsersQuery(organization?.id, {
      skip: !organization?.id,
    });

  const { data: organizationUsers, isLoading: isLoadingOrganizationUsers } =
    useGetOrganizationUsersQuery(organization?.id, {
      skip: !organization?.id,
    });

  const { data: actualUsage, isLoading: isLoadingActualUsage } =
    useGetCurrentSubscriptionActualUsageQuery(
      {
        organizationId: organization?.id,
      },
      { skip: !organization?.id || isRestricted }
    );

  const { data: organizationRoles, isLoading: isLoadingOrganizationRoles } =
    useGetOrganizationRolesQuery(organization?.id, {
      skip: !organization.id,
    });

  // Other variables
  const iconSize = globalFontSize;

  const facts = actualUsage?.facts;
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue ?? 0);

  const sentInvitationsCount =
    invitedUsers?.filter((iu) => iu.status === INVITATION_STATUS.SENT)
      ?.length ?? 0;
  const organizationUsersCount = organizationUsers?.length ?? 0;
  const usersCurrentValue = sentInvitationsCount + organizationUsersCount;

  const avatarUsers = organizationUsers?.slice() ?? [];

  // Handlers
  const handleOpenSearch = () => {
    setOpenSearch(true);
  };

  const handleCloseSearch = () => {
    setOpenSearch(false);
    setFilter({ ...filter, searchValue: "" });
  };

  const handleChangeRole = (role) => {
    setFilter({ ...filter, role });
  };

  const handleChangeStatus = (status) => {
    setFilter({ ...filter, status });
  };

  const handleChangeSearchValue = (event) => {
    const newSearchValue = event.target.value;

    setFilter({ ...filter, searchValue: newSearchValue });
  };

  if (
    isLoadingOrganizationUsers ||
    isLoadingActualUsage ||
    isLoadingInvitedUsers ||
    isLoadingOrganizationRoles
  ) {
    return <LoadingSpinner />;
  }

  return (
    <Stack rowGap={1} sx={{ width: "100%" }}>
      <Grid container sx={{ alignItems: "center" }}>
        <Grid item xs>
          <PrimaryText variant="h5">
            {getTranslation("USERS", t, i18n)}
          </PrimaryText>
        </Grid>
        <Grid item>
          <InviteMembers
            invitedUsers={invitedUsers}
            organizationUsers={organizationUsers}
            organizationId={organization?.id}
          />
        </Grid>
      </Grid>
      <OrganizationUserAvatarsList
        users={avatarUsers}
        organizationId={organization?.id}
        total={usersCurrentValue}
      />
      {actualUsage && (
        <PrimaryText>
          {getTranslation("ACCOUNTS_BEING_USED", t, i18n)
            .replace("{0}", organizationUsersCount)
            .replace("{1}", usersSubscriptionValue)}
        </PrimaryText>
      )}
      {openSearch ? (
        <TextField
          focused={true}
          sx={{
            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.secondary.contrastText,
            },
            ".MuiInputBase-input": {
              paddingInline: "8px",
            },
          }}
          id="search"
          variant="outlined"
          onChange={handleChangeSearchValue}
          value={filter.searchValue}
          InputProps={{
            startAdornment: getSvgIcon(
              "SEARCH",
              iconSize,
              iconSize,
              theme.palette.secondary.dark
            ),
            endAdornment: (
              <IconButton sx={{ padding: 0 }} onClick={handleCloseSearch}>
                {getSvgIcon(
                  "CLEAR",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.dark
                )}
              </IconButton>
            ),
          }}
          placeholder={getTranslation("SEARCH", t, i18n)}
        />
      ) : (
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs>
            <Stack direction="row" spacing={1}>
              <RoleChip
                organizationRoles={organizationRoles}
                role={filter.role}
                onChangeRole={handleChangeRole}
              />
              <StatusChip
                status={filter.status}
                onChangeStatus={handleChangeStatus}
              />
            </Stack>
          </Grid>
          <Grid item>
            <IconButton
              onClick={handleOpenSearch}
              id="search-users-icon"
              data-testid="search-users-icon"
            >
              {getSvgIcon(
                "SEARCH",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </IconButton>
          </Grid>
        </Grid>
      )}
      <OrganizationUserListTable
        organizationUsers={organizationUsers ?? []}
        invitedUsers={invitedUsers ?? []}
        filter={filter}
        organization={organization}
      />
    </Stack>
  );
};

export default OrganizationUsersCard;
