import {
  Slide,
  Dialog,
  IconButton,
  Box,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  THEME,
} from "../../util/utils";
import ReportExecutionResultTable from "./ReportExecutionResultTable";
import {
  SecondaryTextOverflowWrap,
  StackContainer,
  TitleSection,
} from "../styles/assets/reports/ReportDetails.styles";
import { SecondaryText } from "../styles/assets/ListInlineView.styles";
import { ReportExecutionWrapper } from "../styles/reports/Reports.styles";
import { useSelector } from "react-redux";
import { selectGlobalFontSize, selectTheme } from "../../store/slices/appSlice";
import SyntaxHighlighter from "react-syntax-highlighter";
import { xcode, a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { getSvgIcon } from "../../util/icons";
import WidgetList from "./WidgetList";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReportFullscreenDialog = ({
  open,
  handleClose,
  reportDefinitionData,
  reportDefinitionFieldsData,
  reportDefinitionFilterData,
  reportDefinitionAggregationsData,
  executionFurtherFields,
  executionGroupFields,
  rows,
  whereClause,
  userRoles,
  showClose,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const currentTheme = useSelector(selectTheme);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize;

  return (
    <>
      <Dialog
        sx={{ "& .MuiPaper-root": { padding: "10px" } }}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {showClose && (
            <SecondaryText variant="h6">
              {reportDefinitionData?.name}
            </SecondaryText>
          )}

          {showClose && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              {getSvgIcon(
                "CLEAR",
                iconSize,
                iconSize,
                theme.palette.secondary.dark
              )}
            </IconButton>
          )}
        </Box>

        <StackContainer>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TitleSection variant="subtitle1">
                {getTranslation("REPORT_NAME", t, i18n)}
              </TitleSection>
              <SecondaryTextOverflowWrap>
                {reportDefinitionData?.name}
              </SecondaryTextOverflowWrap>
            </Grid>

            <Grid item xs={6}>
              <TitleSection variant="subtitle1">
                {getTranslation("REPORT_DESCRIPTION", t, i18n)}
              </TitleSection>

              <Tooltip title={reportDefinitionData?.description}>
                <SecondaryTextOverflowWrap>
                  {reportDefinitionData?.description}
                </SecondaryTextOverflowWrap>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <TitleSection variant="subtitle1">
                {getTranslation("REPORT_QUERY", t, i18n)}
              </TitleSection>
              {Boolean(whereClause) ? (
                <SyntaxHighlighter
                  language="sql"
                  style={currentTheme === THEME.LIGHT ? xcode : a11yDark}
                  customStyle={{ margin: 0, padding: 0 }}
                >
                  {whereClause}
                </SyntaxHighlighter>
              ) : (
                <SecondaryText>
                  {getTranslation("NO_FILTERS_APPLIED", t, i18n)}
                </SecondaryText>
              )}
            </Grid>
          </Grid>

          <ReportExecutionWrapper>
            <WidgetList
              reportId={reportDefinitionData?.id}
              reportTypeId={reportDefinitionData?.reportTypeId}
              rows={rows}
              aggregations={reportDefinitionAggregationsData}
              fields={reportDefinitionFieldsData}
            />

            {reportDefinitionData?.organizationId ? (
              reportDefinitionFieldsData?.length > 0 &&
              reportDefinitionFilterData?.length ? (
                hasAccess(
                  "all",
                  [permissions["REPORTING_USER_DEFINED_EXECUTE"]],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <ReportExecutionResultTable
                    executionFurtherResultFields={executionFurtherFields}
                    executionGroupFields={executionGroupFields}
                    rows={rows}
                  />
                )
              ) : (
                <Typography>{getTranslation("NO_FIELDS", t, i18n)}</Typography>
              )
            ) : (
              hasAccess(
                "all",
                [permissions["REPORTING_SYSTEM_REPORT_EXECUTE"]],
                getPermissionsFromUserRoles(userRoles)
              ) && (
                <ReportExecutionResultTable
                  executionFurtherResultFields={executionFurtherFields}
                  executionGroupFields={executionGroupFields}
                  rows={rows}
                />
              )
            )}
          </ReportExecutionWrapper>
        </StackContainer>
      </Dialog>
    </>
  );
};

export default ReportFullscreenDialog;
