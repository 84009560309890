import { Box, Checkbox, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";
import { StyledFormControlLabel } from "../../../styles/general/General.styles";
import SingleValueWidgetContent from "../categorized-widgets/SingleValueWidgetContent";
import { useSelector } from "react-redux";
import {
  addWidget,
  selectResultFields,
  selectWidgetById,
  setWidgetAggregations,
} from "../../../../store/slices/reportsSlice";
import {
  constructAggregations,
  getMeasurementUnitForField,
  WIDGET_TYPE,
} from "../../../../util/reports-utils";
import { useDispatch } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import WidgetAggregationsList from "../WidgetAggregationsList";
import {
  WidgetContainer,
  WidgetTitle,
  WidgetWrapper,
} from "../../../styles/reports/ChartWidgets.styles";
import { measurementDisplay } from "../../../../util/utils";
import { selectUser } from "../../../../store/slices/authSlice";

const SingleValueWidgetForm = ({ rows }) => {
  // General hooks
  const dispatch = useDispatch();

  // Selectors
  const user = useSelector(selectUser);
  const resultFields = useSelector(selectResultFields);
  const widget = useSelector((state) =>
    selectWidgetById(state, WIDGET_TYPE.SINGLE_VALUE)
  );

  // Other variables
  const finalAggregations = constructAggregations(resultFields);

  // States
  const [title, setTitle] = useState(widget?.title);
  const [displayHome, setDisplayHome] = useState(false);

  // Other variables
  const dataSources = widget?.dataSources ?? [];

  const aggregationIds =
    dataSources?.map((ds) => ds.generatedAggregationId) ?? [];

  const aggregationId = aggregationIds?.length > 0 ? aggregationIds[0] : "";

  const aggregation = finalAggregations?.find((a) => a.id === aggregationId);

  const key =
    aggregation?.field?.name +
    `.aggregation-${aggregation?.aggregationType?.toLowerCase()}`;

  const row = rows?.length > 0 ? rows[0] : {};
  const value = row[key];

  const { unit, value: convertedValue } = measurementDisplay({
    value,
    unit: getMeasurementUnitForField(
      aggregation?.field?.name,
      aggregation?.aggregationType
    ),
    region: user?.region,
  });

  const unitLabel = unit ? ` (in ${unit})` : "";
  const finalValue = convertedValue + unitLabel;

  // Handlers
  const handleChangeTitle = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    dispatch(addWidget({ ...widget, title: newTitle }));
  };

  const handleChangeDisplayHome = (e) => {
    const newDisplayHome = e.target.checked;
    setDisplayHome(newDisplayHome);
  };

  const handleRearangeArr = (arr, sourceIndex, destIndex) => {
    const arrCopy = [...arr];
    const [removed] = arrCopy.splice(sourceIndex, 1);
    arrCopy.splice(destIndex, 0, removed);

    return arrCopy;
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const items = handleRearangeArr(
      widget?.dataSources,
      source.index,
      destination.index
    );

    dispatch(
      setWidgetAggregations({ widgetTypeId: WIDGET_TYPE.SINGLE_VALUE, items })
    );
  };

  return (
    <Stack spacing={3}>
      <Box>
        <SecondaryText gutterBottom>Configure Count</SecondaryText>

        <Box sx={{ flexGrow: 1 }}>
          <TextField
            label="Title"
            id="title"
            value={title}
            onChange={handleChangeTitle}
          />
        </Box>
      </Box>

      <DragDropContext onDragEnd={handleDragEnd}>
        <WidgetAggregationsList widgetTypeId={WIDGET_TYPE.SINGLE_VALUE} />
      </DragDropContext>
      <Box>
        <SecondaryText gutterBottom>Preview</SecondaryText>

        <WidgetWrapper>
          <WidgetContainer>
            <WidgetTitle variant="body2" textAlign="center">
              {title}
            </WidgetTitle>

            <SingleValueWidgetContent value={finalValue} />
          </WidgetContainer>
        </WidgetWrapper>
      </Box>
    </Stack>
  );
};

export default SingleValueWidgetForm;
