import { useDispatch } from "react-redux";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { deleteResourceImage } from "../../../store/slices/resourceImageSlice";
import CanvasImage from "../../common/CanvasImage";
import { forwardRef, useState } from "react";
import { Slide, useMediaQuery, useTheme } from "@mui/material";
import {
  CancelButton,
  ChangeRoleButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../../styles/general/General.styles";
import { StyledDivider } from "../../styles/profile/Profile.styles";
import {
  DeleteAttachmentButton,
  StyledDividerWithSpace,
} from "../../styles/assets/asset-attachments/AssetResourceAttachment.styles";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectPageView,
} from "../../../store/slices/appSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { useNavigate, useParams } from "react-router-dom";
import { selectUser } from "../../../store/slices/authSlice";
import {
  useDeleteResourceImageMutation,
  useGetResourceImageQuery,
} from "../../../store/slices/api/resourceImagesApiSlice";
import ErrorHandling from "../../common/ErrorHandling";
import { selectParentId } from "../../../store/slices/columnViewSlice";
import { messageSuccess } from "../../../util/notification";
import EditLocalImageDialog from "../asset-form/EditLocalImageDialog";
import { selectCurrentResourceId } from "../../../store/slices/longPressSlice";
import { getSvgIcon } from "../../../util/icons";
import { FORM_MODE } from "../../../util/asset-utils";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LocalImage = ({ resourceImage, index, mode }) => {
  // General hooks
  const dispatch = useDispatch();
  const { resourceid } = useParams();
  const { t, i18n } = useTranslation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const navigate = useNavigate();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const parentId = useSelector(selectParentId);
  const currentResourceId = useSelector(selectCurrentResourceId);
  const currentPageView = useSelector(selectPageView);

  // State
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEditImage, setOpenEditImage] = useState(false);

  // Other variables
  const calculatedResourceId = currentResourceId || resourceid || parentId;
  const iconSize = globalFontSize;

  // Queries
  const {
    data: resourceImageUri,
    isLoading: isLoadingResourceImage,
    isError,
    error,
  } = useGetResourceImageQuery(
    {
      imageUri: resourceImage ? resourceImage?.selectedFile : "",
      organizationId: user?.organizations?.find((o) => o.default).id,
      thumbnail: true,
    },
    {
      skip: !resourceImage || !resourceImage?.isFromDb,
    }
  );

  // Mutations
  const [deleteResourceImageMutation] = useDeleteResourceImageMutation();

  // Other variables
  const isLoading =
    currentPageView !== "column" ? isLoadingResourceImage : false;

  // Handlers
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEditImage = () => {
    if (mobileMatches) {
      if (mode === FORM_MODE.DUPLICATE) {
        navigate(
          `/resources/${resourceid}/duplicate/edit-local-image?direction=${transitionDirections.RIGHT_TO_LEFT}&index=${index}`
        );
      } else {
        navigate(
          `/resources${
            resourceid ? `/${resourceid}` : ""
          }/edit-local-image?direction=${
            transitionDirections.RIGHT_TO_LEFT
          }&index=${index}`
        );
      }
    } else {
      setOpenEditImage(true);
    }
  };

  const handleDownloadFileFromDb = () => {
    const { name } = resourceImage;
    const link = document.createElement("a");
    link.href = resourceImageUri;

    link.download = name; // Set the original file name as the downloaded file name

    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadLocalFile = () => {
    const { file, name } = resourceImage;
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;

    link.download = name; // Set the original file name as the downloaded file name

    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadResourceImage = () => {
    if (calculatedResourceId) {
      if (mode === "edit-asset") {
        handleDownloadFileFromDb();
      } else {
        handleDownloadLocalFile();
      }
    } else {
      if (resourceImage?.isFromDb) {
        handleDownloadFileFromDb();
      } else {
        handleDownloadLocalFile();
      }
    }
  };

  const handleDeleteConfirm = () => {
    setOpenConfirm(true);
  };

  const handleDeleteResourceImageFromDb = async () => {
    await deleteResourceImageMutation({
      resourceid: calculatedResourceId,
      organizationId: user?.organizations?.find((o) => o.default).id,
      resourceImageId: resourceImage?.id,
    }).unwrap();

    messageSuccess(
      getTranslation("RESOURCE_IMAGE_DELETED_SUCCESSFULLY", t, i18n)
    );
  };

  const handleDeleteResourceImage = async (index) => {
    try {
      if (calculatedResourceId) {
        if (mode === "edit-asset") {
          await handleDeleteResourceImageFromDb();
        } else {
          dispatch(deleteResourceImage(index));
        }
      } else {
        dispatch(deleteResourceImage(index));
      }
    } catch (error) {
      console.error("Failed to delete resource image", error);
    }
  };

  return (
    <ErrorHandling
      isLoading={isLoading}
      isError={error?.status !== 404 && isError}
    >
      {resourceImage?.selectedFile && (
        <div
          id="local-resource-image"
          style={{ marginTop: "10px", pointer: "cursor" }}
          onClick={handleClickOpen}
        >
          {resourceImage?.isFromDb ? (
            <CanvasImage
              id={resourceImage.name}
              height={100}
              width={100}
              image={resourceImageUri}
              offsetX={0}
            />
          ) : (
            <CanvasImage
              id={resourceImage.name}
              height={100}
              width={100}
              image={resourceImage?.selectedFile}
              offsetX={0}
            />
          )}
        </div>
      )}

      <StyledDialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <StyledDialogContent>
          <StyledDialogTitle>{resourceImage.name}</StyledDialogTitle>
          <StyledDivider />

          <ChangeRoleButton
            id={`edit-resource-image`}
            onClick={() => {
              handleClickOpenEditImage();
              handleClose();
            }}
          >
            {getSvgIcon(
              "EDIT",
              iconSize,
              iconSize,
              theme.palette.primary.main,
              {
                marginRight: "10px",
              }
            )}
            {getTranslation("EDIT", t, i18n)}
          </ChangeRoleButton>

          <StyledDivider />

          <ChangeRoleButton
            id={`download-resource-image`}
            onClick={() => {
              handleDownloadResourceImage();
              handleClose();
            }}
          >
            {getSvgIcon(
              "DOWNLOAD",
              iconSize,
              iconSize,
              theme.palette.primary.main,
              { marginRight: "10px" }
            )}
            {getTranslation("DOWNLOAD", t, i18n)}
          </ChangeRoleButton>

          <>
            <StyledDividerWithSpace />

            <DeleteAttachmentButton
              id={`delete-resource-image`}
              data-testid={`delete-resource-image-${index}`}
              color="error"
              onClick={() => {
                handleClose();
                handleDeleteConfirm();
              }}
            >
              {getSvgIcon(
                "DELETE",
                iconSize,
                iconSize,
                theme.palette.error.main,
                { marginRight: "10px" }
              )}
              {getTranslation("DELETE", t, i18n)}
            </DeleteAttachmentButton>
          </>
        </StyledDialogContent>

        <CancelButton id={`cancel-button`} onClick={handleClose}>
          {getTranslation("CANCEL", t, i18n)}
        </CancelButton>
      </StyledDialog>

      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={{
          content: getTranslation("ASSET_IMAGE_DELETE_MESSAGE", t, i18n),
          confirmTitle: getTranslation("DELETE_ASSET_IMAGE", t, i18n),
          closeTitle: getTranslation("CANCEL", t, i18n),
          showConfirm: true,
        }}
        label="delete-image"
        handleConfirm={() =>
          handleDeleteResourceImage(index, resourceImage?.id)
        }
      />

      {openEditImage && (
        <EditLocalImageDialog
          resourceId={mode === "edit-asset" ? calculatedResourceId : null}
          open={openEditImage}
          setOpen={setOpenEditImage}
          index={index}
        />
      )}
    </ErrorHandling>
  );
};

export default LocalImage;
