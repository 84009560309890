import React from "react";
import InvitationForm from "../../../components/InvitationForm";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import Access from "../../../components/common/Access";
import { permissions } from "../../../util/utils";
import Layer2Access from "../../../components/common/Layer2Access";

const OrganizationInviteMembersPage = () => {
  // General hooks
  const { organizationId } = useParams();

  // Selectors
  const user = useSelector(selectUser);

  //Other variables
  const organization = user?.organizations?.find(
    (o) => o.id === organizationId
  );

  return (
    <Access all={[permissions.USER_MANAGEMENT_ADD]}>
      <Layer2Access organization={organization}>
        <InvitationForm />
      </Layer2Access>
    </Access>
  );
};

export default OrganizationInviteMembersPage;
