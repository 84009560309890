import { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { getSvgIcon } from "../../../../../util/icons";
import { CircularProgress, Paper, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../../../store/slices/appSlice";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../util/utils";
import { TimelineDot } from "@mui/lab";
import {
  IMPORT_OPTIONS,
  selectImportFile,
  selectImportOption,
  setImportFile,
} from "../../../../../store/slices/importExportSlice";
import { messageError } from "../../../../../util/notification";
import { useDispatch } from "react-redux";

// Styled Components
const DragDropContainer = styled("section")(({ theme }) => ({
  background: theme.palette.primary.contrastText,
  border: "1px solid var(--border-color)",
  borderRadius: "8px",
  marginTop: "20px",
}));

const DocumentUploader = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.secondary.contrastText}`,
  backgroundColor: theme.palette.primary.upload,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  borderRadius: "8px",
  width: "100%",
}));

const UploadInfo = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "1rem",
});

// Component
const DragNDropFileUpload = ({ width, height }) => {
  // General hooks
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const importOption = useSelector(selectImportOption);
  const importFile = useSelector(selectImportFile);

  // Other variables
  const iconSize = globalFontSize * 1.5;

  // State
  const [isLoading, setIsLoading] = useState(false);

  // Refs
  const fileInputRef = useRef(null);

  // Handlers
  const handleDrop = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const droppedFile = event.dataTransfer.files[0];

    if (droppedFile) {
      if (importOption === IMPORT_OPTIONS.XLSX) {
        if (!droppedFile?.name?.endsWith(".xlsx")) {
          messageError(getTranslation("FILE_SHOULD_BE_XLSX", t, i18n));
          return;
        }
      } else {
        if (!droppedFile?.name?.endsWith(".zip")) {
          messageError(getTranslation("FILE_SHOULD_BE_ZIP", t, i18n));
          return;
        }
      }

      dispatch(setImportFile(droppedFile));
    }

    setIsLoading(false);
  };

  const handleFileChange = (event) => {
    setIsLoading(true);
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      dispatch(setImportFile(selectedFile));
    }

    setIsLoading(false);
  };

  const handleRemoveFile = () => {
    dispatch(setImportFile(null));
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      {!importFile && (
        <DragDropContainer style={{ width, height }}>
          <DocumentUploader
            id="drag-and-drop-placeholder"
            onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}
          >
            <UploadInfo sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box>
                  {getSvgIcon(
                    "IMPORT",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </Box>

                <Box sx={{ marginTop: "10px" }}>
                  <Typography
                    onClick={handleClick}
                    sx={{
                      color: theme.palette.secondary.contrastText,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    component="span"
                  >
                    {getTranslation("CLICK_TO_UPLOAD", t, i18n)}
                  </Typography>{" "}
                  {getTranslation("DRAG_AND_DROP", t, i18n)}
                </Box>

                {/* Hidden File Input */}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept={
                    importOption === IMPORT_OPTIONS.XLSX ? ".xlsx" : ".zip"
                  }
                />

                <Box sx={{ marginTop: "10px" }}>
                  <Typography sx={{ color: theme.palette.text.secondary }}>
                    {importOption === IMPORT_OPTIONS.XLSX ? ".xlsx" : ".zip"}
                  </Typography>
                </Box>
              </Box>
            </UploadInfo>
          </DocumentUploader>
        </DragDropContainer>
      )}

      {importFile && (
        <>
          <Typography
            sx={{
              marginTop: "15px",
            }}
          >
            {getTranslation("YOUR_FILE", t, i18n)}
          </Typography>

          <Paper
            sx={{
              width: "100%",
              padding: "20px",
              display: "flex",
              alignItems: "center",
              columnGap: "15px",
              marginTop: "15px",
            }}
          >
            <Box>
              {getSvgIcon(
                "IMPORT",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText
              )}
            </Box>

            <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
              <Box>
                <Typography>{importFile.name}</Typography>
              </Box>

              <Box sx={{ display: "flex", columnGap: "10px" }}>
                <Box>
                  <Typography sx={{ color: theme.palette.text.secondary }}>
                    {(importFile.size / 1024).toFixed(0)}kB
                  </Typography>
                </Box>

                <Box>
                  <Typography sx={{ color: theme.palette.text.secondary }}>
                    {isLoading
                      ? getTranslation("LOADING", t, i18n)
                      : getTranslation("UPLOAD_COMPLETED", t, i18n)}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box>
              <IconButton onClick={handleRemoveFile}>
                {getSvgIcon("DELETE", iconSize, iconSize, "#7D8182")}
              </IconButton>
            </Box>

            {isLoading ? (
              <Box>
                <CircularProgress
                  sx={{ color: theme.palette.secondary.contrastText }}
                />
              </Box>
            ) : (
              <TimelineDot
                sx={{
                  height: "15px",
                  width: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                color="success"
              >
                {getSvgIcon(
                  "CHECK",
                  iconSize,
                  iconSize,
                  theme.palette.primary.contrastText
                )}
              </TimelineDot>
            )}
          </Paper>
        </>
      )}
    </>
  );
};

export default DragNDropFileUpload;
