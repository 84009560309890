import {
  Backdrop,
  Box,
  ListItemIcon,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddRecentMutation,
  useGetAllFunctionsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import {
  selectColumnViewTrigger,
  selectGlobalFontSize,
  selectPageView,
  selectTheme,
  setColumnViewTrigger,
  setSearchTerm,
} from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  setOptimisticItem,
  setParentId,
} from "../../../store/slices/columnViewSlice";
import ErrorHandling from "../../common/ErrorHandling";
import {
  ChildrenCountText,
  ItemWrapper,
  ResourceItemName,
  SelectedResourceItem,
} from "../../styles/assets/asset-list/ResourceItem.styles";
import LongPressAssetsItemMenu from "./LongPressAssetsItemMenu";
import { LongPressEventType, useLongPress } from "use-long-press";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { getSvgIcon } from "../../../util/icons";
import FavoriteStarComponent from "./FavoriteStarComponent";

const ResourceItem = ({ isSelected, resource, isActive }) => {
  // General hooks
  const dispatch = useDispatch();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const columnViewTrigger = useSelector(selectColumnViewTrigger);
  const currentTheme = useSelector(selectTheme);
  const currentPageView = useSelector(selectPageView);

  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [longPressed, setLongPressed] = useState(false);
  const [rightClicked, setRightClicked] = useState(false);

  // Refs
  const elementRef = useRef(null);

  // Callbacks
  const callback = useCallback(() => {
    handleOpen();
  }, []);

  // Longpress
  const bind = useLongPress(tabletMatches ? callback : null, {
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 1000,
    captureEvent: true,
    cancelOnMovement: false,
    cancelOutsideElement: false,
    detect: LongPressEventType.Pointer,
  });

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const iconSize = globalFontSize * 1.5;

  // Queries
  const { data: allFunctionsData, isError } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Mutations
  const [addRecent] = useAddRecentMutation();

  // Other variables
  const {
    name,
    id,
    functionId,
    displayId,
    childrenCount,
    isFavorite,
    parentId,
  } = resource;
  const resourceFunction = allFunctionsData?.find((f) => f.id === functionId);
  const resourceName = displayId || name;

  const handlers = bind("longpress context");
  const open = longPressed || rightClicked;

  // Handlers
  const handleClickItem = async (parentId) => {
    try {
      if (currentPageView === "column") {
        dispatch(setOptimisticItem(resource));
      }

      dispatch(setParentId(parentId));
      dispatch(setSearchTerm(""));
      dispatch(setColumnViewTrigger(columnViewTrigger + 1));

      await addRecent({
        resourceid: parentId,
        organizationId,
      }).unwrap();
    } catch (error) {
      console.error("Failed to add to recents", error);
    }
  };

  const handleOpen = () => {
    setAnchorEl(elementRef?.current);
    setLongPressed(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLongPressed(false);
    setRightClicked(false);
  };

  const handleRightClick = (e) => {
    e.preventDefault();

    if (desktopMatches) {
      setAnchorEl(elementRef?.current);
      setRightClicked(true);
    }
  };

  return (
    <ErrorHandling isLoading={false} isError={isError}>
      {open && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={open}
          onClick={handleClose}
        />
      )}

      <LongPressAssetsItemMenu
        resourceFunction={resourceFunction}
        anchorEl={anchorEl}
        handleClose={handleClose}
        resourceId={id}
        parentId={parentId}
      />

      <SelectedResourceItem
        id="resource-item"
        isselected={isSelected}
        isactive={isActive}
        longPressed={open}
      >
        <ItemWrapper
          ref={elementRef}
          data-testid={`item-${resourceName}`}
          onClick={() => handleClickItem(id)}
          onContextMenu={handleRightClick}
          {...handlers}
        >
          <Box sx={{ position: "relative" }}>
            <ListItemIcon>
              {getSvgIcon(
                resourceFunction?.name?.toUpperCase(),
                iconSize,
                iconSize,
                isActive
                  ? theme.palette.primary.main
                  : theme.palette.secondary.dark
              )}
            </ListItemIcon>

            {isFavorite && (
              <FavoriteStarComponent
                bottom="-8px"
                right="4px"
                multiplier={1.2}
              />
            )}
          </Box>

          {/* If display id is empty, display name instead */}
          <ResourceItemName variant="body2" isactive={isActive}>
            {resourceName}
          </ResourceItemName>

          {childrenCount > 0 && (
            <ChildrenCountText isactive={isActive} variant="body2">
              {childrenCount}
            </ChildrenCountText>
          )}

          {getSvgIcon(
            "ARROW_RIGHT",
            iconSize,
            iconSize,
            isActive
              ? theme.palette.primary.main
              : theme.palette.secondary.dark,
            { flexShrink: 0 }
          )}
        </ItemWrapper>
      </SelectedResourceItem>
    </ErrorHandling>
  );
};

export default ResourceItem;
