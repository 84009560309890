import { createSlice } from "@reduxjs/toolkit";

export const MIN_STEP = 1;
export const MAX_STEP = 5;

export const DEFAULT_EXPORT_INCLUDES = {
  images: true,
  attachments: true,
  tags: true,
  graphicalObjects: true,
  customTypes: true,
};

export const DEFAULT_IMPORT_INCLUDES = {
  images: true,
  attachments: true,
  tags: true,
  graphicalObjects: true,
  customTypes: true,
};

export const EXPORT_OPTIONS = {
  XLSX: "xlsx",
  ZIP: "zip",
};

export const IMPORT_OPTIONS = {
  XLSX: "xlsx",
  ZIP: "zip",
};

export const IMPORT_STRATEGIES = {
  OVERWRITE: "overwrite",
  SKIP: "skip",
};

export const IMPORT_EXPORT_FLOWS = {
  IMPORT: "import",
  EXPORT: "export",
};

export const IMPORT_MIME_TYPES = {
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ZIP: "application/zip",
};

const initialState = {
  wizardStep: MIN_STEP,
  flow: IMPORT_EXPORT_FLOWS.EXPORT,
  exportOption: EXPORT_OPTIONS.XLSX,
  exportIncludes: DEFAULT_EXPORT_INCLUDES,
  importOption: IMPORT_OPTIONS.XLSX,
  importIncludes: DEFAULT_IMPORT_INCLUDES,
  importStrategy: IMPORT_STRATEGIES.SKIP,
  importFile: null,
};

const importExportSlice = createSlice({
  name: "importExport",
  initialState,
  reducers: {
    setWizardStep: (state, action) => {
      const newWizardStep = action.payload;
      state.wizardStep = newWizardStep;
    },
    nextWizardStep: (state) => {
      if (state.wizardStep < MAX_STEP) {
        state.wizardStep++;
      }
    },
    previousWizardStep: (state) => {
      if (state.wizardStep > MIN_STEP) {
        state.wizardStep--;
      }
    },
    setFlow: (state, action) => {
      const newFlow = action.payload;

      state.flow = newFlow;
      state.exportOption = EXPORT_OPTIONS.XLSX;
      state.exportIncludes = DEFAULT_EXPORT_INCLUDES;
      state.importOption = IMPORT_OPTIONS.XLSX;
      state.importIncludes = DEFAULT_IMPORT_INCLUDES;
      state.importStrategy = IMPORT_STRATEGIES.SKIP;
      state.importFile = null;
    },
    setExportOption: (state, action) => {
      const newExportOption = action.payload;

      state.exportOption = newExportOption;
      state.exportIncludes = DEFAULT_EXPORT_INCLUDES;
    },
    setExportIncludes: (state, action) => {
      const newExportIncludes = action.payload;
      state.exportIncludes = newExportIncludes;
    },
    setImportOption: (state, action) => {
      const newImportOption = action.payload;

      state.importOption = newImportOption;
      state.importIncludes = DEFAULT_IMPORT_INCLUDES;
      state.importStrategy = IMPORT_STRATEGIES.SKIP;
      state.importFile = null;
    },
    setImportIncludes: (state, action) => {
      const newImportIncludes = action.payload;
      state.importIncludes = newImportIncludes;
    },
    setImportStrategy: (state, action) => {
      const newImportStrategy = action.payload;
      state.importStrategy = newImportStrategy;
    },
    setImportFile: (state, action) => {
      const newImportFile = action.payload;
      state.importFile = newImportFile;
    },
    resetWizard: () => initialState,
  },
});

export default importExportSlice.reducer;

export const {
  setWizardStep,
  nextWizardStep,
  previousWizardStep,
  setFlow,
  setExportOption,
  setExportIncludes,
  setImportOption,
  setImportIncludes,
  setImportStrategy,
  setImportFile,
  resetWizard,
} = importExportSlice.actions;

export const selectWizardStep = (state) => state.importExport.wizardStep;
export const selectFlow = (state) => state.importExport.flow;
export const selectExportOption = (state) => state.importExport.exportOption;
export const selectExportIncludes = (state) =>
  state.importExport.exportIncludes;
export const selectImportOption = (state) => state.importExport.importOption;
export const selectImportIncludes = (state) =>
  state.importExport.importIncludes;
export const selectImportStrategy = (state) =>
  state.importExport.importStrategy;
export const selectImportFile = (state) => state.importExport.importFile;
