import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { getTranslation } from "../../../util/utils";
import { Grid, IconButton, useTheme } from "@mui/material";
import {
  StickySearchContainer,
  StickySearchTextField,
} from "../../styles/assets/StickySearch.styles";
import { useDispatch } from "react-redux";
import {
  selectSearchQuery,
  setSearchQuery,
} from "../../../store/slices/reportSlice";
import { getSvgIcon } from "../../../util/icons";

const ReportsStickySearch = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const searchQuery = useSelector(selectSearchQuery);

  // States
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  // Other variables
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleOpenSearchClick = () => {
    if (isSearchOpen) {
      setIsSearchOpen(false);
    } else {
      setIsSearchOpen(true);
    }
  };

  const handleChangeSearch = (event) => {
    const newSearchTerm = event.target.value;
    dispatch(setSearchQuery(newSearchTerm));
  };

  const handleClearSearch = () => {
    dispatch(setSearchQuery(""));
    setIsSearchOpen(false);
  };

  return (
    <StickySearchContainer>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <IconButton
            id="search"
            onClick={() => {
              if (!isSearchOpen) {
                handleOpenSearchClick();
              }
            }}
          >
            {getSvgIcon(
              "SEARCH",
              iconSize,
              iconSize,
              theme.palette.primary.main
            )}
          </IconButton>
        </Grid>
        {isSearchOpen && (
          <>
            <Grid item xs>
              <StickySearchTextField
                inputProps={{ "data-testid": "search-field" }}
                id="search-reports-field"
                variant="standard"
                placeholder={getTranslation("SEARCH", t, i18n)}
                value={searchQuery}
                onChange={handleChangeSearch}
              />
            </Grid>
            <Grid item>
              <IconButton id="clear-search" onClick={handleClearSearch}>
                {getSvgIcon(
                  "CLEAR",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
    </StickySearchContainer>
  );
};

export default ReportsStickySearch;
