import { Box, Dialog, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ErrorHandling from "../../common/ErrorHandling";
import { useTranslation } from "react-i18next";
import { selectUser } from "../../../store/slices/authSlice";
import {
  MIN_STEP,
  nextActiveStep,
  previousActiveStep,
  selectActiveStep,
  selectDescription,
  selectName,
  selectQuery,
  selectReportingFields,
  selectResultFields,
  selectTypeId,
  setDescription,
  setName,
  setQuery,
  setReportingFields,
  setResultFields,
} from "../../../store/slices/reportsSlice";
import {
  addPropertyToChildren,
  checkQueryValidity,
  constructQuery,
  parseQuery,
} from "../../../util/reports-utils";
import { messageError } from "../../../util/notification";
import {
  useCreateReportDefinitionFilterMutation,
  useGetReportDefinitionAggregationsQuery,
  useGetReportDefinitionByIdQuery,
  useGetReportDefinitionFieldsQuery,
  useGetReportDefinitionFiltersQuery,
  useReportingFieldsByTypeQuery,
  useUpdateReportDefinitionAggregationsMutation,
  useUpdateReportDefinitionFieldsMutation,
  useUpdateReportDefinitionFilterMutation,
  useUpdateReportDefinitionMutation,
} from "../../../store/slices/api/reportsApiSlice";
import { Transition } from "./MobileCreateReportDialog";
import MobileUpdateReportDialogHeader from "./MobileUpdateReportDialogHeader";
import ReportsStepper from "./ReportsStepper";
import {
  ReportsWizardBackButton,
  ReportsWizardContinueButton,
} from "../../styles/reports/Reports.styles";
import { getTranslation, THEME } from "../../../util/utils";
import BasicDataMobileStep from "./BasicDataMobileStep";
import DefineQueryMobileStep from "./DefineQueryMobileStep";
import FormatResultMobileStep from "./FormatResultMobileStep";
import AddWidgetMobileStep from "./AddWidgetMobileStep";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { selectTheme } from "../../../store/slices/appSlice";

const MobileUpdateReportDialog = ({ open, handleClose, reportId }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const activeStep = useSelector(selectActiveStep);
  const name = useSelector(selectName);
  const description = useSelector(selectDescription);
  const reportingFields = useSelector(selectReportingFields);
  const resultFields = useSelector(selectResultFields);
  const query = useSelector(selectQuery);
  const typeId = useSelector(selectTypeId);
  const currentTheme = useSelector(selectTheme);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const filters = [parseQuery(query, reportingFields)];
  const isQueryValid = checkQueryValidity(query);

  // Mutations
  const [createReportDefinitionFilter] =
    useCreateReportDefinitionFilterMutation();
  const [updateReportDefinition] = useUpdateReportDefinitionMutation();
  const [updateReportDefinitionFilter] =
    useUpdateReportDefinitionFilterMutation();
  const [updateReportDefinitionFields] =
    useUpdateReportDefinitionFieldsMutation();
  const [updateReportDefinitionAggregations] =
    useUpdateReportDefinitionAggregationsMutation();

  // Queries
  const { data: reportDefinitionData, isLoading: isLoadingReportDefinition } =
    useGetReportDefinitionByIdQuery(
      { organizationId, reportDefinitionId: reportId },
      {
        skip: !open || !reportId,
      }
    );

  const { data: reportDefinitionFields, isLoading: isLoadingReportFields } =
    useGetReportDefinitionFieldsQuery(
      { organizationId, reportDefinitionId: reportId },
      { skip: !open || !reportId }
    );

  const {
    data: reportDefinitionAggregations,
    isLoading: isLoadingReportAggregations,
  } = useGetReportDefinitionAggregationsQuery(
    {
      organizationId,
      reportDefinitionId: reportId,
    },
    { skip: !open || !reportId }
  );

  const { data: reportingFieldsData, isLoading: isLoadingReportingFields } =
    useReportingFieldsByTypeQuery(
      {
        organizationId,
        typeId: reportDefinitionData?.reportTypeId,
      },
      { skip: !reportDefinitionData?.reportTypeId }
    );

  const { data: reportDefinitionFilterData } =
    useGetReportDefinitionFiltersQuery(
      {
        organizationId,
        reportDefinitionId: reportDefinitionData?.id,
      },
      { skip: !reportDefinitionData?.id }
    );

  // Handlers
  const handleMoveBack = () => dispatch(previousActiveStep());

  const handleMoveNext = async () => {
    try {
      if (activeStep === 1) {
        const reportDefinition = {
          reportTypeId: typeId,
          name,
          description,
        };

        await updateReportDefinition({
          organizationId,
          reportDefinitionId: reportDefinitionData.id,
          reportDefinition,
        }).unwrap();
      } else if (activeStep === 2) {
        const modifiedFilters = addPropertyToChildren(
          filters,
          "reportDefinitionId",
          reportDefinitionData.id
        );

        for (let i = 0; i < modifiedFilters.length; i++) {
          const { logicalOperator, conditions, children } = modifiedFilters[i];
          const reportDefinitionFilter = {
            logicalOperator,
            conditions,
            children,
          };

          if (reportDefinitionFilterData.length >= 1) {
            await updateReportDefinitionFilter({
              organizationId,
              reportDefinitionId: reportDefinitionData.id,
              reportDefinitionFilterId: reportDefinitionFilterData[i].id,
              reportDefinitionFilter,
            }).unwrap();
          } else {
            await createReportDefinitionFilter({
              organizationId,
              reportDefinitionId: reportDefinitionData.id,
              reportDefinitionFilter,
            }).unwrap();
          }
        }
      } else if (activeStep === 3) {
        let reportDefinitionFields = [];
        let reportDefinitionAggregations = [];

        for (let i = 0; i < resultFields.length; i++) {
          const {
            field,
            usedForGrouping,
            aggregationTypes,
            orderIndex,
            sortMethod,
            displayName,
          } = resultFields[i];

          const reportingField = reportingFields.find(
            (reportingField) => reportingField.name === field
          );

          if (!reportingField) {
            continue;
          }

          const { id: reportFieldId } = reportingField;

          const existingReportDefinitionField = reportDefinitionFields.find(
            (f) => f.fieldId === reportFieldId
          );

          if (!Boolean(existingReportDefinitionField)) {
            const reportDefinitionField = {
              fieldId: reportFieldId,
              usedForGrouping,
              orderIndex,
              sortMethod,
              index: i,
              displayName,
            };

            aggregationTypes.forEach((aggregationType) => {
              reportDefinitionAggregations.push({
                fieldId: reportFieldId,
                aggregationType,
              });
            });

            reportDefinitionFields.push(reportDefinitionField);
          } else {
            if (usedForGrouping) {
              existingReportDefinitionField.usedForGrouping = usedForGrouping;
            }
          }
        }

        await updateReportDefinitionFields({
          organizationId,
          reportDefinitionId: reportDefinitionData.id,
          reportDefinitionFields,
        }).unwrap();

        await updateReportDefinitionAggregations({
          organizationId,
          reportDefinitionId: reportDefinitionData.id,
          reportDefinitionAggregations,
        }).unwrap();

        handleClose();

        return;
      }

      dispatch(nextActiveStep());
    } catch (error) {
      console.error("Error moving next", error);
      messageError(error?.data?.message);
    }
  };

  const renderContent = () => {
    if (activeStep === 1) {
      return <BasicDataMobileStep showPrivate={false} />;
    } else if (activeStep === 2) {
      return <DefineQueryMobileStep />;
    } else if (activeStep === 3) {
      return <FormatResultMobileStep />;
    } else if (activeStep === 4) {
      return <AddWidgetMobileStep />;
    }

    return <p>Invalid wizard step</p>;
  };

  // Effects
  useEffect(() => {
    // First Step
    if (reportDefinitionData) {
      dispatch(setDescription(reportDefinitionData.description));
      dispatch(setName(reportDefinitionData.name));
    }

    // Second Step
    // filters
    if (reportDefinitionFilterData && reportDefinitionFilterData.length > 0) {
      const constructedQuery = constructQuery(reportDefinitionFilterData[0]);
      dispatch(setQuery(constructedQuery));
    }

    // Third step
    // fields

    if (reportingFieldsData) {
      const formattedResultFields = reportDefinitionFields
        ?.slice()
        ?.sort((a, b) => a.index - b.index)
        .map((reportDefinitionField) => {
          const {
            field: { id: reportDefinitionFieldId },
            displayName,
            usedForGrouping,
            orderIndex,
            sortMethod,
          } = reportDefinitionField;
          const reportingField = reportingFieldsData.find(
            (f) => f.id === reportDefinitionFieldId
          );

          const aggregationTypes = reportDefinitionAggregations
            ?.filter(
              (aggregation) => aggregation.field.id === reportingField.id
            )
            ?.map((a) => a.aggregationType);

          return {
            uniqueId: uuidv4(),
            displayName: getTranslation(
              displayName ?? reportingField.name,
              t,
              i18n
            ),
            field: reportingField.name,
            orderIndex,
            sortMethod,
            usedForGrouping: usedForGrouping,
            aggregationTypes,
          };
        });

      const formattedReportingFields = reportingFieldsData.map((field) => {
        return {
          id: field.id,
          name: field.name,
          label: field.name,
          validator: ({ value }) => Boolean(value),
        };
      });

      dispatch(setReportingFields(formattedReportingFields));
      dispatch(setResultFields(formattedResultFields));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportDefinitionData,
    reportingFieldsData,
    reportDefinitionFields,
    reportDefinitionAggregations,
    reportDefinitionFilterData,
  ]);

  return (
    <ErrorHandling
      isLoading={
        isLoadingReportingFields ||
        isLoadingReportAggregations ||
        isLoadingReportDefinition ||
        isLoadingReportFields
      }
      isError={false}
    >
      <Dialog
        id="mobile-update-report-dialog"
        fullScreen
        open={open}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            paddingBlock: "10px",
          }}
        >
          <Box>
            <Box sx={{ paddingInline: "10px" }}>
              <MobileUpdateReportDialogHeader handleClose={handleClose} />
            </Box>

            <Box sx={{ marginTop: "20px" }}>
              <ReportsStepper />
            </Box>

            <Box sx={{ marginTop: "20px", paddingInline: "10px" }}>
              {renderContent()}
            </Box>
          </Box>

          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              backgroundColor: theme.palette.secondary.active,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "5px",
              }}
            >
              {activeStep > MIN_STEP && (
                <ReportsWizardBackButton
                  id="back"
                  disabled={activeStep <= MIN_STEP}
                  onClick={handleMoveBack}
                  variant="text"
                >
                  {getTranslation("back", t, i18n)}
                </ReportsWizardBackButton>
              )}

              <ReportsWizardContinueButton
                sx={{ maxWidth: "50%" }}
                id="save"
                disabled={!name || !isQueryValid}
                onClick={handleMoveNext}
                variant="contained"
              >
                {getTranslation("SAVE_AND_CONTINUE", t, i18n)}
              </ReportsWizardContinueButton>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </ErrorHandling>
  );
};

export default MobileUpdateReportDialog;
