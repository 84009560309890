import { useSelector } from "react-redux";
import { useGetCurrentSubscriptionActualUsageQuery } from "../store/slices/api/subscriptionsApiSlice";
import { selectUser } from "../store/slices/authSlice";
import ErrorHandling from "./common/ErrorHandling";
import useCheckOrganizationRestricted from "../hooks/useCheckOrganizationRestricted";
import { getTranslation } from "../util/utils";
import { Stack, Alert, AlertTitle } from "@mui/material";
import { useTranslation } from "react-i18next";

const ALERT_STATUS = {
  EXCEEDED: "EXCEEDED",
  REACHED: "REACHED",
  APPROACHED: "APPROACHED",
  SUCCESS: "SUCCESS",
};

const ALERT_SEVERITY = {
  EXCEEDED: "error",
  REACHED: "warning",
  APPROACHED: "info",
};

const ALERT_DESCRIPTION = {
  EXCEEDED: "ALERT_LIMIT_EXCEEDED",
  REACHED: "ALERT_LIMIT_REACHED",
  APPROACHED: "ALERT_LIMIT_APPROACHED",
};

const APPROACH_PERCENTAGE = 0.8;

const Layer2Alerts = ({ organization }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const activeOrganization = user?.organizations?.find((o) => o.default);

  // Custom hooks
  const { isLoading, isRestricted } =
    useCheckOrganizationRestricted(activeOrganization);

  // Queries
  const { data: actualUsage, isLoading: isLoadingActualUsage } =
    useGetCurrentSubscriptionActualUsageQuery(
      {
        organizationId: activeOrganization?.id,
      },
      {
        skip: isRestricted,
      }
    );

  // Other variables
  const facts = actualUsage?.facts;
  const assetsUsage = facts?.find((f) => f.name === "ASSET_COUNT");
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const assetsSubscriptionValue = Number(assetsUsage?.subscriptionValue ?? 0);
  const assetsCurrentValue = Number(assetsUsage?.currentValue ?? 0);
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue ?? 0);
  const usersCurrentValue = Number(usersUsage?.currentValue ?? 0);

  // Handlers
  const getStatus = (value, limit) => {
    if (value > APPROACH_PERCENTAGE * limit && value < limit) {
      return ALERT_STATUS.APPROACHED;
    } else if (value === limit) {
      return ALERT_STATUS.REACHED;
    } else if (value > limit) {
      return ALERT_STATUS.EXCEEDED;
    } else {
      return ALERT_STATUS.SUCCESS;
    }
  };

  const assetStatus = getStatus(assetsCurrentValue, assetsSubscriptionValue);
  const userStatus = getStatus(usersCurrentValue, usersSubscriptionValue);

  return (
    <ErrorHandling
      isLoading={isLoading || isLoadingActualUsage}
      isError={false}
    >
      {organization?.id === activeOrganization?.id && (
        <Stack sx={{ marginBlock: "10px", width: "100%" }} spacing={2}>
          {activeOrganization?.owner && !activeOrganization?.subscription && (
            <Alert variant="outlined" severity="error" onClose={() => {}}>
              {getTranslation("NO_ACTIVE_SUBSCRIPTION_FOUND", t, i18n)}
            </Alert>
          )}

          {actualUsage && (
            <>
              {userStatus !== ALERT_STATUS.SUCCESS && (
                <Alert
                  variant="outlined"
                  severity={ALERT_SEVERITY[userStatus]}
                  onClose={() => {}}
                >
                  {getTranslation("USERS", t, i18n)}{" "}
                  {getTranslation(ALERT_DESCRIPTION[userStatus], t, i18n)}
                </Alert>
              )}
              {assetStatus !== ALERT_STATUS.SUCCESS && (
                <Alert
                  variant="outlined"
                  severity={ALERT_SEVERITY[assetStatus]}
                  onClose={() => {}}
                >
                  {getTranslation("ASSETS", t, i18n)}{" "}
                  {getTranslation(ALERT_DESCRIPTION[assetStatus], t, i18n)}
                </Alert>
              )}
            </>
          )}
        </Stack>
      )}
    </ErrorHandling>
  );
};

export default Layer2Alerts;
