import {
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Stack,
  useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useGetResourceFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  DESKTOP_TYPES_SORTING_OPTIONS,
  selectCategory,
  selectFunction,
  selectTypeSearchTerm,
  setCategory,
  setFunction,
  setTypeQuery,
  setTypeSearchTerm,
} from "../../../store/slices/typeSearchSlice";
import {
  CATEGORY_SELECT_LIST,
  getTranslation,
  RESOURCE_CATEGORIES,
} from "../../../util/utils";
import ErrorHandling from "../../common/ErrorHandling";
import {
  DynamicVisibilityItemIcon,
  SearchTextField,
  SortingOptionsMenu,
  StickySearchContainer,
  UnSpacedSelectInput,
} from "../../styles/types/type-list/DesktopTypeStickySearch.styles";
import { getSvgIcon } from "../../../util/icons";

const DesktopTypeStickySearch = ({ sortingOption, setSortingOption }) => {
  // General hooks
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const category = useSelector(selectCategory);
  const functionId = useSelector(selectFunction);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const searchTerm = useSelector(selectTypeSearchTerm);

  // States
  const [anchorEl, setAnchorEl] = useState(null);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default).id;
  const iconSize = globalFontSize * 1.5;

  // Queries
  const {
    data: resourceFunctionsData,
    isLoading,
    isFetching,
    isError,
  } = useGetResourceFunctionsQuery(
    {
      category,
      organizationId,
      onlyWithType: false,
    },
    { skip: !Boolean(category), refetchOnMountOrArgChange: true }
  );

  // Other variables
  const open = Boolean(anchorEl);
  const functions = useMemo(
    () =>
      resourceFunctionsData?.map((f) => ({
        label: f.name,
        value: f.id,
      })) ?? [],
    [resourceFunctionsData]
  );

  // Handlers
  const handleChangeCategory = (value) => {
    const newCategory = value;

    dispatch(setCategory(newCategory));
    dispatch(setFunction(null));

    if (newCategory) {
      searchParams.set("category", newCategory);
    } else {
      searchParams.delete("category");
    }
    searchParams.delete("functionId");

    setSearchParams(searchParams);
  };

  const handleChangeFunctionId = (value) => {
    const newFunctionId = value;
    dispatch(setFunction(newFunctionId));

    if (newFunctionId) {
      searchParams.set("category", category);
      searchParams.set("functionId", newFunctionId);
    } else {
      searchParams.delete("functionId");
    }

    setSearchParams(searchParams);
  };

  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    dispatch(setTypeSearchTerm(newSearchTerm));

    setTimeout(() => {
      dispatch(setTypeQuery(newSearchTerm));
    }, 2000);
  };

  const handleOpenSortingOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSortingOptions = () => {
    setAnchorEl(null);
  };

  const handleSortingOptionClick = (option) => {
    setSortingOption(option);
  };

  const handleOrderClick = (e) => {
    e.stopPropagation();

    setSortingOption({
      ...sortingOption,
      order: sortingOption.order === "asc" ? "desc" : "asc",
    });
  };

  const handleClearSearch = () => {
    dispatch(setTypeQuery(""));
    dispatch(setTypeSearchTerm(""));
  };

  return (
    <ErrorHandling isLoading={isLoading || isFetching} isError={isError}>
      <StickySearchContainer gap={2} container>
        <Grid item md>
          <Stack direction="row" alignItems="center" spacing={1} width="90%">
            <UnSpacedSelectInput
              fullWidth
              selectLabelId="resourceCategory-type-label"
              label={getTranslation("CATEGORY", t, i18n)}
              handleChange={handleChangeCategory}
              data={CATEGORY_SELECT_LIST.filter(
                (r) => r.value !== RESOURCE_CATEGORIES.LOCATION
              )}
              value={category}
            />
            <UnSpacedSelectInput
              disabled={functions.length === 0 || !Boolean(category)}
              fullWidth
              selectLabelId="function-type-label"
              label={getTranslation("FUNCTION", t, i18n)}
              value={functionId}
              handleChange={handleChangeFunctionId}
              data={functions}
            />
          </Stack>
        </Grid>
        <Grid item>
          {getSvgIcon(
            "SORTING",
            iconSize,
            iconSize,
            theme.palette.secondary.contrastText
          )}

          <IconButton onClick={handleOpenSortingOptions}>
            {getSvgIcon(
              "EXPAND",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </IconButton>
          <SortingOptionsMenu
            id="options-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseSortingOptions}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {DESKTOP_TYPES_SORTING_OPTIONS.map((option) => {
              const { value } = option;

              return (
                <MenuItem
                  onClick={() => handleSortingOptionClick(option)}
                  key={value}
                >
                  <DynamicVisibilityItemIcon
                    isvisible={sortingOption.value === value}
                  >
                    {getSvgIcon(
                      "CHECK",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.dark
                    )}
                  </DynamicVisibilityItemIcon>
                  <ListItemText>{getTranslation(value, t, i18n)}</ListItemText>
                  <DynamicVisibilityItemIcon
                    isvisible={sortingOption.value === value}
                    onClick={handleOrderClick}
                  >
                    {sortingOption.order === "asc"
                      ? getSvgIcon(
                          "COLLAPSE",
                          iconSize,
                          iconSize,
                          theme.palette.secondary.dark
                        )
                      : getSvgIcon(
                          "EXPAND",
                          iconSize,
                          iconSize,
                          theme.palette.secondary.dark
                        )}
                  </DynamicVisibilityItemIcon>
                </MenuItem>
              );
            })}
          </SortingOptionsMenu>
        </Grid>
        <Grid item md={4}>
          <SearchTextField
            inputProps={{
              "data-testid": "search-field",
              style: {
                padding: 0,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {getSvgIcon(
                    "SEARCH",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {searchTerm && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearSearch}>
                        {getSvgIcon(
                          "CLEAR",
                          iconSize,
                          iconSize,
                          theme.palette.secondary.contrastText
                        )}
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            id="search-field"
            variant="standard"
            placeholder={getTranslation("SEARCH_TERM", t, i18n)}
            value={searchTerm}
            onChange={handleSearch}
          />
        </Grid>
      </StickySearchContainer>
    </ErrorHandling>
  );
};

export default DesktopTypeStickySearch;
