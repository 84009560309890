import React, { useEffect } from "react";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import Layer2Access from "./Layer2Access";
import {
  getPermissionsFromUserRoles,
  hasAccess,
  permissions,
} from "../../util/utils";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";

const Layer2AccessOrganization = ({ organization, children }) => {
  //Selectors
  const user = useSelector(selectUser);

  // Queries
  const { data: userRoles } = useUserRolePermissionsQuery(
    {
      organizationId: organization?.id,
      userId: user.id,
    },
    { skip: !organization?.id }
  );

  // Other variables
  if (
    hasAccess(
      "all",
      [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
      getPermissionsFromUserRoles(userRoles)
    )
  ) {
    return <>{children}</>;
  }

  return <Layer2Access organization={organization}>{children}</Layer2Access>;
};

export default Layer2AccessOrganization;
