import { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  refreshUser,
  selectUser,
  uploadOrganizationImage,
} from "../../store/slices/authSlice";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  localizeDate,
  permissions,
  transitionDirections,
} from "../../util/utils";
import UploadImage from "../../components/UploadImage";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import OrganizationDetailsHeader from "../../navigation/header/organization/OrganizationDetailsHeader";
import {
  useDeleteOrganizationImageMutation,
  useGetOrganizationByIdQuery,
  useGetOrganizationLogoQuery,
  useGetOrganizationUsersQuery,
  useLeaveOrganizationMutation,
  useUploadOrganizationImageMutation,
} from "../../store/slices/api/organizationsApiSlice";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import ErrorHandling from "../../components/common/ErrorHandling";
import { fetchCurrentUser } from "../../services/UserService";
import {
  HomePagePadding,
  SecondaryContrastButton,
} from "../../components/styles/general/General.styles";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import { useGetCurrentSubscriptionActualUsageQuery } from "../../store/slices/api/subscriptionsApiSlice";
import AppAccess from "../../components/common/AppAccess";
import { getSvgIcon } from "../../util/icons";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import Layer2Alerts from "../../components/Layer2Alerts";
import { PrimaryText } from "../../components/styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import Access from "../../components/common/Access";
import OrganizationDesktopDetails from "./OrganizationDesktopDetails";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import ProfileDesktopHeader from "../../navigation/header/profile/desktop/ProfileDesktopHeader";
import Layer2AccessOrganization from "../../components/common/Layer2AccessOrganization";

const OrganizationDetails = () => {
  // Global hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [initialFile, setInitialFile] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    organizationId,
    userId: user.id,
  });

  const {
    data: organizationData,
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization,
  } = useGetOrganizationByIdQuery(organizationId);

  // Other variables
  const org = user?.organizations?.find((o) => o.id === organizationId);

  // Custom hooks
  const { isLoading, isRestricted, subscription, canFetchCurrentSubscription } =
    useCheckOrganizationRestricted(org);

  // Other variables
  const orgDisabled = hasAccess(
    "all",
    [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
    getPermissionsFromUserRoles(userRoles)
  )
    ? false
    : isRestricted;

  const {
    data: organizationUsers,
    isLoading: isLoadingOrganizationUsers,
    isError: isErrorOrganizationUsers,
  } = useGetOrganizationUsersQuery(organizationId, {
    skip:
      orgDisabled ||
      !hasAccess(
        "all",
        [permissions.USER_MANAGEMENT_VIEW],
        getPermissionsFromUserRoles(userRoles)
      ),
  });

  const { data: actualUsage, isLoading: isLoadingActualUsage } =
    useGetCurrentSubscriptionActualUsageQuery(
      {
        organizationId: org?.id,
      },
      {
        skip: isRestricted,
      }
    );

  // Other variables
  const facts = actualUsage?.facts;
  const assetsUsage = facts?.find((f) => f.name === "ASSET_COUNT");
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const assetsSubscriptionValue = Number(assetsUsage?.subscriptionValue);
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue);
  const editIconSize = globalFontSize * 1.2;
  const iconSize = globalFontSize * 1.5;

  const { data: organizationLogo } = useGetOrganizationLogoQuery(
    {
      logoUri: organizationData?.logoUri,
      thumbnail: true,
    },
    {
      skip: !Boolean(organizationData?.logoUri),
    }
  );

  // Mutations
  const [uploadImage, { isLoading: isLoadingUploadImage }] =
    useUploadOrganizationImageMutation();

  const [leaveOrganization, { isLoading: isLoadingLeaveOrganization }] =
    useLeaveOrganizationMutation();

  const [
    deleteOrganizationImage,
    { isLoading: isLoadingDeleteOrganizationImage },
  ] = useDeleteOrganizationImageMutation();

  // Other variables
  let transitionDirection = searchParams.get("direction");

  const organizationDisplayName =
    organizationData?.nickname || organizationData?.name;

  // Handlers
  const submitUploadedImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      let resp = await uploadImage({
        formData,
        organizationId: organizationData?.id,
      }).unwrap();

      dispatch(
        uploadOrganizationImage({
          logoUri: resp.logoUri,
          organizationId: organizationData?.id,
        })
      );

      messageSuccess(
        getTranslation("successfulUpdateOrganizationLogo", t, i18n)
      );
    } catch (error) {
      messageError(getTranslation("failedUpdateOrganizationLogo", t, i18n));
    }
  };

  const handleDeleteOrganizationImage = async () => {
    try {
      await deleteOrganizationImage({ organizationId }).unwrap();

      dispatch(
        uploadOrganizationImage({
          logoUri: "",
          organizationId,
        })
      );

      messageSuccess(getTranslation("ORGANIZATION_IMAGE_DELETED", t, i18n));
    } catch (error) {
      console.error("Failed to delete profile image");
    }
  };

  const handleLeaveOrganization = async () => {
    try {
      await leaveOrganization(organizationId).unwrap();
      const response = await fetchCurrentUser();
      dispatch(refreshUser(response.data));
      messageSuccess(getTranslation("successfulLeaveOrganization", t, i18n));
      navigate("/profile/organization");
    } catch (error) {
      messageError(getTranslation("failedLeaveOrganization", t, i18n));
    }
  };

  const goBackHandler = () =>
    navigate(
      `/profile/organization?direction=${transitionDirections.LEFT_TO_RIGHT}`
    );

  const handleNavigateUserDetails = () => {
    navigate(
      `/profile/organization/${org.id}/user-management?direction=${transitionDirections.RIGHT_TO_LEFT}`
    );
  };

  const navigateToMoreDetails = () =>
    navigate(
      `/profile/organization/${organizationId}/more-details?direction=${transitionDirections.LEFT_TO_RIGHT}`
    );

  // Effects
  useEffect(() => {
    if (organizationLogo) {
      setInitialFile(organizationLogo);
    }
  }, [organizationLogo]);

  return (
    <AppAccess>
      <Access all={[permissions["ORG_MANAGEMENT_EDIT"]]}>
        <Layer2AccessOrganization organization={org}>
          <ErrorHandling
            isLoading={
              isLoadingUserRoles ||
              isLoadingOrganizationUsers ||
              isLoadingLeaveOrganization ||
              isLoadingUploadImage ||
              isLoadingDeleteOrganizationImage ||
              isLoadingOrganization ||
              isLoading ||
              isLoadingActualUsage
            }
            isError={
              isErrorUserRoles ||
              isErrorOrganizationUsers ||
              isErrorOrganization
            }
          >
            <PageTransition direction={transitionDirection}>
              <ConfirmAlert
                isOpen={openConfirm}
                setIsOpen={setOpenConfirm}
                alert={{
                  content:
                    getTranslation("organizationLeaveMessage", t, i18n) +
                    " " +
                    organizationDisplayName +
                    " " +
                    getTranslation("organization", t, i18n).toLowerCase(),
                  confirmTitle: getTranslation(
                    "organizationConfirmTitle",
                    t,
                    i18n
                  ),
                  closeTitle: getTranslation("CANCEL", t, i18n),
                  showConfirm: true,
                }}
                label="leave-organization"
                handleConfirm={handleLeaveOrganization}
              />

              {mobileMatches ? (
                <>
                  <OrganizationDetailsHeader
                    goBackHandler={goBackHandler}
                    title={organizationDisplayName}
                  />

                  <HomePagePadding>
                    <Layer2Alerts organization={org} />

                    <UploadImage
                      initialFile={
                        organizationData?.logoUri ? initialFile : null
                      }
                      submitUploadedImage={submitUploadedImage}
                      uploadTitle={
                        initialFile && organizationData?.logoUri
                          ? "EDIT_LOGO"
                          : "ADD_LOGO"
                      }
                      checker="all"
                      permissions={[permissions.ORG_MANAGEMENT_EDIT]}
                      userRoles={userRoles}
                      deleteImage={handleDeleteOrganizationImage}
                      disabled={orgDisabled}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Box>
                        <PrimaryText variant="h5">
                          {getTranslation("YOUR_PACKAGE", t, i18n)}
                        </PrimaryText>
                      </Box>

                      {/* {hasAccess(
                      "all",
                      [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
                      getPermissionsFromUserRoles(userRoles)
                    ) && (
                      <Box>
                        <SecondaryContrastButton
                          id="edit-organization-subscription"
                          //onClick={handleOpen}
                          startIcon={getSvgIcon(
                            "EDIT",
                            editIconSize,
                            editIconSize,
                            theme.palette.secondary.contrastText
                          )}
                        >
                          {getTranslation("EDIT", t, i18n)}
                        </SecondaryContrastButton>
                      </Box>
                    )} */}
                    </Box>

                    {isRestricted ? (
                      <PrimaryText
                        sx={{ marginTop: "15px", whiteSpace: "normal" }}
                        align="center"
                      >
                        {getTranslation("YOU_ARE_RESTRICTED", t, i18n)}
                      </PrimaryText>
                    ) : (
                      <Box sx={{ marginTop: "15px" }}>
                        <PrimaryText
                          sx={{
                            color:
                              theme.palette.primary[
                                org?.subscription?.tier?.name
                              ],
                          }}
                          variant="h5"
                        >
                          {getTranslation(
                            org?.subscription?.tier?.name,
                            t,
                            i18n
                          )}
                        </PrimaryText>

                        {canFetchCurrentSubscription && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <Box>
                              {getSvgIcon(
                                "FORUM",
                                iconSize,
                                iconSize,
                                theme.palette.primary[
                                  org?.subscription?.tier?.name
                                ]
                              )}
                            </Box>

                            <Box>
                              <PrimaryText>
                                {getTranslation("USERS_INCLUDED", t, i18n)}:{" "}
                                {usersSubscriptionValue}
                              </PrimaryText>
                            </Box>
                          </Box>
                        )}

                        {canFetchCurrentSubscription && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: "10px",
                              marginTop: "10px",
                            }}
                          >
                            <Box>
                              {getSvgIcon(
                                "ASSETS",
                                iconSize,
                                iconSize,
                                theme.palette.primary[
                                  org?.subscription?.tier?.name
                                ]
                              )}
                            </Box>

                            <Box>
                              <PrimaryText>
                                {getTranslation("ASSETS_INCLUDED", t, i18n)}:{" "}
                                {assetsSubscriptionValue}
                              </PrimaryText>
                            </Box>
                          </Box>
                        )}

                        {canFetchCurrentSubscription && (
                          <PrimaryText sx={{ marginTop: "10px" }}>
                            {`${getTranslation("VALID_UNTIL", t, i18n)} ${
                              subscription?.validUntil
                                ? localizeDate(
                                    subscription?.validUntil,
                                    user?.region
                                  )
                                : "N/A"
                            }`}
                          </PrimaryText>
                        )}
                      </Box>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Box>
                        <PrimaryText variant="h5">
                          {getTranslation("ORGANIZATION_DETAILS", t, i18n)}
                        </PrimaryText>
                      </Box>

                      <Box>
                        <SecondaryContrastButton
                          id="organization-more-details"
                          onClick={navigateToMoreDetails}
                        >
                          {getTranslation("MORE_DETAILS", t, i18n)}
                        </SecondaryContrastButton>
                      </Box>
                    </Box>

                    <Box sx={{ marginTop: "15px" }}>
                      <PrimaryText>
                        {getTranslation("ORGANIZATION_NAME", t, i18n)}:{" "}
                        {organizationData?.name}
                      </PrimaryText>

                      <PrimaryText sx={{ marginTop: "10px" }}>
                        {getTranslation("NICKNAME", t, i18n)}:{" "}
                        {organizationData?.nickname ?? "(Empty)"}
                      </PrimaryText>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Box>
                        <PrimaryText variant="h4">
                          {getTranslation("USERS", t, i18n)}
                        </PrimaryText>
                      </Box>

                      {!orgDisabled &&
                        hasAccess(
                          "all",
                          [permissions.USER_MANAGEMENT_VIEW],
                          getPermissionsFromUserRoles(userRoles)
                        ) && (
                          <Box>
                            <SecondaryContrastButton
                              id="organization-users-more-details"
                              onClick={handleNavigateUserDetails}
                            >
                              {getTranslation("MORE_DETAILS", t, i18n)}
                            </SecondaryContrastButton>
                          </Box>
                        )}
                    </Box>

                    {orgDisabled ||
                    !hasAccess(
                      "all",
                      [permissions.USER_MANAGEMENT_VIEW],
                      getPermissionsFromUserRoles(userRoles)
                    ) ? (
                      <PrimaryText
                        sx={{ marginTop: "15px", whiteSpace: "normal" }}
                        align="center"
                      >
                        {getTranslation(
                          "CANNOT_VIEW_ORGANIZATION_USERS",
                          t,
                          i18n
                        )}
                      </PrimaryText>
                    ) : (
                      <Box sx={{ marginTop: "15px" }}>
                        {organizationUsers?.map((u, i) =>
                          i < organizationUsers?.length - 1 ? (
                            <PrimaryText key={u?.id} sx={{ marginTop: "10px" }}>
                              {`${u?.firstName} ${u?.lastName},`}
                            </PrimaryText>
                          ) : (
                            <PrimaryText key={u?.id} sx={{ marginTop: "10px" }}>
                              {`${u?.firstName} ${u?.lastName}`}
                            </PrimaryText>
                          )
                        )}
                      </Box>
                    )}
                  </HomePagePadding>
                </>
              ) : (
                <>
                  <ProfileDesktopHeader />
                  <OrganizationDesktopDetails />
                </>
              )}
            </PageTransition>
          </ErrorHandling>
        </Layer2AccessOrganization>
      </Access>
    </AppAccess>
  );
};

export default OrganizationDetails;
