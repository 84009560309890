import { useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  alpha,
  useTheme,
} from "@mui/material";
import {
  useInviteUserMutation,
  useRemoveInvitationMutation,
  useUpdateInvitationRoleMutation,
  useUpdateUserRolePermissionsMutation,
  useUserRolePermissionsQuery,
} from "../../store/slices/api/userManagementSlice";
import {
  USER_STATUS,
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  showValidationError,
} from "../../util/utils";
import { selectUser, setUser } from "../../store/slices/authSlice";
import {
  CancelButton,
  ChangeRoleButton,
  HandoverOwnershipTitle,
  RemoveFromOrganizationButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../styles/general/General.styles";
import { messageError, messageSuccess } from "../../util/notification";
import { StyledDivider } from "../styles/profile/Profile.styles";
import {
  useActivateUserMutation,
  useGetOrganizationUsersQuery,
  useRemoveUserFromOrganizationMutation,
} from "../../store/slices/api/organizationsApiSlice";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import { getSvgIcon } from "../../util/icons";
import { useGetOrganizationRolesQuery } from "../../store/slices/api/organizationsApiSlice";
import { useGetCurrentSubscriptionActualUsageQuery } from "../../store/slices/api/subscriptionsApiSlice";
import {
  selectGlobalFontSize,
  selectOrganizationId,
} from "../../store/slices/appSlice";
import SendIcon from "@mui/icons-material/Send";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrganizationUserOptions = ({ user }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const organizationId = useSelector(selectOrganizationId);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentUser = useSelector(selectUser);
  const organization = currentUser?.organizations?.find(
    (o) => o.id === organizationId
  );

  // States
  const [open, setOpen] = useState(false);
  const [openOwnership, setOpenOwnership] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(organization);

  // Queries
  const { data: currentUserRoles } = useUserRolePermissionsQuery(
    {
      organizationId,
      userId: currentUser.id,
    },
    { skip: !organizationId }
  );
  const isOrganizationRestricted = hasAccess(
    "all",
    [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
    getPermissionsFromUserRoles(currentUserRoles)
  )
    ? false
    : isRestricted;

  const { data: roles } = useGetOrganizationRolesQuery(organizationId, {
    skip: !organizationId || isOrganizationRestricted,
  });

  const { data: organizationUsers } = useGetOrganizationUsersQuery(
    organizationId,
    {
      skip:
        isOrganizationRestricted ||
        !hasAccess(
          "all",
          [permissions.USER_MANAGEMENT_VIEW],
          getPermissionsFromUserRoles(currentUserRoles)
        ),
    }
  );

  const { data: actualUsage } = useGetCurrentSubscriptionActualUsageQuery(
    {
      organizationId: organization?.id,
    },
    {
      skip: isRestricted,
    }
  );

  // Mutations
  const [updateUserRolePermissions] = useUpdateUserRolePermissionsMutation();

  const [removeUserFromOrganization] = useRemoveUserFromOrganizationMutation();

  const [activateUser] = useActivateUserMutation();

  const [removeInvitation] = useRemoveInvitationMutation();

  const [inviteUser] = useInviteUserMutation();

  const [updateInvitationRole] = useUpdateInvitationRoleMutation();

  // Handlers
  const handleOpenOptionsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptionsMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpen(true);
    handleCloseOptionsMenu();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenOwnership = () => {
    setOpen(false);
    setOpenOwnership(true);
  };

  const handleCloseOwnership = () => {
    setOpenOwnership(false);
  };

  const handleUpdateUserRolePermissions = async (roleName) => {
    const role = roles?.find((r) => r.name === roleName);

    try {
      await updateUserRolePermissions({
        userId: user.id,
        add: [role.id],
        organizationId,
        remove: user?.roles?.filter((r) => r.id !== role.id)?.map((r) => r.id),
      }).unwrap();

      if (roleName === "OWNER") {
        const newOrganizations = currentUser?.organizations?.map((org) => {
          return org.id === organizationId ? { ...org, owner: false } : org;
        });

        dispatch(
          setUser({
            ...currentUser,
            organizations: newOrganizations,
          })
        );
      }

      messageSuccess(
        getTranslation("successfulUpdateUserRolePermissions", t, i18n)
      );
    } catch (error) {
      messageError(getTranslation("failedUpdateUserRolePermissions", t, i18n));
    }
  };

  const handleUpdateInvitationRoles = async (roleId) => {
    try {
      await updateInvitationRole({
        invitationId: user.invitationId,
        organizationId,
        roleId,
      }).unwrap();

      messageSuccess(getTranslation("successfulUpdateInvitationRole", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedUpdateInvitationRole", t, i18n));
    }
  };

  const handleRemoveUserFromOrganization = async () => {
    try {
      await removeUserFromOrganization({
        organizationId,
        userId: user.id,
      }).unwrap();

      messageSuccess(
        getTranslation("successfulRemoveUserFromOrganization", t, i18n)
      );
    } catch (error) {
      messageError(getTranslation("failedRemoveUserFromOrganization", t, i18n));
    }
  };

  const handleRemoveInvitation = async () => {
    try {
      await removeInvitation({
        organizationId,
        invitationId: user.invitationId,
      });

      messageSuccess(getTranslation("successfulRemoveInvitation", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedRemoveInvitation", t, i18n));
    }
  };

  const handleActivateUser = async () => {
    try {
      await activateUser({
        organizationId,
        userId: user.id,
      }).unwrap();

      messageSuccess(getTranslation("USER_ACTIVATED", t, i18n));
    } catch (error) {
      showValidationError(error, t, i18n);
    }
  };

  const handleResendInvitation = async () => {
    const { email, roles } = user;

    const userData = {
      email,
      roles: roles?.map((r) => r.id),
    };

    const invitationData = [userData];

    try {
      await inviteUser({ invitationData, organizationId }).unwrap();
      messageSuccess(getTranslation("successfulResendInvitation", t, i18n));
    } catch (error) {
      showValidationError(error, t, i18n);
    }
  };

  // Other variables
  const openMenu = Boolean(anchorEl);
  const iconSize = globalFontSize;
  const isUserActive = user.status === USER_STATUS.ACTIVE;

  // Permission variables
  const userHasOwnerRole = user?.roles?.some((r) => r.name === "OWNER");
  const currentUserHasOwnerRole = currentUserRoles?.some(
    (r) => r.name === "OWNER"
  );
  const hasSubscription = Boolean(organization?.subscription);
  const isAllowedChangeRole =
    hasSubscription &&
    hasAccess(
      "all",
      [permissions.USER_MANAGEMENT_ADD],
      getPermissionsFromUserRoles(currentUserRoles)
    ) &&
    !userHasOwnerRole &&
    currentUser.id !== user.id;

  // Subscription variables
  const usersCurrentValue = organizationUsers?.length ?? 0;
  const facts = actualUsage?.facts;
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue);

  // List variables
  const leftRoles =
    roles?.filter((r) => !user?.roles?.some((ur) => ur.name === r.name)) ?? [];

  const leftRolesExceptOwner =
    roles?.filter(
      (r) =>
        !user?.roles?.some((ur) => ur.name === r.name) && r.name !== "OWNER"
    ) ?? [];

  return (
    <>
      <IconButton
        onClick={isAllowedChangeRole ? handleOpenOptionsMenu : () => {}}
        sx={{ visibility: isAllowedChangeRole ? "visible" : "hidden" }}
      >
        {getSvgIcon(
          "MORE",
          iconSize,
          iconSize,
          !isUserActive
            ? alpha(theme.palette.primary.main, 0.4)
            : theme.palette.secondary.dark
        )}
      </IconButton>
      <Menu
        slotProps={{
          paper: {
            elevation: 1,
            sx: {
              borderRadius: "8px",
            },
          },
        }}
        aria-labelledby="more-icon"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id="menu-options"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseOptionsMenu}
      >
        {usersCurrentValue <= usersSubscriptionValue && (
          <>
            {!isUserActive && (
              <MenuItem
                onClick={() => {
                  handleCloseOptionsMenu();
                  handleResendInvitation();
                }}
              >
                <ListItemIcon>
                  <SendIcon
                    sx={{
                      color: theme.palette.secondary.contrastText,
                      width: iconSize,
                      height: iconSize,
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  {getTranslation("RESENDINVITATION", t, i18n)}
                </ListItemText>
              </MenuItem>
            )}
            {user.restricted &&
              hasAccess(
                "all",
                [permissions.USER_MANAGEMENT_EDIT],
                getPermissionsFromUserRoles(currentUserRoles)
              ) && (
                <MenuItem
                  id="activate-member"
                  onClick={() => {
                    handleCloseOptionsMenu();
                    handleActivateUser();
                  }}
                >
                  <ListItemIcon>
                    <PowerSettingsNewIcon
                      sx={{
                        color: theme.palette.error.main,
                        width: iconSize,
                        height: iconSize,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: theme.palette.error.main }}>
                    {getTranslation("ACTIVATE", t, i18n)}
                  </ListItemText>
                </MenuItem>
              )}
            <MenuItem onClick={handleOpen} id="edit-role">
              <ListItemIcon>
                {getSvgIcon(
                  "EDIT",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </ListItemIcon>
              <ListItemText>
                {getTranslation("EDIT_ROLE", t, i18n)}
              </ListItemText>
            </MenuItem>
          </>
        )}

        {hasAccess(
          "all",
          [permissions.USER_MANAGEMENT_DELETE],
          getPermissionsFromUserRoles(currentUserRoles)
        ) && (
          <MenuItem
            onClick={() => {
              handleCloseOptionsMenu();
              if (isUserActive) {
                handleRemoveUserFromOrganization();
              } else {
                handleRemoveInvitation();
              }
            }}
          >
            <ListItemIcon>
              {getSvgIcon(
                "DELETE",
                iconSize,
                iconSize,
                theme.palette.error.main
              )}
            </ListItemIcon>
            <ListItemText sx={{ color: theme.palette.error.main }}>
              {getTranslation("REMOVE", t, i18n)}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
      <StyledDialog
        fullWidth
        open={openOwnership}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseOwnership}
        aria-describedby="alert-dialog-slide-description"
      >
        <StyledDialogContent>
          <HandoverOwnershipTitle>
            {getTranslation("CHANGE_OWNERSHIP", t, i18n)}
          </HandoverOwnershipTitle>
          <StyledDivider />
          <RemoveFromOrganizationButton
            id={`remove-member`}
            color="error"
            onClick={() => {
              handleUpdateUserRolePermissions("OWNER");
              handleCloseOwnership();
            }}
          >
            {getTranslation("HAND_OVER_OWNERSHIP", t, i18n)}
          </RemoveFromOrganizationButton>
        </StyledDialogContent>
        <CancelButton id={`cancel-button`} onClick={handleCloseOwnership}>
          {getTranslation("CANCEL", t, i18n)}
        </CancelButton>
      </StyledDialog>
      <StyledDialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <StyledDialogContent>
          <StyledDialogTitle>{user.name}</StyledDialogTitle>
          {currentUserHasOwnerRole && isUserActive
            ? leftRoles.map((r) => (
                <div key={r.id}>
                  <StyledDivider />
                  <ChangeRoleButton
                    id={`change-role`}
                    onClick={
                      r.name === "OWNER"
                        ? handleOpenOwnership
                        : () => {
                            handleClose();
                            if (isUserActive) {
                              handleUpdateUserRolePermissions(r.name);
                            } else {
                              handleUpdateInvitationRoles(r.id);
                            }
                          }
                    }
                  >
                    {getTranslation("CHANGETO" + r.name, t, i18n)}
                  </ChangeRoleButton>
                </div>
              ))
            : leftRolesExceptOwner.map((r) => (
                <div key={r.id}>
                  <StyledDivider />
                  <ChangeRoleButton
                    id={`change-role`}
                    onClick={() => {
                      handleClose();
                      if (isUserActive) {
                        handleUpdateUserRolePermissions(r.name);
                      } else {
                        handleUpdateInvitationRoles(r.id);
                      }
                    }}
                  >
                    {getTranslation("CHANGETO" + r.name, t, i18n)}
                  </ChangeRoleButton>
                </div>
              ))}
        </StyledDialogContent>

        <CancelButton id={`cancel-button`} onClick={handleClose}>
          {getTranslation("CANCEL", t, i18n)}
        </CancelButton>
      </StyledDialog>
    </>
  );
};

export default OrganizationUserOptions;
