import React from "react";
import { Avatar } from "@mui/material";

const OrganizationUserAvatarCard = ({ user, organizationId }) => {
  const { firstName, lastName } = user;

  //Queries
  // const { data: selectedFile } = useGetUserProfileImageQuery(
  //   {
  //     avatarUri: user?.avatarUri,
  //     thumbnail: true,
  //   },
  //   {
  //     skip: !Boolean(avatarUri) ,
  //   }
  // );
  // Other variables
  const fullName = `${firstName} ${lastName}`;
  const initials = `${firstName?.substring(0, 1)}${lastName?.substring(0, 1)}`;

  return (
    <Avatar alt={fullName} src={""}>
      {initials}
    </Avatar>
  );
};

export default OrganizationUserAvatarCard;
