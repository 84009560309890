import { Grid, Stack } from "@mui/material";
import React from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";
import CircleIcon from "@mui/icons-material/Circle";

const StatusWidgetContent = ({
  title,
  dangerCount,
  warningCount,
  successCount,
}) => {
  return (
    <Stack
      id="status-widget"
      sx={{ height: "100%", alignItems: "center", paddingInline: "8px" }}
    >
      <Stack
        sx={{
          width: "100%",
          flexGrow: 1,
          justifyContent: "center",
        }}
        spacing={2}
      >
        <Grid container>
          <Grid item xs>
            <CircleIcon sx={{ color: "#F01800" }} />
          </Grid>
          <Grid item>
            <SecondaryText>
              {dangerCount && dangerCount !== "undefined" ? dangerCount : 0}
            </SecondaryText>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <CircleIcon sx={{ color: "#FFAD01" }} />
          </Grid>
          <Grid item>
            <SecondaryText>
              {warningCount && warningCount !== "undefined" ? warningCount : 0}
            </SecondaryText>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <CircleIcon sx={{ color: "#146F2A" }} />
          </Grid>
          <Grid item>
            <SecondaryText>
              {successCount && successCount !== "undefined" ? successCount : 0}
            </SecondaryText>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default StatusWidgetContent;
