import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import {
  IconButton,
  ListItemIcon,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  RESOURCE_CATEGORIES,
  transitionDirections,
} from "../../../util/utils";
import { Item } from "../../styles/assets/ListInlineView.styles";
import { messageSuccess } from "../../../util/notification";
import { useTranslation } from "react-i18next";
import {
  AttachmentItemContainer,
  AttachmentNameText,
  DeleteAttachmentButton,
  StyledDividerWithSpace,
} from "../../styles/assets/asset-attachments/AssetResourceAttachment.styles";
import { useState, forwardRef } from "react";
import {
  CancelButton,
  ChangeRoleButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../../styles/general/General.styles";
import { StyledDivider } from "../../styles/profile/Profile.styles";
import {
  selectGlobalFontSize,
  selectPageView,
} from "../../../store/slices/appSlice";
import {
  useDeleteResourceAttachmentMutation,
  useGetResourceAttachmentQuery,
} from "../../../store/slices/api/resourceAttachmentsApiSlice";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import ErrorHandling from "../../common/ErrorHandling";
import { useNavigate } from "react-router-dom";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import EditAttachmentDialog from "../asset-form/EditAttachmentDialog";
import { selectParentId } from "../../../store/slices/columnViewSlice";
import { selectCurrentResourceId } from "../../../store/slices/longPressSlice";
import { getSvgIcon } from "../../../util/icons";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AssetResourceAttachment = ({ attachment, resourceid }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const globalFontSize = useSelector(selectGlobalFontSize);
  const [openConfirm, setOpenConfirm] = useState(false);
  const parentId = useSelector(selectParentId);
  const pageView = useSelector(selectPageView);
  const currentResourceId = useSelector(selectCurrentResourceId);

  // Queries
  const {
    data: resourceAttachmentUri,
    isLoading: isLoadingAttachment,
    isError: isErrorAttachment,
  } = useGetResourceAttachmentQuery(
    {
      attachmentUri: attachment.selectedFile,
      organizationId,
    },
    {
      skip: !attachment?.selectedFile,
    }
  );

  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  const {
    data: resourceData,
    isLoading: isLoadingResourceData,
    isError: isErrorResourceData,
  } = useGetResourceDetailsQuery(
    {
      resourceid,
      organizationId,
    },
    { skip: !Boolean(resourceid) }
  );

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery(
    {
      organizationId,
    },
    {
      skip: !Boolean(organizationId),
    }
  );

  // Mutations
  const [deleteResourceAttachmentMutation] =
    useDeleteResourceAttachmentMutation();

  // States
  const [open, setOpen] = useState(false);
  const [openEditAttachment, setOpenEditAttachment] = useState(false);

  // Other variables
  const calculatedResourceId = currentResourceId || resourceid || parentId;
  const iconSize = globalFontSize;

  const isLoading =
    pageView !== "column"
      ? isLoadingAttachment ||
        isLoadingUserRoles ||
        isLoadingResourceData ||
        isLoadingFunctions
      : false;

  // Handlers
  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEditImage = () => {
    if (mobileMatches) {
      navigate(
        `/resources/${resourceid}/edit-attachment/${attachment?.id}?direction=${transitionDirections.RIGHT_TO_LEFT}`
      );
    } else {
      setOpenEditAttachment(true);
    }
  };

  const handlePermissions = (key) => {
    if (!Boolean(resourceid)) {
      return true;
    }

    const functionDetails = allFunctionsData?.find(
      (f) => f.id === resourceData.functionId
    );
    const permissionsKey = `ASSET_MANAGEMENT_${
      functionDetails?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET
        ? "HARDWARE"
        : functionDetails?.category
    }_${key.toUpperCase()}`;

    if (
      hasAccess(
        "all",
        [permissions[permissionsKey]],
        getPermissionsFromUserRoles(userRoles)
      )
    ) {
      return true;
    }

    return false;
  };

  const handleDownloadResourceAttachment = (isViewable) => {
    const { name } = attachment;
    const link = document.createElement("a");
    link.href = resourceAttachmentUri;
    link.target = "_blank";

    if (!isViewable) {
      link.download = name; // Set the original file name as the downloaded file name
    }

    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteConfirm = () => {
    setOpenConfirm(true);
  };

  const handleDeleteResourceAttachment = async (resourceAttachmentId) => {
    try {
      await deleteResourceAttachmentMutation({
        resourceid,
        organizationId: user?.organizations?.find((o) => o.default).id,
        resourceAttachmentId,
      }).unwrap();
    } catch (error) {
      console.error("Failed to delete resource attachment", error);
    }

    messageSuccess(
      getTranslation("RESOURCE_ATTACHMENT_DELETED_SUCCESSFULLY", t, i18n)
    );
  };

  return (
    <ErrorHandling
      isLoading={isLoading}
      isError={
        isErrorAttachment ||
        isErrorUserRoles ||
        isErrorResourceData ||
        isErrorFunctions
      }
    >
      <AttachmentItemContainer
        mobileMatches={mobileMatches || pageView === "column"}
      >
        <StyledDialog
          fullWidth
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <StyledDialogContent>
            <StyledDialogTitle>{attachment?.name}</StyledDialogTitle>
            <StyledDivider />

            {handlePermissions("edit") && (
              <ChangeRoleButton
                id={`edit-resource-attachment`}
                onClick={() => {
                  handleClickOpenEditImage();
                  handleClose();
                }}
              >
                {getSvgIcon(
                  "EDIT",
                  iconSize,
                  iconSize,
                  theme.palette.primary.main,
                  {
                    marginRight: "10px",
                  }
                )}
                {getTranslation("EDIT", t, i18n)}
              </ChangeRoleButton>
            )}

            <StyledDivider />

            {attachment.mimeType.includes("pdf") ||
            attachment.mimeType.includes("png") ||
            attachment.mimeType.includes("jpg") ||
            attachment.mimeType.includes("jpeg") ? (
              <ChangeRoleButton
                id={`view-resource-attachment`}
                onClick={() => {
                  handleDownloadResourceAttachment(true);
                  handleClose();
                }}
              >
                {getSvgIcon(
                  "VISIBILITY",
                  iconSize,
                  iconSize,
                  theme.palette.primary.main,
                  { marginRight: "10px" }
                )}
                {getTranslation("VIEW", t, i18n)}
              </ChangeRoleButton>
            ) : (
              <ChangeRoleButton
                id={`download-resource-attachment`}
                onClick={() => {
                  handleDownloadResourceAttachment(false);
                  handleClose();
                }}
              >
                {getSvgIcon(
                  "DOWNLOAD",
                  iconSize,
                  iconSize,
                  theme.palette.primary.main,
                  { marginRight: "10px" }
                )}
                {getTranslation("DOWNLOAD", t, i18n)}
              </ChangeRoleButton>
            )}

            {handlePermissions("delete") && (
              <>
                <StyledDividerWithSpace />
                <DeleteAttachmentButton
                  id={`delete-resource-attachment`}
                  data-testid={`delete-resource-attachment-${attachment?.id}`}
                  color="error"
                  onClick={() => {
                    handleClose();
                    handleDeleteConfirm();
                  }}
                >
                  {getSvgIcon(
                    "DELETE",
                    iconSize,
                    iconSize,
                    theme.palette.error.main,
                    { marginRight: "10px" }
                  )}
                  {getTranslation("DELETE", t, i18n)}
                </DeleteAttachmentButton>
              </>
            )}
          </StyledDialogContent>
          <CancelButton id={`cancel-button`} onClick={handleClose}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelButton>
        </StyledDialog>

        <ConfirmAlert
          isOpen={openConfirm}
          setIsOpen={setOpenConfirm}
          alert={{
            content: getTranslation("ASSET_ATTACHMENT_DELETE_MESSAGE", t, i18n),
            confirmTitle: getTranslation("DELETE_ASSET_ATTACHMENT", t, i18n),
            closeTitle: getTranslation("CANCEL", t, i18n),
            showConfirm: true,
          }}
          label="delete-attachment"
          handleConfirm={() => handleDeleteResourceAttachment(attachment?.id)}
        />

        {openEditAttachment && (
          <EditAttachmentDialog
            resourceId={calculatedResourceId}
            open={openEditAttachment}
            setOpen={setOpenEditAttachment}
            resourceAttachment={attachment}
          />
        )}

        <Item
          id="asset-resource-attachment"
          onClick={
            attachment.mimeType.includes("pdf") ||
            attachment.mimeType.includes("png") ||
            attachment.mimeType.includes("jpg") ||
            attachment.mimeType.includes("jpeg")
              ? () => handleDownloadResourceAttachment(true)
              : () => handleDownloadResourceAttachment(false)
          }
        >
          <ListItemIcon>
            {getSvgIcon(
              "ATTACHMENT",
              iconSize,
              iconSize,
              theme.palette.secondary.dark
            )}
          </ListItemIcon>
          <AttachmentNameText>{attachment.name}</AttachmentNameText>

          <IconButton
            onClick={handleClickOpen}
            id="more-attachment-options"
            data-testid={`more-attachment-options-${attachment.name}`}
          >
            {getSvgIcon("MORE", iconSize, iconSize, theme.palette.primary.main)}
          </IconButton>
        </Item>
      </AttachmentItemContainer>
    </ErrorHandling>
  );
};

export default AssetResourceAttachment;
