import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import ErrorHandling from "../common/ErrorHandling";
import { HomePagePadding } from "../styles/general/General.styles";
import { PrimaryText } from "../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import {
  countries,
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
} from "../../util/utils";
import { useTranslation } from "react-i18next";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import {
  useGetAddressesByOrganizationIdQuery,
  usePatchAddressMutation,
  useSaveAddressMutation,
} from "../../store/slices/api/addressesApiSlice";
import {
  useGetOrganizationByIdQuery,
  useUpdateOrganizationMutation,
} from "../../store/slices/api/organizationsApiSlice";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import { useSelector } from "react-redux";
import { selectUser, setUser } from "../../store/slices/authSlice";
import { OrganizationEditSaveButton } from "../styles/profile/ProfileDesktop.styles";
import { TABLET_SPACE } from "./OrganizationEditDesktop";
import { inputLabelProps } from "../inputs/BillingGroup";
import * as yup from "yup";
import { useFormik } from "formik";
import FormikCountriesAutocomplete from "../inputs/FormikCountriesAutocomplete";
import PhoneInput from "react-phone-input-2";
import { selectTheme } from "../../store/slices/appSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { messageError, messageSuccess } from "../../util/notification";
import { ValidationText } from "../styles/inputs/NamesGroup.styles";

const OrganizationEditMobile = ({ organizationId }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);
  const currentTheme = useSelector(selectTheme);

  // Queries
  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolePermissionsQuery(
      {
        organizationId,
        userId: user.id,
      },
      { skip: !organizationId }
    );

  const { data: organizationData, isLoading: isLoadingOrganization } =
    useGetOrganizationByIdQuery(organizationId, {
      skip: !Boolean(organizationId),
    });

  const { data: addressesData, isLoading: isLoadingAddresses } =
    useGetAddressesByOrganizationIdQuery(
      { organizationId },
      {
        skip: !Boolean(organizationId),
      }
    );

  // Yup Validations
  const validationSchema = yup.object({
    name: yup
      .string(getTranslation("ENTER_ORGANIZATION_NAME", t, i18n))
      .required(getTranslation("ORGANIZATION_NAME_REQUIRED", t, i18n))
      .min(2, getTranslation("ORGANIZATION_NAME_MIN", t, i18n))
      .max(100, getTranslation("ORGANIZATION_NAME_MAX", t, i18n)),
    nickname: yup
      .string(getTranslation("ENTER_ORGANIZATION_NICKNAME", t, i18n))
      .matches(
        /^[0-9a-zàâæçéèêëğîïıôöşùûü_]*$/,
        getTranslation("ORGANIZATION_NICKNAME_SHOULD_MATCH_FORMAT", t, i18n)
      )
      .min(3, getTranslation("ORGANIZATION_NICKNAME_MIN", t, i18n))
      .max(35, getTranslation("ORGANIZATION_NICKNAME_MAX", t, i18n)),
    addressLine1: yup
      .string(getTranslation("ENTER_ORGANIZATION_ADDRESS_LINE_1", t, i18n))
      .required(getTranslation("ORGANIZATION_ADDRESS_LINE_1_REQUIRED", t, i18n))
      .min(2, getTranslation("ORGANIZATION_ADDRESS_LINE_1_MIN", t, i18n)),
    addressLine2: yup
      .string(getTranslation("ENTER_ORGANIZATION_ADDRESS_LINE_2", t, i18n))
      .min(2, getTranslation("ORGANIZATION_ADDRESS_LINE_2_MIN", t, i18n)),
    postcode: yup
      .string(getTranslation("ENTER_ORGANIZATION_POSTCODE", t, i18n))
      .required(getTranslation("ORGANIZATION_POSTCODE_REQUIRED", t, i18n))
      .min(2, getTranslation("ORGANIZATION_POSTCODE_MIN", t, i18n)),
    city: yup
      .string(getTranslation("ENTER_ORGANIZATION_CITY", t, i18n))
      .required(getTranslation("ORGANIZATION_CITY_REQUIRED", t, i18n))
      .min(2, getTranslation("ORGANIZATION_CITY_MIN", t, i18n)),
    contactPerson: yup
      .string(getTranslation("ENTER_ORGANIZATION_CONTACT_PERSON", t, i18n))
      .required(getTranslation("ORGANIZATION_CONTACT_PERSON_REQUIRED", t, i18n))
      .matches(
        /^[A-Za-zÀàÂâæÇçÉéÈèÊêËëĞğÎîÏïİıÔôÖöŞşßÙùÛûÜü.-\s]*$/,
        getTranslation(
          "ORGANIZATION_CONTACT_PERSON_SHOULD_MATCH_FORMAT",
          t,
          i18n
        )
      )
      .min(2, getTranslation("ORGANIZATION_CONTACT_PERSON_MIN", t, i18n)),
    contactEmail: yup
      .string(getTranslation("ENTER_ORGANIZATION_CONTACT_EMAIL", t, i18n))
      .required(getTranslation("ORGANIZATION_CONTACT_EMAIL_REQUIRED", t, i18n))
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/,
        getTranslation(
          "ORGANIZATION_CONTACT_EMAIL_SHOULD_MATCH_FORMAT",
          t,
          i18n
        )
      )
      .min(2, getTranslation("ORGANIZATION_CONTACT_EMAIL_MIN", t, i18n)),
    state: yup
      .string(getTranslation("ENTER_ORGANIZATION_STATE", t, i18n))
      .min(2, getTranslation("ORGANIZATION_STATE_MIN", t, i18n)),
    country: yup
      .string(getTranslation("ENTER_ORGANIZATION_COUNTRY", t, i18n))
      .required(getTranslation("ORGANIZATION_COUNTRY_REQUIRED", t, i18n)),
    contactPhone: yup
      .string(getTranslation("ENTER_ORGANIZATION_CONTACT_PHONE", t, i18n))
      .required(getTranslation("ORGANIZATION_CONTACT_PHONE_REQUIRED", t, i18n))
      .min(10, getTranslation("ORGANIZATION_CONTACT_PHONE_MIN", t, i18n)),
  });

  // Other variables
  const { name, nickname, contactPerson, contactEmail, contactPhone } =
    organizationData ?? {};

  const defaultAddress = addressesData?.length > 0 ? addressesData[0] : {};

  const { addressLine1, addressLine2, postcode, city, country, state } =
    defaultAddress;

  // Formik
  const formik = useFormik({
    initialValues: {
      name,
      nickname: nickname ?? "",
      contactPerson,
      contactEmail,
      addressLine1,
      addressLine2: addressLine2 ?? "",
      postcode,
      city,
      state: state ?? "",
      country,
      contactPhone,
    },
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      const {
        name,
        nickname,
        contactPerson,
        contactEmail,
        addressLine1,
        addressLine2,
        postcode,
        city,
        state,
        country,
        contactPhone,
      } = values;

      const organization = {
        name,
        nickname,
        contactPerson,
        contactEmail,
        contactPhone,
      };

      const address = {
        addressLine1,
        addressLine2,
        postcode,
        city,
        state,
        country,
        district: "District",
      };

      await handleSubmit(organization, address);
    },
  });

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(
    organizationData ?? {}
  );

  // Mutations
  const [updateOrganization] = useUpdateOrganizationMutation();
  const [patchAddress] = usePatchAddressMutation();
  const [saveAddress] = useSaveAddressMutation();

  // Other variables
  const orgDisabled = hasAccess(
    "all",

    [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
    getPermissionsFromUserRoles(userRoles)
  )
    ? false
    : isRestricted;

  const isContactPhoneValid =
    formik.touched.contactPhone && Boolean(formik.errors.contactPhone)
      ? false
      : true;

  const submitDisabled =
    orgDisabled || !formik.isValid || formik.isSubmitting || !formik.dirty;

  // Handlers
  const handleChangeContactPhone = (value) => {
    formik.setFieldValue("contactPhone", value);
  };

  const handleBlurContactPhone = (e) => {
    if (!formik.touched.contactPhone) {
      formik.setFieldTouched("contactPhone");
    }
  };

  const handleSubmit = async (organization, address) => {
    try {
      const data = await updateOrganization({
        organizationData: organization,
        organizationId,
      }).unwrap();

      if (data) {
        let filteredOrganizations = user?.organizations?.filter(
          (o) => o.id !== data.id
        );

        filteredOrganizations.push(data);

        if (addressesData && addressesData?.length > 0) {
          await patchAddress({
            address,
            organizationId,
            addressId: addressesData[0]?.id,
          }).unwrap();
        } else {
          const addressData = {
            addressType: "BILLING",
            district: "District",
            ...address,
          };

          await saveAddress({ address: addressData, organizationId }).unwrap();
        }

        dispatch(
          setUser({
            ...user,
            organizations: filteredOrganizations,
          })
        );
      }

      messageSuccess(getTranslation("SAVED_CHANGES", t, i18n));
      navigate(`/profile/organization/${organizationId}/more-details`);
    } catch (error) {
      messageError(getTranslation("failedUpdateOrganizationName", t, i18n));
    }
  };

  return (
    <ErrorHandling
      isLoading={
        isLoadingUserRoles || isLoadingAddresses || isLoadingOrganization
      }
      isError={false}
    >
      <Box>
        <HomePagePadding>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Box>
                <PrimaryText variant="h5">
                  {getTranslation("ORGANIZATION_DETAILS", t, i18n)}
                </PrimaryText>
              </Box>

              {hasAccess(
                "all",
                [permissions.ORG_MANAGEMENT_EDIT],
                getPermissionsFromUserRoles(userRoles)
              ) && (
                <Box>
                  <OrganizationEditSaveButton
                    disabled={submitDisabled}
                    variant="contained"
                    type="submit"
                  >
                    {formik.isSubmitting ? (
                      <CircularProgress size={20} />
                    ) : (
                      getTranslation("SAVE_AND_CLOSE", t, i18n)
                    )}
                  </OrganizationEditSaveButton>
                </Box>
              )}
            </Box>

            <Box>
              <Stack sx={{ marginTop: "20px" }} rowGap={TABLET_SPACE}>
                <TextField
                  id="organization-name-input"
                  type="text"
                  label={getTranslation("organizationName", t, i18n)}
                  placeholder={getTranslation("organizationName", t, i18n)}
                  name="name"
                  InputLabelProps={inputLabelProps}
                  required
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <TextField
                  id="organization-nickname-input"
                  inputProps={{
                    "data-testid": "organization-nickname-input",
                  }}
                  type="text"
                  label={getTranslation("ORGANIZATION_NICKNAME", t, i18n)}
                  placeholder={getTranslation("ORGANIZATION_NICKNAME", t, i18n)}
                  name="nickname"
                  InputLabelProps={inputLabelProps}
                  value={formik.values.nickname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.nickname && Boolean(formik.errors.nickname)
                  }
                  helperText={formik.touched.nickname && formik.errors.nickname}
                />

                <TextField
                  inputProps={{
                    "data-testid": "organization-address-line-1-input",
                  }}
                  id="organization-address-line-1-input"
                  type="text"
                  label={getTranslation("ADDRESS_LINE1", t, i18n)}
                  required
                  placeholder={getTranslation("ADDRESS_LINE1", t, i18n)}
                  name="addressLine1"
                  InputLabelProps={inputLabelProps}
                  value={formik.values.addressLine1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.addressLine1 &&
                    Boolean(formik.errors.addressLine1)
                  }
                  helperText={
                    formik.touched.addressLine1 && formik.errors.addressLine1
                  }
                />

                <TextField
                  inputProps={{
                    "data-testid": "organization-address-line-2-input",
                  }}
                  id="organization-address-line-2-input"
                  type="text"
                  label={getTranslation("ADDRESS_LINE2", t, i18n)}
                  placeholder={getTranslation("ADDRESS_LINE2", t, i18n)}
                  name="addressLine2"
                  InputLabelProps={inputLabelProps}
                  value={formik.values.addressLine2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.addressLine2 &&
                    Boolean(formik.errors.addressLine2)
                  }
                  helperText={
                    formik.touched.addressLine2 && formik.errors.addressLine2
                  }
                />

                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      inputProps={{
                        "data-testid": "organization-postal-code-input",
                      }}
                      id="organization-postal-code-input"
                      type="text"
                      label={getTranslation("POSTAL_CODE", t, i18n)}
                      required
                      placeholder={getTranslation("POSTAL_CODE", t, i18n)}
                      name="postcode"
                      InputLabelProps={inputLabelProps}
                      value={formik.values.postcode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.postcode &&
                        Boolean(formik.errors.postcode)
                      }
                      helperText={
                        formik.touched.postcode && formik.errors.postcode
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      inputProps={{
                        "data-testid": "organization-city-input",
                      }}
                      id="organization-city-input"
                      type="text"
                      label={getTranslation("CITY", t, i18n)}
                      required
                      placeholder={getTranslation("CITY", t, i18n)}
                      name="city"
                      InputLabelProps={inputLabelProps}
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                    />
                  </Grid>
                </Grid>

                <TextField
                  inputProps={{ "data-testid": "organization-state-input" }}
                  id="organization-state-input"
                  type="text"
                  label={getTranslation("STATE", t, i18n)}
                  placeholder={getTranslation("STATE", t, i18n)}
                  name="state"
                  InputLabelProps={inputLabelProps}
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                />

                <FormikCountriesAutocomplete
                  name="country"
                  label={getTranslation("COUNTRY", t, i18n)}
                  options={countries ?? []}
                  formik={formik}
                />

                <PhoneInput
                  containerClass={currentTheme}
                  specialLabel={getTranslation("phoneNumber", t, i18n)}
                  inputClass={"phone-input"}
                  inputStyle={{
                    marginTop: "15px",
                    backgroundColor: theme.palette.primary.contrastText,
                    color: theme.palette.primary.main,
                    width: "100%",
                  }}
                  buttonStyle={{ marginTop: "15px" }}
                  dropdownStyle={{
                    backgroundColor: theme.palette.primary.contrastText,
                    color: theme.palette.primary.main,
                  }}
                  id="phone-input"
                  name="contactPhone"
                  placeholder={getTranslation("phoneNumber", t, i18n)}
                  value={formik.values.contactPhone}
                  onChange={handleChangeContactPhone}
                  onBlur={handleBlurContactPhone}
                  isValid={() => isContactPhoneValid}
                  defaultErrorMessage={getTranslation(
                    "phoneNumberValidation",
                    t,
                    i18n
                  )}
                  inputProps={{
                    "data-testid": "organization-phone-input-onboarding",
                  }}
                />

                <TextField
                  id="organization-contact-person-input"
                  type="text"
                  label={getTranslation("contactPerson", t, i18n)}
                  placeholder={getTranslation("contactPerson", t, i18n)}
                  name="contactPerson"
                  required
                  InputLabelProps={inputLabelProps}
                  value={formik.values.contactPerson}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.contactPerson &&
                    Boolean(formik.errors.contactPerson)
                  }
                  helperText={
                    formik.touched.contactPerson && formik.errors.contactPerson
                  }
                />

                <TextField
                  id="organization-contact-email-input"
                  type="text"
                  label={getTranslation("contactEmail", t, i18n)}
                  placeholder={getTranslation("contactEmail", t, i18n)}
                  name="contactEmail"
                  required
                  InputLabelProps={inputLabelProps}
                  value={formik.values.contactEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.contactEmail &&
                    Boolean(formik.errors.contactEmail)
                  }
                  helperText={
                    formik.touched.contactEmail && formik.errors.contactEmail
                  }
                />
              </Stack>
            </Box>
          </form>
        </HomePagePadding>
      </Box>
    </ErrorHandling>
  );
};

export default OrganizationEditMobile;
