import { useCallback, useState } from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useSelector } from "react-redux";
import { StaticHeightDialogContent } from "../../styles/assets/ChooseAssetImagePage.styles";
import { DEFAULT_RESOURCE_IMAGE } from "../../../store/slices/resourceImageSlice";
import { useTranslation } from "react-i18next";
import { getTranslation, showValidationError } from "../../../util/utils";
import { messageError, messageSuccess } from "../../../util/notification";
import { selectPageInfo } from "../../../store/slices/appSlice";
import {
  CancelButton,
  CreateButton,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { selectUser } from "../../../store/slices/authSlice";
import { useUpdateResourceImageMutation } from "../../../store/slices/api/resourceImagesApiSlice";
import ErrorHandling from "../../common/ErrorHandling";
import ResourceImageForm from "./ResourceImageForm";

const EditImageDialog = ({ resourceId, open, setOpen, resourceImage }) => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const { isActionButtonDisabled } = useSelector(selectPageInfo);

  // States
  const [resourceImageInput, setResourceImageInput] = useState(resourceImage);

  const [name, setName] = useState(
    resourceImageInput?.name?.substring(
      0,
      resourceImageInput?.name?.lastIndexOf(".")
    )
  );

  const [extension, setExtension] = useState(
    resourceImageInput?.name?.substring(
      resourceImageInput?.name?.lastIndexOf(".")
    )
  );

  // Mutations
  const [updateResourceImage, { isLoading: isLoadingUpdate }] =
    useUpdateResourceImageMutation();

  // Handlers
  const handleClose = () => {
    setOpen(false);
    setResourceImageInput(DEFAULT_RESOURCE_IMAGE);
  };

  const handleSubmit = useCallback(async () => {
    if (!resourceImageInput.selectedFile) {
      messageError(getTranslation("UPLOAD_IMAGE", t, i18n));
      return;
    }

    try {
      const formData = new FormData();

      const { file, imageCategory, description } = resourceImageInput;

      formData.append("file", file);
      formData.append("fileName", name + extension);
      formData.append("imageCategory", imageCategory);
      formData.append("description", description);

      await updateResourceImage({
        formData,
        resourceid: resourceId,
        organizationId,
        updateImage: Boolean(file),
        resourceImageId: resourceImageInput.id,
      }).unwrap();

      handleClose();

      messageSuccess(
        getTranslation("RESOURCE_IMAGE_UPDATED_SUCCESSFULLY", t, i18n)
      );
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to update resource image", error);
    }

    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceImageInput, name]);

  return (
    <ErrorHandling isLoading={isLoadingUpdate}>
      <Dialog
        id="edit-image-dialog"
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle>{getTranslation("CHOOSE_IMAGE", t, i18n)}</DialogTitle>
        <StaticHeightDialogContent>
          <ResourceImageForm
            resourceImageInput={resourceImageInput}
            setResourceImageInput={setResourceImageInput}
            name={name}
            setName={setName}
            setExtension={setExtension}
          />
        </StaticHeightDialogContent>
        <DialogActions>
          <CancelButton variant="outlined" onClick={handleClose}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelButton>
          <CreateButton
            disabled={isActionButtonDisabled || isLoadingUpdate}
            onClick={handleSubmit}
          >
            {getTranslation("SUBMIT", t, i18n)}
          </CreateButton>
        </DialogActions>
      </Dialog>
    </ErrorHandling>
  );
};

export default EditImageDialog;
