import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { useCreateTypeMutation } from "../../../store/slices/api/typesApiSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  resetForm,
  selectIsFirstSubmitted,
  selectOriginalTypeInput,
  selectTypeInput,
  setIsFirstSubmitted,
  setOriginalTypeInput,
  setTypeInput,
} from "../../../store/slices/typeInputSlice";
import { messageError, messageSuccess } from "../../../util/notification";
import {
  checkTypeChanged,
  transformTypeInputRequest,
  validateTypeForm,
} from "../../../util/type-utils";
import { getTranslation } from "../../../util/utils";
import {
  CancelButton,
  CreateButton,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { DialogPaperProps } from "../../styles/general/General.styles";
import TypeForm from "./TypeForm";
import { setIndex } from "../../../store/slices/typeSearchSlice";
import { DEFAULT_INDEX } from "../../../store/slices/appSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";

const CreateType = ({
  category,
  functionId,
  open,
  setOpen,
  resourceTypeHandler,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const typeInput = useSelector(selectTypeInput);
  const originalInput = useSelector(selectOriginalTypeInput);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const region = user?.region;

  // States
  const [openDiscard, setOpenDiscard] = useState(false);
  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false);

  // Mutations
  const [createType, { isLoading }] = useCreateTypeMutation();

  const alert = {
    title: getTranslation("CANCEL_CREATE", t, i18n),
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  // Handlers
  const handleSubmit = async () => {
    dispatch(setIsFirstSubmitted(true));
    try {
      const { error: evaluatedError, firstError } = validateTypeForm(typeInput);

      const isValid = Object.keys(evaluatedError).length === 0;
      if (!isValid) {
        messageError(getTranslation(firstError, t, i18n));
        setIsActionButtonDisabled(true);
        return;
      }

      const data = await createType({
        typeInput: transformTypeInputRequest(typeInput, region),
        organizationId,
      }).unwrap();

      if (data) {
        dispatch(setIndex(DEFAULT_INDEX));
        if (resourceTypeHandler) {
          resourceTypeHandler(
            data.id,
            typeInput.category,
            typeInput.functionId
          );
        }
      }

      handleClose();
      messageSuccess(getTranslation("successfulCreateType", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedCreateType", t, i18n));
    }
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(resetForm());
    setOpenDiscard(false);
  };

  const handleCancelCreate = () => {
    if (checkTypeChanged(originalInput, typeInput, user.region)) {
      setOpenDiscard(true);
      return;
    }

    handleClose();
  };

  // Effects
  useEffect(() => {
    if (open) {
      const initialTypeInput = {
        ...typeInput,
        functionId,
        category,
      };

      dispatch(setTypeInput(initialTypeInput));
      dispatch(setOriginalTypeInput(initialTypeInput));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!isFirstSubmitted) return;

    const { error: evaluatedError } = validateTypeForm(typeInput);
    const isValid = Object.keys(evaluatedError).length === 0;

    setIsActionButtonDisabled(!isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeInput]);

  const isTypeChanged = useMemo(
    () => checkTypeChanged(originalInput, typeInput, user.region),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [typeInput, originalInput]
  );

  return (
    <>
      <Dialog
        id="create-type-dialog"
        fullWidth={true}
        maxWidth="md"
        PaperProps={DialogPaperProps}
        open={open}
        onClose={handleClose}
        disablePortal
      >
        {tabletMatches && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Button
                sx={{ color: theme.palette.secondary.contrastText }}
                variant="text"
                onClick={handleCancelCreate}
              >
                {getTranslation("CANCEL", t, i18n)}
              </Button>
            </Box>

            <Box>
              <DialogTitle>{getTranslation("NEW_TYPE", t, i18n)}</DialogTitle>
            </Box>

            <Box>
              <Button
                sx={{ color: theme.palette.secondary.contrastText }}
                variant="text"
                disabled={
                  !isTypeChanged ||
                  isLoading ||
                  isActionButtonDisabled ||
                  !typeInput?.name ||
                  !typeInput?.displayId ||
                  !typeInput?.description ||
                  !typeInput?.functionId
                }
                onClick={handleSubmit}
              >
                {getTranslation("CREATE", t, i18n)}
              </Button>
            </Box>
          </Box>
        )}

        {desktopMatches && (
          <DialogTitle>{getTranslation("CREATE_TYPE", t, i18n)}</DialogTitle>
        )}

        <DialogContent>
          <ConfirmAlert
            isOpen={openDiscard}
            setIsOpen={setOpenDiscard}
            alert={alert}
            label="discard-changes"
            handleConfirm={handleClose}
          />
          <TypeForm mode="create" />
        </DialogContent>

        {desktopMatches && (
          <DialogActions>
            <CancelButton variant="outlined" onClick={handleCancelCreate}>
              {getTranslation("CANCEL", t, i18n)}
            </CancelButton>
            <CreateButton
              disabled={
                !isTypeChanged ||
                isLoading ||
                isActionButtonDisabled ||
                !typeInput?.name ||
                !typeInput?.displayId ||
                !typeInput?.description ||
                !typeInput?.functionId
              }
              onClick={handleSubmit}
            >
              {getTranslation("CREATE", t, i18n)}
            </CreateButton>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default CreateType;
