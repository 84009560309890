import { Stack } from "@mui/material";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";

const SingleValueWidgetContent = ({ value }) => {
  return (
    <Stack
      id="single-value-widget"
      sx={{
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <SecondaryText fontWeight="bolder">
        {value && value !== "undefined" ? value : 0}
      </SecondaryText>
    </Stack>
  );
};

export default SingleValueWidgetContent;
