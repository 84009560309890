import { Box, Button, Dialog, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import QueryBuilderMobileView from "./QueryBuilderMobileView";
import { useReportExecutionBaseQuery } from "../../../store/slices/api/reportsApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectQuery,
  selectShouldExecute,
  selectTypeId,
  setQueryDefinitionTablePage,
  setShouldExecute,
} from "../../../store/slices/reportsSlice";
import {
  BASIC_FIELDS,
  checkQueryValidity,
  parseQueryExecute,
} from "../../../util/reports-utils";
import AddConditionDialogHeader from "./query-builder/AddConditionDialogHeader";
import { Transition } from "./MobileCreateReportDialog";
import AbsoluteLoadingSpinner from "../../common/AbsoluteLoadingSpinner";
import QueryDefinitionPreviewTable from "../create-report/QueryDefinitionPreviewTable";
import { useEffect, useState } from "react";
import { messageError } from "../../../util/notification";

const DefineQueryMobileStep = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const query = useSelector(selectQuery);
  const shouldExecute = useSelector(selectShouldExecute);
  const typeId = useSelector(selectTypeId);

  // State
  const [open, setOpen] = useState(false);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const fields = BASIC_FIELDS.map((field, index) => ({ ...field, index }));
  const filters = [parseQueryExecute(query)];
  const isQueryValid = checkQueryValidity(query);

  // Queries
  const {
    data: reportExecutionData,
    isLoading,
    isFetching,
    isError,
  } = useReportExecutionBaseQuery(
    {
      organizationId,
      typeId,
      fields,
      filters,
    },
    { skip: !shouldExecute }
  );

  // Handlers
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setShouldExecute(false));
  };

  const handleLoadPreview = () => {
    dispatch(setShouldExecute(true));
    dispatch(setQueryDefinitionTablePage(0));
    handleOpen();
  };

  useEffect(() => {
    if (isError) {
      messageError("Reporting execution failed");
    }
  }, [isError]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: "bold" }} variant="h5">
            {getTranslation("DEFINE_QUERY", t, i18n)}
          </Typography>
        </Box>

        <Box>
          <Button
            id="open-preview"
            variant="text"
            disabled={!isQueryValid || shouldExecute}
            onClick={handleLoadPreview}
            sx={{
              color: theme.palette.secondary.contrastText,
            }}
          >
            Open preview
          </Button>
        </Box>
      </Box>

      <QueryBuilderMobileView />

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            paddingBlock: "10px",
          }}
        >
          <Box>
            <Box sx={{ paddingInline: "10px" }}>
              <AddConditionDialogHeader
                handleClose={handleClose}
                title="Preview"
                showDone={false}
              />
            </Box>

            <Box sx={{ marginTop: "20px", paddingInline: "10px" }}>
              {isLoading || isFetching ? (
                <AbsoluteLoadingSpinner />
              ) : (
                reportExecutionData?.data &&
                reportExecutionData.data.length > 0 && (
                  <QueryDefinitionPreviewTable
                    rows={reportExecutionData.data}
                  />
                )
              )}
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DefineQueryMobileStep;
