import { Stack } from "@mui/material";
import React from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";

const DoubleValueWidgetContent = ({ value1, value2 }) => {
  return (
    <Stack
      id="double-value-widget"
      sx={{
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <SecondaryText fontWeight="bolder">
        {value1 && value1 !== "undefined" ? value1 : 0}
      </SecondaryText>

      <SecondaryText>
        {value2 && value2 !== "undefined" ? value2 : 0}
      </SecondaryText>
    </Stack>
  );
};

export default DoubleValueWidgetContent;
