import React from "react";
import OrganizationUserAvatarCard from "./OrganizationUserAvatarCard";
import { AvatarGroup, Stack, useTheme } from "@mui/material";

const MAX_GROUP_USERS = 5;

const sortUsers = (array) => {
  return array.sort((a, b) => {
    const firstNameA = a.firstName || "";
    const firstNameB = b.firstName || "";
    const lastNameA = a.lastName || "";
    const lastNameB = b.lastName || "";

    if (firstNameA.toLowerCase() < firstNameB.toLowerCase()) return -1;
    if (firstNameA.toLowerCase() > firstNameB.toLowerCase()) return 1;

    if (lastNameA.toLowerCase() < lastNameB.toLowerCase()) return -1;
    if (lastNameA.toLowerCase() > lastNameB.toLowerCase()) return 1;

    return 0;
  });
};

const OrganizationUserAvatarsList = ({ users, organizationId, total }) => {
  // General hooks
  const theme = useTheme();

  const max = Math.min(users.length, MAX_GROUP_USERS) + 1;

  const sortedUsers = sortUsers(users);

  return (
    <Stack sx={{ alignItems: "start" }}>
      {sortedUsers?.length > 0 && (
        <AvatarGroup
          sx={{
            ".MuiAvatar-root": {
              backgroundColor: theme.palette.secondary.active,
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
            },
            ".MuiAvatarGroup-avatar": {
              border: "none",
            },
          }}
          max={max}
          total={total}
        >
          {sortedUsers.map((u) => (
            <OrganizationUserAvatarCard
              key={u.id}
              user={u}
              organizationId={organizationId}
            />
          ))}
        </AvatarGroup>
      )}
    </Stack>
  );
};

export default OrganizationUserAvatarsList;
