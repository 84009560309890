import { Box, Checkbox, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";
import StatusWidgetContent from "../categorized-widgets/StatusWidgetContent";
import { StyledFormControlLabel } from "../../../styles/general/General.styles";
import {
  constructAggregations,
  getMeasurementUnitForField,
  WIDGET_TYPE,
} from "../../../../util/reports-utils";
import { DragDropContext } from "react-beautiful-dnd";
import WidgetAggregationsList from "../WidgetAggregationsList";
import { useDispatch } from "react-redux";
import {
  addWidget,
  selectResultFields,
  selectWidgetById,
  setWidgetAggregations,
} from "../../../../store/slices/reportsSlice";
import { useSelector } from "react-redux";
import {
  WidgetContainer,
  WidgetTitle,
  WidgetWrapper,
} from "../../../styles/reports/ChartWidgets.styles";
import React from "react";
import { measurementDisplay } from "../../../../util/utils";
import { selectUser } from "../../../../store/slices/authSlice";

const StatusWidgetForm = ({ rows }) => {
  // General hooks
  const dispatch = useDispatch();

  // Selectors
  const user = useSelector(selectUser);
  const resultFields = useSelector(selectResultFields);
  const widget = useSelector((state) =>
    selectWidgetById(state, WIDGET_TYPE.STATUS)
  );

  // Other variables
  const finalAggregations = constructAggregations(resultFields);

  // States
  const [title, setTitle] = useState(widget?.title);
  const [displayHome, setDisplayHome] = useState(false);

  // Other variables
  const dataSources = widget?.dataSources ?? [];

  const aggregationIds =
    dataSources?.map((ds) => ds.generatedAggregationId) ?? [];

  const dangerAggregationId =
    aggregationIds?.length > 0 ? aggregationIds[0] : "";

  const warningAggregationId =
    aggregationIds?.length > 1 ? aggregationIds[1] : "";

  const successAggregationId =
    aggregationIds?.length > 2 ? aggregationIds[2] : "";

  const dangerAggregation = finalAggregations?.find(
    (a) => a.id === dangerAggregationId
  );

  const dangerKey =
    dangerAggregation?.field?.name +
    `.aggregation-${dangerAggregation?.aggregationType?.toLowerCase()}`;

  const dangerRow = rows?.length > 0 ? rows[0] : {};
  const dangerAggregationValue = dangerRow[dangerKey] ?? 0;

  const warningAggregation = finalAggregations?.find(
    (a) => a.id === warningAggregationId
  );

  const warningKey =
    warningAggregation?.field?.name +
    `.aggregation-${warningAggregation?.aggregationType?.toLowerCase()}`;

  const warningRow = rows?.length > 0 ? rows[0] : {};
  const warningAggregationValue = warningRow[warningKey] ?? 0;

  const successAggregation = finalAggregations?.find(
    (a) => a.id === successAggregationId
  );

  const successKey =
    successAggregation?.field?.name +
    `.aggregation-${successAggregation?.aggregationType?.toLowerCase()}`;

  const successRow = rows?.length > 0 ? rows[0] : {};
  const successAggregationValue = successRow[successKey] ?? 0;

  const { unit: unitDanger, value: convertedValueDanger } = measurementDisplay({
    value: dangerAggregationValue,
    unit: getMeasurementUnitForField(
      dangerAggregation?.field?.name,
      dangerAggregation?.aggregationType
    ),
    region: user?.region,
  });

  const unitDangerLabel = unitDanger ? ` (in ${unitDanger})` : "";
  const finalDangerValue = convertedValueDanger + unitDangerLabel;

  const { unit: unitWarning, value: convertedValueWarning } =
    measurementDisplay({
      value: warningAggregationValue,
      unit: getMeasurementUnitForField(
        warningAggregation?.field?.name,
        warningAggregation?.aggregationType
      ),
      region: user?.region,
    });

  const unitWarningLabel = unitWarning ? ` (in ${unitWarning})` : "";
  const finalWarningValue = convertedValueWarning + unitWarningLabel;

  const { unit: unitSuccess, value: convertedValueSuccess } =
    measurementDisplay({
      value: successAggregationValue,
      unit: getMeasurementUnitForField(
        successAggregation?.field?.name,
        successAggregation?.aggregationType
      ),
      region: user?.region,
    });

  const unitSuccessLabel = unitSuccess ? ` (in ${unitSuccess})` : "";
  const finalSuccessValue = convertedValueSuccess + unitSuccessLabel;

  // Handlers
  const handleChangeTitle = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    dispatch(addWidget({ ...widget, title: newTitle }));
  };

  const handleChangeDisplayHome = (e) => {
    const newDisplayHome = e.target.checked;
    setDisplayHome(newDisplayHome);
  };

  const handleRearangeArr = (arr, sourceIndex, destIndex) => {
    const arrCopy = [...arr];
    const [removed] = arrCopy.splice(sourceIndex, 1);
    arrCopy.splice(destIndex, 0, removed);

    return arrCopy;
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const items = handleRearangeArr(
      widget?.dataSources,
      source.index,
      destination.index
    );

    dispatch(
      setWidgetAggregations({
        widgetTypeId: WIDGET_TYPE.STATUS,
        items,
      })
    );
  };

  return (
    <Stack spacing={3}>
      <Box>
        <SecondaryText gutterBottom>Configure Status</SecondaryText>
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            label="Title"
            id="title"
            value={title}
            size="small"
            onChange={handleChangeTitle}
          />
        </Box>
      </Box>

      <DragDropContext onDragEnd={handleDragEnd}>
        <WidgetAggregationsList widgetTypeId={WIDGET_TYPE.STATUS} />
      </DragDropContext>

      <Box>
        <SecondaryText gutterBottom>Preview</SecondaryText>
        <WidgetWrapper>
          <WidgetContainer>
            <WidgetTitle variant="body2" textAlign="center">
              {title}
            </WidgetTitle>
            <StatusWidgetContent
              dangerCount={finalDangerValue}
              warningCount={finalWarningValue}
              successCount={finalSuccessValue}
            />
          </WidgetContainer>
        </WidgetWrapper>
      </Box>
    </Stack>
  );
};

export default StatusWidgetForm;
