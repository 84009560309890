import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  alpha,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { getSvgIcon } from "../../util/icons";
import {
  USER_STATUS,
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  showValidationError,
} from "../../util/utils";
import {
  useInviteUserMutation,
  useRemoveInvitationMutation,
  useUpdateInvitationRoleMutation,
  useUpdateUserRolePermissionsMutation,
  useUserRolePermissionsQuery,
} from "../../store/slices/api/userManagementSlice";
import {
  useActivateUserMutation,
  useGetOrganizationRolesQuery,
  useGetOrganizationUsersQuery,
  useRemoveUserFromOrganizationMutation,
} from "../../store/slices/api/organizationsApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../../store/slices/authSlice";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import SendIcon from "@mui/icons-material/Send";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useTranslation } from "react-i18next";
import { messageError, messageSuccess } from "../../util/notification";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import { useGetCurrentSubscriptionActualUsageQuery } from "../../store/slices/api/subscriptionsApiSlice";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";

const OrganizationUserOptionsDesktop = ({ organization, user }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(organization);

  // Selectors
  const currentUser = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateRoleAnchorEl, setUpdateRoleAnchorEl] = useState(null);
  const [openHandoverOwnership, setOpenHandoverOwnership] = useState(false);

  // Queries
  const { data: currentUserRoles } = useUserRolePermissionsQuery(
    {
      organizationId: organization?.id,
      userId: currentUser.id,
    },
    { skip: !organization }
  );

  const isOrganizationRestricted = hasAccess(
    "all",
    [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
    getPermissionsFromUserRoles(currentUserRoles)
  )
    ? false
    : isRestricted;

  const { data: roles } = useGetOrganizationRolesQuery(organization?.id, {
    skip: !organization || isOrganizationRestricted,
  });

  const { data: organizationUsers } = useGetOrganizationUsersQuery(
    organization?.id,
    {
      skip:
        !organization ||
        isOrganizationRestricted ||
        !hasAccess(
          "all",
          [permissions.USER_MANAGEMENT_VIEW],
          getPermissionsFromUserRoles(currentUserRoles)
        ),
    }
  );

  const { data: actualUsage } = useGetCurrentSubscriptionActualUsageQuery(
    {
      organizationId: organization?.id,
    },
    {
      skip: isRestricted,
    }
  );

  // Mutations
  const [updateUserRolePermissions] = useUpdateUserRolePermissionsMutation();

  const [removeUserFromOrganization] = useRemoveUserFromOrganizationMutation();

  const [activateUser] = useActivateUserMutation();

  const [removeInvitation] = useRemoveInvitationMutation();

  const [inviteUser] = useInviteUserMutation();

  const [updateInvitationRole] = useUpdateInvitationRoleMutation();

  // Handlers
  const handleOpenOptionsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptionsMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenUpdateRoleMenu = (event) => {
    setUpdateRoleAnchorEl(event.currentTarget);
  };

  const handleCloseUpdateRoleMenu = () => {
    setUpdateRoleAnchorEl(null);
  };

  const handleOpenHandoverOwnership = () => {
    setOpenHandoverOwnership(true);
  };

  const handleConfirm = () => {
    handleUpdateUserRolePermissions("OWNER");
    handleCloseUpdateRoleMenu();
  };

  const handleUpdateUserRolePermissions = async (roleName) => {
    const role = roles?.find((r) => r.name === roleName);

    try {
      await updateUserRolePermissions({
        userId: user.id,
        add: [role.id],
        organizationId: organization?.id,
        remove: user?.roles?.filter((r) => r.id !== role.id)?.map((r) => r.id),
      }).unwrap();

      if (roleName === "OWNER") {
        const newOrganizations = currentUser?.organizations?.map((org) => {
          return org.id === organization?.id ? { ...org, owner: false } : org;
        });

        dispatch(
          setUser({
            ...currentUser,
            organizations: newOrganizations,
          })
        );
      }

      messageSuccess(
        getTranslation("successfulUpdateUserRolePermissions", t, i18n)
      );
    } catch (error) {
      messageError(getTranslation("failedUpdateUserRolePermissions", t, i18n));
    }
  };

  const handleUpdateInvitationRoles = async (roleId) => {
    try {
      await updateInvitationRole({
        invitationId: user.invitationId,
        organizationId: organization?.id,
        roleId,
      }).unwrap();

      messageSuccess(getTranslation("successfulUpdateInvitationRole", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedUpdateInvitationRole", t, i18n));
    }
  };

  const handleRemoveUserFromOrganization = async () => {
    try {
      await removeUserFromOrganization({
        organizationId: organization?.id,
        userId: user.id,
      }).unwrap();

      messageSuccess(
        getTranslation("successfulRemoveUserFromOrganization", t, i18n)
      );
    } catch (error) {
      messageError(getTranslation("failedRemoveUserFromOrganization", t, i18n));
    }
  };

  const handleRemoveInvitation = async () => {
    try {
      await removeInvitation({
        organizationId: organization?.id,
        invitationId: user.invitationId,
      });

      messageSuccess(getTranslation("successfulRemoveInvitation", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedRemoveInvitation", t, i18n));
    }
  };

  const handleActivateUser = async () => {
    try {
      await activateUser({
        organizationId: organization?.id,
        userId: user.id,
      }).unwrap();

      messageSuccess(getTranslation("USER_ACTIVATED", t, i18n));
    } catch (error) {
      showValidationError(error, t, i18n);
    }
  };

  const handleResendInvitation = async () => {
    const { email, roles } = user;

    const userData = {
      email,
      roles: roles?.map((r) => r.id),
    };

    const invitationData = [userData];

    try {
      await inviteUser({
        invitationData,
        organizationId: organization?.id,
      }).unwrap();
      messageSuccess(getTranslation("successfulResendInvitation", t, i18n));
    } catch (error) {
      showValidationError(error, t, i18n);
    }
  };

  // Other variables
  const alert = {
    content: getTranslation("CHANGE_OWNERSHIP", t, i18n),
    confirmTitle: getTranslation("HAND_OVER_OWNERSHIP", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };
  const iconSize = globalFontSize;
  const openMenu = Boolean(anchorEl);
  const openUpdateRoleMenu = Boolean(updateRoleAnchorEl);
  const isUserActive = user.status === USER_STATUS.ACTIVE;

  // Permission variables
  const userHasOwnerRole = user?.roles?.some((r) => r.name === "OWNER");
  const currentUserHasOwnerRole = currentUserRoles?.some(
    (r) => r.name === "OWNER"
  );

  const hasSubscription = Boolean(organization?.subscription);
  const isAllowedChangeRole =
    hasSubscription &&
    hasAccess(
      "all",
      [permissions.USER_MANAGEMENT_ADD],
      getPermissionsFromUserRoles(currentUserRoles)
    ) &&
    !userHasOwnerRole &&
    currentUser.id !== user.id;

  // Subscription variables
  const usersCurrentValue = organizationUsers?.length ?? 0;
  const facts = actualUsage?.facts;
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue);

  // List variables
  const leftRoles = roles?.filter(
    (r) => !user?.roles?.some((ur) => ur.name === r.name)
  );

  const leftRolesExceptOwner = roles?.filter(
    (r) => !user?.roles?.some((ur) => ur.name === r.name) && r.name !== "OWNER"
  );

  return (
    <>
      <ConfirmAlert
        isOpen={openHandoverOwnership}
        setIsOpen={setOpenHandoverOwnership}
        alert={alert}
        label="handover-ownership"
        handleConfirm={handleConfirm}
      />
      <IconButton
        onClick={isAllowedChangeRole ? handleOpenOptionsMenu : () => {}}
        sx={{ visibility: isAllowedChangeRole ? "visible" : "hidden" }}
      >
        {getSvgIcon(
          "MORE",
          iconSize,
          iconSize,
          !isUserActive
            ? alpha(theme.palette.primary.main, 0.4)
            : theme.palette.secondary.dark
        )}
      </IconButton>
      <Menu
        slotProps={{
          paper: {
            elevation: 1,
            sx: {
              borderRadius: "8px",
            },
          },
        }}
        aria-labelledby="more-icon"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id="menu-options"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseOptionsMenu}
      >
        {usersCurrentValue <= usersSubscriptionValue && (
          <>
            {!isUserActive && (
              <MenuItem
                onClick={() => {
                  handleCloseOptionsMenu();
                  handleResendInvitation();
                }}
              >
                <ListItemIcon>
                  <SendIcon
                    sx={{
                      color: theme.palette.secondary.contrastText,
                      width: iconSize,
                      height: iconSize,
                    }}
                  />
                </ListItemIcon>
                <ListItemText>
                  {getTranslation("RESENDINVITATION", t, i18n)}
                </ListItemText>
              </MenuItem>
            )}
            {user.restricted &&
              hasAccess(
                "all",
                [permissions.USER_MANAGEMENT_EDIT],
                getPermissionsFromUserRoles(currentUserRoles)
              ) && (
                <MenuItem
                  id="activate-member"
                  onClick={() => {
                    handleCloseOptionsMenu();
                    handleActivateUser();
                  }}
                >
                  <ListItemIcon>
                    <PowerSettingsNewIcon
                      sx={{
                        color: theme.palette.error.main,
                        width: iconSize,
                        height: iconSize,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ color: theme.palette.error.main }}>
                    {getTranslation("ACTIVATE", t, i18n)}
                  </ListItemText>
                </MenuItem>
              )}
            <MenuItem onClick={handleOpenUpdateRoleMenu} id="edit-role">
              <ListItemIcon>
                {getSvgIcon(
                  "EDIT",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </ListItemIcon>
              <ListItemText>
                {getTranslation("CHANGE_ROLE", t, i18n)}
              </ListItemText>
            </MenuItem>
          </>
        )}

        {hasAccess(
          "all",
          [permissions.USER_MANAGEMENT_DELETE],
          getPermissionsFromUserRoles(currentUserRoles)
        ) && (
          <MenuItem
            onClick={() => {
              handleCloseOptionsMenu();
              if (isUserActive) {
                handleRemoveUserFromOrganization();
              } else {
                handleRemoveInvitation();
              }
            }}
          >
            <ListItemIcon>
              {getSvgIcon(
                "DELETE",
                iconSize,
                iconSize,
                theme.palette.error.main
              )}
            </ListItemIcon>
            <ListItemText sx={{ color: theme.palette.error.main }}>
              {getTranslation("REMOVE_FROM_ORGANIZATION", t, i18n)}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
      <Menu
        slotProps={{
          paper: {
            elevation: 1,
            sx: {
              borderRadius: "8px",
            },
          },
        }}
        aria-labelledby="more-icon"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id="menu-options"
        anchorEl={updateRoleAnchorEl}
        open={openUpdateRoleMenu}
        onClose={handleCloseUpdateRoleMenu}
      >
        {currentUserHasOwnerRole && isUserActive
          ? leftRoles?.map((r) => (
              <MenuItem
                id="change-role"
                onClick={
                  r.name === "OWNER"
                    ? handleOpenHandoverOwnership
                    : () => {
                        handleCloseUpdateRoleMenu();
                        if (isUserActive) {
                          handleUpdateUserRolePermissions(r.name);
                        } else {
                          handleUpdateInvitationRoles(r.id);
                        }
                      }
                }
              >
                {getTranslation("CHANGETO" + r.name, t, i18n)}
              </MenuItem>
            ))
          : leftRolesExceptOwner?.map((r) => (
              <MenuItem
                id="change-role"
                onClick={() => {
                  handleCloseUpdateRoleMenu();
                  if (isUserActive) {
                    handleUpdateUserRolePermissions(r.name);
                  } else {
                    handleUpdateInvitationRoles(r.id);
                  }
                }}
              >
                {getTranslation("CHANGETO" + r.name, t, i18n)}
              </MenuItem>
            ))}
      </Menu>
    </>
  );
};

export default OrganizationUserOptionsDesktop;
