import { Box, CircularProgress, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ReportsWizardTimeline from "../../components/reports/create-report/ReportsWizardTimeline";
import DefineQueryWizardStep from "../../components/reports/create-report/DefineQueryWizardStep";
import FormatResultWizardStep from "../../components/reports/create-report/FormatResultWizardStep";
import AddWidgetWizardStep from "../../components/reports/create-report/AddWidgetWizardStep";
import BasicDataWizardStep from "../../components/reports/create-report/BasicDataWizardStep";
import {
  MIN_STEP,
  nextWizardStep,
  previousWizardStep,
  resetWidgets,
  resetWizard,
  selectDescription,
  selectIsPrivate,
  selectName,
  selectQuery,
  selectReportDefinitionId,
  selectReportingFields,
  selectResultFields,
  selectTypeId,
  selectWidgets,
  selectWizardStep,
  setReportDefinitionAggregations,
  setReportDefinitionId,
  setReportingFields,
} from "../../store/slices/reportsSlice";
import {
  useCreateReportDefinitionFilterMutation,
  useCreateReportDefinitionMutation,
  useCreateReportDefinitionWidgetsMutation,
  useGetReportDefinitionFiltersQuery,
  useReportingFieldsByTypeQuery,
  useUpdateReportDefinitionAggregationsMutation,
  useUpdateReportDefinitionFieldsMutation,
  useUpdateReportDefinitionFilterMutation,
  useUpdateReportDefinitionMutation,
} from "../../store/slices/api/reportsApiSlice";
import { selectUser } from "../../store/slices/authSlice";
import { useEffect, useState } from "react";
import ErrorHandling from "../../components/common/ErrorHandling";
import {
  addPropertyToChildren,
  checkQueryValidity,
  compatibilityCheck,
  parseQuery,
} from "../../util/reports-utils";
import { getTranslation } from "../../util/utils";
import { useTranslation } from "react-i18next";
import { messageError } from "../../util/notification";
import {
  CreateReportTypography,
  CreateReportWrapper,
  ReportWizardTimeLineContainer,
  ReportsWizardBackButton,
  ReportsWizardContinueButton,
  ReportsWizardGridContainer,
  ReportsWizardRightSectionGridContainer,
  SaveAndContinueWrapper,
} from "../../components/styles/reports/Reports.styles";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";

const ReportsWizard = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const wizardStep = useSelector(selectWizardStep);
  const typeId = useSelector(selectTypeId);
  const name = useSelector(selectName);
  const description = useSelector(selectDescription);
  const isPrivate = useSelector(selectIsPrivate);
  const reportingFields = useSelector(selectReportingFields);
  const resultFields = useSelector(selectResultFields);
  const query = useSelector(selectQuery);
  const reportDefinitionId = useSelector(selectReportDefinitionId);
  const widgets = useSelector(selectWidgets);

  // States
  const [openAlertWidgets, setOpenAlertWidgets] = useState(false);
  const [openAlertAggregations, setOpenAlertAggregations] = useState(false);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  const filters = [parseQuery(query, reportingFields)];
  const isQueryValid = checkQueryValidity(query);
  const continueButtonDisabled =
    !name || resultFields?.some((field) => !field.displayName);

  const alert = {
    content: getTranslation("WIDGETS_WARNING", t, i18n),
    confirmTitle: getTranslation("CONFIRM_WIDGETS_WARNING", t, i18n),
    closeTitle: getTranslation("CANCEL_WIDGETS_WARNING", t, i18n),
    showConfirm: true,
  };

  const alertAggregations = {
    content: getTranslation("AGGREGATIONS_WARNING", t, i18n),
    confirmTitle: getTranslation("CONFIRM_AGGREGATIONS_WARNING", t, i18n),
    closeTitle: getTranslation("CANCEL_AGGREGATIONS_WARNING", t, i18n),
    showConfirm: true,
  };

  // Queries
  const { data: reportingFieldsData, isLoading: isLoadingReportingFields } =
    useReportingFieldsByTypeQuery({
      organizationId,
      typeId,
    });

  const { data: reportDefinitionFilterData } =
    useGetReportDefinitionFiltersQuery(
      {
        organizationId,
        reportDefinitionId: reportDefinitionId,
      },
      {
        skip: !reportDefinitionId,
      }
    );

  // Mutations
  const [
    createReportDefinition,
    { isLoading: isLoadingCreateReportDefinition },
  ] = useCreateReportDefinitionMutation();
  const [
    createReportDefinitionWidgets,
    { isLoading: isLoadingCreateReportDefinitionWidgets },
  ] = useCreateReportDefinitionWidgetsMutation();
  const [
    createReportDefinitionFilter,
    { isLoading: isLoadingCreateReportDefinitionFilter },
  ] = useCreateReportDefinitionFilterMutation();

  const [
    updateReportDefinition,
    { isLoading: isLoadingUpdateReportDefinition },
  ] = useUpdateReportDefinitionMutation();
  const [
    updateReportDefinitionFilter,
    { isLoading: isLoadingUpdateReportDefinitionFilter },
  ] = useUpdateReportDefinitionFilterMutation();
  const [
    updateReportDefinitionFields,
    { isLoading: isLoadingUpdateReportDefinitionFields },
  ] = useUpdateReportDefinitionFieldsMutation();
  const [
    updateReportDefinitionAggregations,
    { isLoading: isLoadingUpdateReportDefinitionAggregations },
  ] = useUpdateReportDefinitionAggregationsMutation();

  // Handlers
  const getFormatResultData = () => {
    let resultReportDefinitionFields = [];
    let resultReportDefinitionAggregations = [];

    for (let i = 0; i < resultFields.length; i++) {
      const {
        field,
        usedForGrouping,
        aggregationTypes,
        orderIndex,
        sortMethod,
        displayName,
      } = resultFields[i];

      const reportingField = reportingFields.find(
        (reportingField) => reportingField.name === field
      );

      if (!reportingField) {
        continue;
      }

      const { id: reportFieldId } = reportingField;

      const existingReportDefinitionField = resultReportDefinitionFields.find(
        (f) => f.fieldId === reportFieldId
      );

      if (!Boolean(existingReportDefinitionField)) {
        const reportDefinitionField = {
          fieldId: reportFieldId,
          usedForGrouping,
          orderIndex,
          sortMethod,
          index: i,
          displayName,
        };

        aggregationTypes.forEach((aggregationType) => {
          resultReportDefinitionAggregations.push({
            fieldId: reportFieldId,
            aggregationType,
          });
        });

        resultReportDefinitionFields.push(reportDefinitionField);
      } else {
        if (usedForGrouping) {
          existingReportDefinitionField.usedForGrouping = usedForGrouping;
        }
      }
    }

    return {
      resultReportDefinitionAggregations,
      resultReportDefinitionFields,
    };
  };

  const handleMoveBack = () => dispatch(previousWizardStep());

  const handleMoveNext = async () => {
    try {
      if (wizardStep === 1) {
        const reportDefinition = {
          reportTypeId: typeId,
          name,
          description,
          userId: isPrivate ? user.id : null,
        };

        if (reportDefinitionId) {
          await updateReportDefinition({
            organizationId,
            reportDefinitionId,
            reportDefinition,
          }).unwrap();
        } else {
          const response = await createReportDefinition({
            organizationId,
            reportDefinition,
          }).unwrap();

          if (!response) {
            return;
          }

          const { id: reportDefinitionId } = response;
          dispatch(setReportDefinitionId(reportDefinitionId));
        }
      } else if (wizardStep === 2) {
        const modifiedFilters = addPropertyToChildren(
          filters,
          "reportDefinitionId",
          reportDefinitionId
        );
        for (let i = 0; i < modifiedFilters.length; i++) {
          const { logicalOperator, conditions, children } = modifiedFilters[i];
          const reportDefinitionFilter = {
            logicalOperator,
            conditions,
            children,
          };

          if (reportDefinitionFilterData.length >= 1) {
            await updateReportDefinitionFilter({
              organizationId,
              reportDefinitionId,
              reportDefinitionFilterId: reportDefinitionFilterData[i].id,
              reportDefinitionFilter,
            }).unwrap();
          } else {
            await createReportDefinitionFilter({
              organizationId,
              reportDefinitionId,
              reportDefinitionFilter,
            }).unwrap();
          }
        }
      } else if (wizardStep === 3) {
        const {
          resultReportDefinitionFields,
          resultReportDefinitionAggregations,
        } = getFormatResultData();

        if (
          !resultFields?.some((field) => field?.aggregationTypes?.length > 0)
        ) {
          handleOpenAlertAggregations();
          return;
        }

        const hasWidgets = widgets?.length > 0;

        if (hasWidgets) {
          // Check by groups
          const hasGroupFields =
            resultFields.filter((field) => field.usedForGrouping).length > 0;

          let incompatibility = widgets.some((w) => {
            return !compatibilityCheck(w.widgetTypeId, hasGroupFields);
          });

          // check some of the aggregations in data source are not available in new aggregations
          const hasAggregations = widgets.some((widget) => {
            return widget.dataSources.length > 0;
          });

          const dependentWidgets = incompatibility || hasAggregations;

          // Check by aggregations
          if (dependentWidgets) {
            handleOpenAlertWidgets();
            return;
          }
        }

        if (resultReportDefinitionFields.length > 0) {
          await updateReportDefinitionFields({
            organizationId,
            reportDefinitionId,
            reportDefinitionFields: resultReportDefinitionFields,
          }).unwrap();
        }

        if (resultReportDefinitionAggregations.length > 0) {
          const response = await updateReportDefinitionAggregations({
            organizationId,
            reportDefinitionId,
            reportDefinitionAggregations: resultReportDefinitionAggregations,
          }).unwrap();

          dispatch(setReportDefinitionAggregations(response));
        }
      } else if (wizardStep === 4) {
        const widgetsPayload = widgets?.map((widget) => {
          const { title, widgetTypeId, dataSources } = widget;

          return {
            title,
            widgetTypeId,
            dataSources: dataSources?.map((ds, index) => {
              const { reportDefinitionAggregationId, color, paletteId } = ds;

              return {
                reportDefinitionAggregationId,
                index,
                color: color ?? "#FFC1CC",
                paletteId: paletteId ?? 1,
              };
            }),
          };
        });

        await createReportDefinitionWidgets({
          organizationId,
          reportDefinitionId,
          widgets: widgetsPayload,
        }).unwrap();

        dispatch(resetWizard());
        return;
      }

      dispatch(nextWizardStep());
    } catch (error) {
      console.error("Error moving next", error);
      messageError(error?.data?.message);
    }
  };

  const handleOpenAlertWidgets = () => {
    setOpenAlertWidgets(true);
  };

  const handleOpenAlertAggregations = () => {
    setOpenAlertAggregations(true);
  };

  const handleConfirm = async () => {
    try {
      const {
        resultReportDefinitionFields,
        resultReportDefinitionAggregations,
      } = getFormatResultData();

      if (resultReportDefinitionFields.length > 0) {
        await updateReportDefinitionFields({
          organizationId,
          reportDefinitionId,
          reportDefinitionFields: resultReportDefinitionFields,
        }).unwrap();
      }

      if (resultReportDefinitionAggregations.length > 0) {
        const response = await updateReportDefinitionAggregations({
          organizationId,
          reportDefinitionId,
          reportDefinitionAggregations: resultReportDefinitionAggregations,
        }).unwrap();

        dispatch(setReportDefinitionAggregations(response));
      }

      dispatch(resetWidgets());
      dispatch(nextWizardStep());
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmAggregations = async () => {
    try {
      const {
        resultReportDefinitionFields,
        resultReportDefinitionAggregations,
      } = getFormatResultData();

      if (resultReportDefinitionFields.length > 0) {
        await updateReportDefinitionFields({
          organizationId,
          reportDefinitionId,
          reportDefinitionFields: resultReportDefinitionFields,
        }).unwrap();
      }

      if (resultReportDefinitionAggregations.length > 0) {
        const response = await updateReportDefinitionAggregations({
          organizationId,
          reportDefinitionId,
          reportDefinitionAggregations: resultReportDefinitionAggregations,
        }).unwrap();

        dispatch(setReportDefinitionAggregations(response));
      }

      dispatch(resetWizard());
    } catch (error) {
      console.log(error);
    }
  };

  // Renderers
  const renderWizardContent = () => {
    if (wizardStep === 1) {
      return <BasicDataWizardStep showPrivate={true} />;
    } else if (wizardStep === 2) {
      return <DefineQueryWizardStep />;
    } else if (wizardStep === 3) {
      return <FormatResultWizardStep />;
    } else if (wizardStep === 4) {
      return <AddWidgetWizardStep />;
    }

    return <p>Invalid wizard step</p>;
  };

  // Effects
  useEffect(() => {
    if (reportingFieldsData && reportingFieldsData.length > 0) {
      const formattedReportingFields = reportingFieldsData.map((field) => {
        return {
          id: field.id,
          name: field.name,
          label: field.name,
        };
      });

      dispatch(setReportingFields(formattedReportingFields));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportingFieldsData]);

  const isLoading =
    isLoadingCreateReportDefinition ||
    isLoadingCreateReportDefinitionWidgets ||
    isLoadingCreateReportDefinitionFilter ||
    isLoadingUpdateReportDefinition ||
    isLoadingCreateReportDefinitionFilter ||
    isLoadingUpdateReportDefinitionFilter ||
    isLoadingUpdateReportDefinitionFields ||
    isLoadingUpdateReportDefinitionAggregations;

  return (
    <ErrorHandling isLoading={isLoadingReportingFields} isError={false}>
      <ConfirmAlert
        isOpen={openAlertWidgets}
        setIsOpen={setOpenAlertWidgets}
        alert={alert}
        label="loose-widgets"
        handleConfirm={handleConfirm}
      />

      <ConfirmAlert
        isOpen={openAlertAggregations}
        setIsOpen={setOpenAlertAggregations}
        alert={alertAggregations}
        label="aggregations-alert"
        handleConfirm={handleConfirmAggregations}
      />

      <Box>
        <ReportsWizardGridContainer container>
          <Grid item xs={10}>
            <CreateReportWrapper>
              <CreateReportTypography variant="h5">
                {getTranslation("CREATE_REPORT", t, i18n)}
              </CreateReportTypography>
              {renderWizardContent()}
            </CreateReportWrapper>
          </Grid>

          <ReportsWizardRightSectionGridContainer item xs={2}>
            <ReportWizardTimeLineContainer>
              <Box>
                <ReportsWizardTimeline />
              </Box>

              <SaveAndContinueWrapper>
                {wizardStep > MIN_STEP && (
                  <ReportsWizardBackButton
                    id="back"
                    disabled={wizardStep <= MIN_STEP}
                    onClick={handleMoveBack}
                    variant="outlined"
                  >
                    {getTranslation("back", t, i18n)}
                  </ReportsWizardBackButton>
                )}

                <ReportsWizardContinueButton
                  id="save"
                  disabled={
                    (!isQueryValid && wizardStep === 2) ||
                    continueButtonDisabled ||
                    isLoading
                  }
                  onClick={handleMoveNext}
                  variant="contained"
                  endIcon={isLoading && <CircularProgress size="20px" />}
                >
                  {getTranslation("SAVE_AND_CONTINUE", t, i18n)}
                </ReportsWizardContinueButton>
              </SaveAndContinueWrapper>
            </ReportWizardTimeLineContainer>
          </ReportsWizardRightSectionGridContainer>
        </ReportsWizardGridContainer>
      </Box>
    </ErrorHandling>
  );
};

export default ReportsWizard;
