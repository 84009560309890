import { Stack, styled } from "@mui/system";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { SecondaryText } from "../assets/ListInlineView.styles";
import { Paper } from "@mui/material";

export const BarStack = styled(Stack)({
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "center",
});

export const StyledBar = styled(Bar)({
  maxHeight: "100px",
});

export const AreaStack = styled(Stack)({
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "center",
});

export const LineStack = styled(Stack)({
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "center",
});

export const StyledLine = styled(Line)({
  maxHeight: "100px",
});

export const DoughnutStack = styled(Stack)({
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "center",
});

export const StyledDoughnut = styled(Doughnut)({
  maxHeight: "100px",
});

export const PieStack = styled(Stack)({
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "center",
});

export const StyledPie = styled(Pie)({
  maxHeight: "100px",
});

export const WidgetTitle = styled(SecondaryText)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  lineClamp: 2,
  WebkitBoxOrient: "vertical",
  whiteSpace: "break-spaces",
  wordBreak: "break-all",
});

export const WidgetContainer = styled(Stack)({
  height: "100%",
  padding: "8px",
});

export const WidgetWrapper = styled(Paper)(({ theme }) => ({
  height: "180px",
  backgroundColor: theme.palette.secondary.active,
  position: "relative",
}));
