import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { selectUser } from "../../store/slices/authSlice";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  localizeDate,
  permissions,
  transitionDirections,
} from "../../util/utils";
import PageTransition from "../../components/PageTransition";
import {
  useGetOrganizationByIdQuery,
  useGetOrganizationDataStorageLocationQuery,
} from "../../store/slices/api/organizationsApiSlice";
import ErrorHandling from "../../components/common/ErrorHandling";
import { SecondaryContrastButton } from "../../components/styles/general/General.styles";
import { useGetAddressesByOrganizationIdQuery } from "../../store/slices/api/addressesApiSlice";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import { useGetCurrentSubscriptionActualUsageQuery } from "../../store/slices/api/subscriptionsApiSlice";
import AppAccess from "../../components/common/AppAccess";
import { getSvgIcon } from "../../util/icons";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import Layer2Alerts from "../../components/Layer2Alerts";
import { PrimaryText } from "../../components/styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import Access from "../../components/common/Access";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import { OrganizationDesktopCardWrapper } from "../../components/styles/profile/ProfileDesktop.styles";
import OrganizationHeaderDesktop from "../../components/profile/OrganizationHeaderDesktop";
import OrganizationUsersCard from "../../components/organizations/OrganizationUsersCard";

const OrganizationDesktopDetails = () => {
  // Global hooks
  const { t, i18n } = useTranslation();
  const { organizationId } = useParams();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    organizationId,
    userId: user.id,
  });

  const {
    data: organizationData,
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization,
  } = useGetOrganizationByIdQuery(organizationId);

  // Other variables
  const org = user?.organizations?.find((o) => o.id === organizationId);

  // Custom hooks
  const { isLoading, isRestricted, subscription, canFetchCurrentSubscription } =
    useCheckOrganizationRestricted(org);

  // Other variables
  const orgDisabled = hasAccess(
    "all",
    [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
    getPermissionsFromUserRoles(userRoles)
  )
    ? false
    : isRestricted;

  // Queries
  const { data: actualUsage, isLoading: isLoadingActualUsage } =
    useGetCurrentSubscriptionActualUsageQuery(
      {
        organizationId: org?.id,
      },
      {
        skip: isRestricted,
      }
    );

  // Handlers
  const handleNavigateToEdit = () =>
    navigate(
      `/profile/organization/${organizationId}/edit?direction=${transitionDirections.LEFT_TO_RIGHT}`
    );

  // Other variables
  const facts = actualUsage?.facts;
  const assetsUsage = facts?.find((f) => f.name === "ASSET_COUNT");
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const assetsSubscriptionValue = Number(assetsUsage?.subscriptionValue);
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue);
  const editIconSize = globalFontSize * 1.2;
  const iconSize = globalFontSize * 1.5;

  const {
    data: orgDataStorageLocation,
    isLoading: isLoadingOrgDataStorageLocation,
    isError: isErrorDataStorageLocation,
  } = useGetOrganizationDataStorageLocationQuery(
    { organizationId },
    {
      skip:
        orgDisabled ||
        !hasAccess(
          "all",
          [permissions.ORG_MANAGEMENT_EDIT],
          getPermissionsFromUserRoles(userRoles)
        ),
    }
  );

  const {
    data: addressesData,
    isLoading: isLoadingAddresses,
    isError: isErrorAddresses,
  } = useGetAddressesByOrganizationIdQuery(
    { organizationId },
    { skip: !Boolean(organizationId) }
  );

  // Handlers
  const getAddress = () => {
    let address = {};

    if (addressesData && addressesData.length > 0) {
      address = addressesData[0];
    }

    return address;
  };

  // Other variables
  let transitionDirection = searchParams.get("direction");

  const { addressLine1, addressLine2, city, state, country } = getAddress();

  const dataStorageLocation = orgDataStorageLocation
    ? i18n.exists(orgDataStorageLocation.displayId)
      ? t(orgDataStorageLocation.displayId)
      : orgDataStorageLocation.name
    : "";

  return (
    <AppAccess>
      <Access all={[permissions["ORG_MANAGEMENT_EDIT"]]}>
        <ErrorHandling
          isLoading={
            isLoadingUserRoles ||
            isLoadingOrgDataStorageLocation ||
            isLoadingAddresses ||
            isLoadingOrganization ||
            isLoading ||
            isLoadingActualUsage
          }
          isError={
            isErrorUserRoles ||
            isErrorDataStorageLocation ||
            isErrorAddresses ||
            isErrorOrganization
          }
        >
          <PageTransition direction={transitionDirection}>
            <Box sx={{ paddingInline: "20px" }}>
              <Layer2Alerts organization={org} />
            </Box>

            <Grid sx={{ paddingInline: "20px" }} container columnSpacing={2}>
              <Grid item xs={desktopMatches ? 4 : 6}>
                <OrganizationDesktopCardWrapper
                  sx={{
                    width: "100%",
                    maxHeight: "210px",
                    padding: 0,
                    paddingBottom: "15px",
                    display: "flex",
                  }}
                >
                  <OrganizationHeaderDesktop organizationId={organizationId} />
                </OrganizationDesktopCardWrapper>
              </Grid>

              <Grid item xs={desktopMatches ? 4 : 6}>
                <OrganizationDesktopCardWrapper
                  sx={{
                    width: "100%",
                    maxHeight: "210px",
                    padding: 0,
                    paddingBottom: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                      paddingInline: "15px",
                    }}
                  >
                    <Box>
                      <PrimaryText variant="h5">
                        {getTranslation("YOUR_PACKAGE", t, i18n)}
                      </PrimaryText>
                    </Box>

                    {/* {hasAccess(
                      "all",
                      [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
                      getPermissionsFromUserRoles(userRoles)
                    ) && (
                      <Box>
                        <SecondaryContrastButton
                          id="edit-organization-subscription"
                          //onClick={handleOpen}
                          startIcon={getSvgIcon(
                            "EDIT",
                            editIconSize,
                            editIconSize,
                            theme.palette.secondary.contrastText
                          )}
                        >
                          {getTranslation("EDIT", t, i18n)}
                        </SecondaryContrastButton>
                      </Box>
                    )} */}
                  </Box>

                  {isRestricted ? (
                    <PrimaryText
                      sx={{ marginTop: "15px", whiteSpace: "normal" }}
                      align="center"
                    >
                      {getTranslation("YOU_ARE_RESTRICTED", t, i18n)}
                    </PrimaryText>
                  ) : (
                    <Box sx={{ marginTop: "15px", paddingInline: "15px" }}>
                      <PrimaryText
                        sx={{
                          color:
                            theme.palette.primary[
                              org?.subscription?.tier?.name
                            ],
                        }}
                        variant="h5"
                      >
                        {getTranslation(org?.subscription?.tier?.name, t, i18n)}
                      </PrimaryText>

                      {canFetchCurrentSubscription && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <Box>
                            {getSvgIcon(
                              "FORUM",
                              iconSize,
                              iconSize,
                              theme.palette.primary[
                                org?.subscription?.tier?.name
                              ]
                            )}
                          </Box>

                          <Box>
                            <PrimaryText>
                              {getTranslation("USERS_INCLUDED", t, i18n)}:{" "}
                              {usersSubscriptionValue}
                            </PrimaryText>
                          </Box>
                        </Box>
                      )}

                      {canFetchCurrentSubscription && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <Box>
                            {getSvgIcon(
                              "ASSETS",
                              iconSize,
                              iconSize,
                              theme.palette.primary[
                                org?.subscription?.tier?.name
                              ]
                            )}
                          </Box>

                          <Box>
                            <PrimaryText>
                              {getTranslation("ASSETS_INCLUDED", t, i18n)}:{" "}
                              {assetsSubscriptionValue}
                            </PrimaryText>
                          </Box>
                        </Box>
                      )}

                      {canFetchCurrentSubscription && (
                        <PrimaryText sx={{ marginTop: "10px" }}>
                          {`${getTranslation("VALID_UNTIL", t, i18n)} ${
                            subscription?.validUntil
                              ? localizeDate(
                                  subscription?.validUntil,
                                  user?.region
                                )
                              : "N/A"
                          }`}
                        </PrimaryText>
                      )}
                    </Box>
                  )}
                </OrganizationDesktopCardWrapper>
              </Grid>
              <Grid item xs></Grid>
            </Grid>

            <Grid
              sx={{ marginTop: "20px", paddingInline: "20px" }}
              container
              columnSpacing={2}
            >
              <Grid item xs={desktopMatches ? 8 : 12}>
                <OrganizationDesktopCardWrapper
                  sx={{ width: "100%", padding: 0, paddingBottom: "15px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                      paddingInline: "15px",
                    }}
                  >
                    <Box>
                      <PrimaryText variant="h5">
                        {getTranslation("ORGANIZATION_DETAILS", t, i18n)}
                      </PrimaryText>
                    </Box>

                    {hasAccess(
                      "all",
                      [permissions.ORG_MANAGEMENT_EDIT],
                      getPermissionsFromUserRoles(userRoles)
                    ) &&
                      !orgDisabled && (
                        <Box>
                          <SecondaryContrastButton
                            id="edit-organization-subscription"
                            onClick={handleNavigateToEdit}
                            startIcon={getSvgIcon(
                              "EDIT",
                              editIconSize,
                              editIconSize,
                              theme.palette.secondary.contrastText
                            )}
                          >
                            {getTranslation("EDIT", t, i18n)}
                          </SecondaryContrastButton>
                        </Box>
                      )}
                  </Box>

                  <Grid
                    sx={{ marginTop: "15px", paddingInline: "15px" }}
                    container
                    columnSpacing={2}
                  >
                    <Grid item xs={6}>
                      <Box>
                        <PrimaryText>
                          {getTranslation("ADDRESS_LINE_1", t, i18n)}:{" "}
                          {addressLine1}
                        </PrimaryText>

                        <PrimaryText sx={{ marginTop: "10px" }}>
                          {getTranslation("ADDRESS_LINE_2", t, i18n)}:{" "}
                          {Boolean(addressLine2) ? addressLine2 : "(Empty)"}
                        </PrimaryText>

                        <PrimaryText sx={{ marginTop: "10px" }}>
                          {getTranslation("STATE", t, i18n)}:{" "}
                          {Boolean(state) ? state : "(Empty)"}
                        </PrimaryText>

                        <PrimaryText sx={{ marginTop: "10px" }}>
                          {getTranslation("CITY", t, i18n)}: {city}
                        </PrimaryText>

                        <PrimaryText sx={{ marginTop: "10px" }}>
                          {getTranslation("COUNTRY", t, i18n)}: {country}
                        </PrimaryText>

                        <PrimaryText sx={{ marginTop: "10px" }}>
                          {getTranslation("CONTACT_PERSON", t, i18n)}:{" "}
                          {organizationData?.contactPerson}
                        </PrimaryText>

                        <PrimaryText sx={{ marginTop: "10px" }}>
                          {getTranslation("phone", t, i18n)}:{" "}
                          {organizationData?.contactPhone}
                        </PrimaryText>

                        <PrimaryText sx={{ marginTop: "10px" }}>
                          {getTranslation("CONTACT_EMAIL_ADDRESS", t, i18n)}:{" "}
                          {organizationData?.contactEmail}
                        </PrimaryText>
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <Box>
                        <PrimaryText>
                          {getTranslation("ORGANIZATION_NAME", t, i18n)}:{" "}
                          {organizationData?.name}
                        </PrimaryText>

                        <PrimaryText sx={{ marginTop: "10px" }}>
                          {getTranslation("NICKNAME", t, i18n)}:{" "}
                          {Boolean(organizationData?.nickname)
                            ? organizationData?.nickname
                            : "(Empty)"}
                        </PrimaryText>

                        {!orgDisabled &&
                          hasAccess(
                            "all",
                            [permissions.ORG_MANAGEMENT_EDIT],
                            getPermissionsFromUserRoles(userRoles)
                          ) && (
                            <PrimaryText sx={{ marginTop: "10px" }}>
                              {getTranslation("DATA_STORAGE_LOCATION", t, i18n)}
                              : {dataStorageLocation}
                            </PrimaryText>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                </OrganizationDesktopCardWrapper>
              </Grid>
            </Grid>

            <Grid sx={{ marginBlock: "20px", paddingInline: "20px" }} container>
              <Grid item xs={desktopMatches ? 8 : 12}>
                <OrganizationDesktopCardWrapper
                  sx={{ width: "100%", padding: 0 }}
                >
                  <Box sx={{ padding: "24px" }}>
                    {!orgDisabled &&
                    hasAccess(
                      "all",
                      [permissions.USER_MANAGEMENT_VIEW],
                      getPermissionsFromUserRoles(userRoles)
                    ) ? (
                      <OrganizationUsersCard organization={organizationData} />
                    ) : (
                      <PrimaryText sx={{ whiteSpace: "normal" }} align="center">
                        {getTranslation(
                          "CANNOT_VIEW_ORGANIZATION_USERS",
                          t,
                          i18n
                        )}
                      </PrimaryText>
                    )}
                  </Box>
                </OrganizationDesktopCardWrapper>
              </Grid>
            </Grid>
          </PageTransition>
        </ErrorHandling>
      </Access>
    </AppAccess>
  );
};

export default OrganizationDesktopDetails;
