import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import {
  useDeleteTypeMutation,
  useGetTypeDetailsQuery,
  useUpdateTypeMutation,
} from "../../../store/slices/api/typesApiSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  resetForm,
  selectIsFirstSubmitted,
  selectOriginalTypeInput,
  selectTypeInput,
  setIsFirstSubmitted,
  setOriginalTypeInput,
  setTypeInput,
} from "../../../store/slices/typeInputSlice";
import { messageError, messageSuccess } from "../../../util/notification";
import {
  checkTypeChanged,
  transformTypeInputRequest,
  transformTypeResponse,
  validateTypeForm,
} from "../../../util/type-utils";
import { getTranslation } from "../../../util/utils";
import {
  CancelButton,
  CreateButton,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { DialogPaperProps } from "../../styles/general/General.styles";
import TypeForm from "./TypeForm";
import TypeDelete from "./TypeDelete";
import ErrorHandling from "../../common/ErrorHandling";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { editItem } from "../../../store/slices/typeSlice";

const EditType = ({ typeId, open, setOpen, resourceTypeHandler }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const region = user?.region;
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);
  const typeInput = useSelector(selectTypeInput);
  const originalTypeInput = useSelector(selectOriginalTypeInput);

  // States
  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false);
  const [openDiscard, setOpenDiscard] = useState(false);

  // Mutations
  const [updateType, { isLoading }] = useUpdateTypeMutation();
  const [
    deleteType,
    { isSuccess: isSuccessDelete, isLoading: isLoadingDelete },
  ] = useDeleteTypeMutation();

  // Queries
  const {
    data: typeData,
    isLoading: isLoadingTypes,
    isError: isErrorTypes,
  } = useGetTypeDetailsQuery(
    {
      typeId,
      organizationId,
    },
    { skip: !typeId || isSuccessDelete || isLoadingDelete }
  );

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Other variables
  const typeFunction = allFunctionsData?.find(
    (f) => f.id === typeData?.functionId
  );

  const alert = {
    title: getTranslation("CANCEL_UPDATE", t, i18n),
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const checkHasChanged = useMemo(
    () => checkTypeChanged(originalTypeInput, typeInput, region),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [originalTypeInput, typeInput]
  );

  const isEditDisabled =
    isLoading ||
    isActionButtonDisabled ||
    !checkHasChanged ||
    !typeInput?.name ||
    !typeInput?.displayId ||
    !typeInput?.description ||
    !typeInput?.functionId;

  // Handlers
  const handleSubmit = async () => {
    dispatch(setIsFirstSubmitted(true));
    try {
      const { error: evaluatedError, firstError } = validateTypeForm(typeInput);
      const isValid = Object.keys(evaluatedError).length === 0;

      if (!isValid) {
        messageError(getTranslation(firstError, t, i18n));
        setIsActionButtonDisabled(true);
        return;
      }

      await updateType({
        typeInput: transformTypeInputRequest(typeInput, user?.region),
        organizationId: user?.organizations?.find((o) => o.default)?.id,
        typeId: typeInput?.id,
      }).unwrap();

      dispatch(
        editItem({
          id: typeInput?.id,
          newData: {
            name: typeInput?.name,
            displayId: typeInput?.displayId,
            functionId: typeInput?.functionId,
          },
        })
      );

      if (resourceTypeHandler) {
        resourceTypeHandler(typeInput.category, typeInput.functionId);
      }

      handleClose();
      messageSuccess(getTranslation("successfulUpdateType", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedUpdateType", t, i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Effects
  // Validation Form control
  useEffect(() => {
    if (!isFirstSubmitted) return;

    const { error: evaluatedError } = validateTypeForm(typeInput);
    const isValid = Object.keys(evaluatedError).length === 0;

    setIsActionButtonDisabled(!isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeInput]);

  // Set loaded type
  useEffect(() => {
    if (typeData) {
      const type = transformTypeResponse(typeData);

      dispatch(
        setTypeInput({
          ...type,
          functionId: typeFunction?.id,
          category: typeFunction?.category,
        })
      );

      dispatch(
        setOriginalTypeInput({
          ...type,
          functionId: typeFunction?.id,
          category: typeFunction?.category,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeData, typeFunction]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorHandling
      isLoading={
        isLoading || isLoadingDelete || isLoadingFunctions || isLoadingTypes
      }
      isError={isErrorFunctions || isErrorTypes}
    >
      <>
        <Dialog
          id="edit-type-dialog"
          fullWidth={true}
          maxWidth="md"
          PaperProps={DialogPaperProps}
          open={open}
          onClose={handleClose}
        >
          {tabletMatches && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Button
                  sx={{ color: theme.palette.secondary.contrastText }}
                  variant="text"
                  onClick={() => {
                    if (checkHasChanged) {
                      setOpenDiscard(true);
                      return;
                    }

                    handleClose();
                  }}
                >
                  {getTranslation("CANCEL", t, i18n)}
                </Button>
              </Box>

              <Box>
                <DialogTitle>
                  {getTranslation("EDIT_TYPE", t, i18n)}
                </DialogTitle>
              </Box>

              <Box>
                <Button
                  sx={{ color: theme.palette.secondary.contrastText }}
                  variant="text"
                  disabled={isEditDisabled}
                  onClick={handleSubmit}
                >
                  {getTranslation("DONE", t, i18n)}
                </Button>
              </Box>
            </Box>
          )}

          {desktopMatches && (
            <DialogTitle>{getTranslation("EDIT_TYPE", t, i18n)}</DialogTitle>
          )}

          <DialogContent>
            <ConfirmAlert
              isOpen={openDiscard}
              setIsOpen={setOpenDiscard}
              alert={alert}
              label="discard-changes"
              handleConfirm={handleClose}
            />
            <TypeForm mode="edit" />
            {typeData && <TypeDelete typeId={typeId} deleteType={deleteType} />}
          </DialogContent>

          {desktopMatches && (
            <DialogActions>
              <CancelButton
                variant="outlined"
                onClick={() => {
                  if (checkHasChanged) {
                    setOpenDiscard(true);
                    return;
                  }

                  handleClose();
                }}
              >
                {getTranslation("CANCEL", t, i18n)}
              </CancelButton>
              <CreateButton disabled={isEditDisabled} onClick={handleSubmit}>
                {getTranslation("DONE", t, i18n)}
              </CreateButton>
            </DialogActions>
          )}
        </Dialog>
      </>
    </ErrorHandling>
  );
};

export default EditType;
