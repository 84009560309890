import { INVITATION_STATUS } from "../components/InvitationForm";
import { USER_STATUS } from "./utils";

export const getMappedUsers = (organizationUsers, invitedUsers) => {
  const mappedOrganizationUsers = organizationUsers.map((user) => {
    const { id, firstName, lastName, email, userRoles } = user;
    const role = userRoles?.length > 0 ? userRoles[0] : null;
    const name = firstName && lastName ? `${firstName} ${lastName}` : email;

    return {
      id,
      name,
      email,
      role,
      roleName: role?.name,
      roles: userRoles,
      status: USER_STATUS.ACTIVE,
    };
  });

  const mappedInvitedUsers = invitedUsers
    .filter((iu) => iu.status === INVITATION_STATUS.SENT)
    .map((user) => {
      const { id, firstName, lastName, email, roles } = user;
      const role = roles?.length > 0 ? roles[0] : null;
      const name = firstName && lastName ? `${firstName} ${lastName}` : email;

      return {
        id,
        invitationId: id,
        name,
        email,
        role,
        roleName: role?.name,
        roles,
        status: USER_STATUS.PENDING,
      };
    });

  return [...mappedOrganizationUsers, ...mappedInvitedUsers];
};
