import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const InputContainer = styled("div")(({ isDesktop }) => ({
  minWidth: isDesktop ? 400 : 300,
  maxWidth: 400,
}));

export const ValidationText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  color: theme.palette.error.main,
}));

export const NamesGroupContainer = styled("div")({
  width: "100%",
});

export const AsterixText = styled("span")(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));
