import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import { delimiterLocalize, getTranslation } from "../../util/utils";
import {
  DarkBackgroundWrapper,
  ReportSecondaryText,
} from "../styles/home/ChartPage.styles";
import { ReportSecondaryGrowingText } from "../styles/reports/Reports.styles";
import LoadingSpinner from "../common/LoadingSpinner";

const ReportStatisticCard = ({
  displayId,
  onClick,
  title,
  min,
  max,
  average,
  isLoading,
}) => {
  //General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);

  return (
    <DarkBackgroundWrapper id={displayId} onClick={onClick}>
      {isLoading && <LoadingSpinner />}

      <ReportSecondaryText textAlign="center">{title}</ReportSecondaryText>

      {!isLoading && (
        <Stack justifyContent="end" height="100%">
          <Stack direction="row" alignItems="baseline">
            <ReportSecondaryGrowingText fontWeight="bold">
              {`${getTranslation("MIN", t, i18n)}.`}
            </ReportSecondaryGrowingText>

            <ReportSecondaryText component="label" variant="h2">
              {min.toLocaleString(delimiterLocalize(user?.region))}
            </ReportSecondaryText>
          </Stack>

          <Stack direction="row" alignItems="baseline">
            <ReportSecondaryGrowingText fontWeight="bold">
              {`${getTranslation("AVG", t, i18n)}.`}
            </ReportSecondaryGrowingText>

            <ReportSecondaryText component="label" variant="h2">
              {average.toLocaleString(delimiterLocalize(user?.region))}
            </ReportSecondaryText>
          </Stack>

          <Stack direction="row" alignItems="baseline">
            <ReportSecondaryGrowingText fontWeight="bold">
              {`${getTranslation("MAX", t, i18n)}.`}
            </ReportSecondaryGrowingText>

            <ReportSecondaryText component="label" variant="h2">
              {max.toLocaleString(delimiterLocalize(user?.region))}
            </ReportSecondaryText>
          </Stack>
        </Stack>
      )}
    </DarkBackgroundWrapper>
  );
};

export default ReportStatisticCard;
