import { useNavigate, useParams } from "react-router-dom";
import OrganizationEditDesktop from "../../../components/profile/OrganizationEditDesktop";
import ProfileDesktopHeader from "../../../navigation/header/profile/desktop/ProfileDesktopHeader";
import AppAccess from "../../../components/common/AppAccess";
import {
  getTranslation,
  permissions,
  transitionDirections,
} from "../../../util/utils";
import { useTranslation } from "react-i18next";
import Access from "../../../components/common/Access";
import { Box, useMediaQuery } from "@mui/material";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import OrganizationDetailsHeader from "../../../navigation/header/organization/OrganizationDetailsHeader";
import OrganizationEditMobile from "../../../components/profile/OrganizationEditMobile";
import Layer2AccessOrganization from "../../../components/common/Layer2AccessOrganization";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";

const OrganizationEdit = () => {
  // General hooks
  const { organizationId } = useParams();
  const { t, i18n } = useTranslation();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const redirectUri = "/profile/organization";
  const org = user?.organizations?.find((o) => o.id === organizationId);

  // Handlers
  const goBackHandler = () =>
    navigate(
      `/profile/organization/${organizationId}/more-details?direction=${transitionDirections.LEFT_TO_RIGHT}`
    );

  return (
    <AppAccess>
      <Access
        oneOf={[permissions.ORG_MANAGEMENT_EDIT]}
        redirectUri={redirectUri}
      >
        <Layer2AccessOrganization organization={org}>
          {mobileMatches ? (
            <Box>
              <OrganizationDetailsHeader
                goBackHandler={goBackHandler}
                title={getTranslation("DETAILS", t, i18n)}
              />

              <OrganizationEditMobile organizationId={organizationId} />
            </Box>
          ) : (
            <Box>
              <ProfileDesktopHeader />
              <OrganizationEditDesktop organizationId={organizationId} />
            </Box>
          )}
        </Layer2AccessOrganization>
      </Access>
    </AppAccess>
  );
};

export default OrganizationEdit;
