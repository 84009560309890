import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HomePagePadding } from "../../../components/styles/general/General.styles";
import { useDispatch, useSelector } from "react-redux";
import { selectTheme } from "../../../store/slices/appSlice";
import { selectUser, setUser } from "../../../store/slices/authSlice";
import { Box, useTheme } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { ValidationText } from "../../../components/styles/inputs/NamesGroup.styles";
import { messageError, messageSuccess } from "../../../util/notification";
import PageTransition from "../../../components/PageTransition";
import {
  transitionDirections,
  getTranslation,
  testInput,
  permissions,
  getPermissionsFromUserRoles,
  hasAccess,
} from "../../../util/utils";
import UpdateOrganizationPhoneHeader from "../../../navigation/header/organization/UpdateOrganizationPhoneHeader";
import {
  useGetOrganizationByIdQuery,
  useUpdateOrganizationMutation,
} from "../../../store/slices/api/organizationsApiSlice";
import Access from "../../../components/common/Access";
import ErrorHandling from "../../../components/common/ErrorHandling";
import AppAccess from "../../../components/common/AppAccess";
import useCheckOrganizationRestricted from "../../../hooks/useCheckOrganizationRestricted";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";

const UpdateOrganizationPhone = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { organizationId } = useParams();
  const [searchParams] = useSearchParams();

  // Selectors
  const user = useSelector(selectUser);
  const currentTheme = useSelector(selectTheme);

  // Other variables
  const org = user?.organizations?.find((o) => o.id === organizationId);

  const redirectUri =
    "/profile/organization/" +
    organizationId +
    `?direction=${transitionDirections.TOP_TO_BOTTOM}`;

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(org);

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isFetching: isFetchingUserRoles,
  } = useUserRolePermissionsQuery(
    {
      organizationId: org?.id,
      userId: user?.id,
    },
    { skip: !org?.id }
  );

  const { data, isLoading, isError } =
    useGetOrganizationByIdQuery(organizationId);

  // Mutations
  const [updateOrganization, { isLoading: isLoadingUpdateOrganization }] =
    useUpdateOrganizationMutation();

  // State
  const [organization, setOrganization] = useState({ contactPhone: "" });

  // Other variables
  const organizationPhoneValid = testInput.PHONE_NUMBER(
    organization.contactPhone
  );

  const isUpdateOrganizationRestricted = hasAccess(
    "all",

    [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
    getPermissionsFromUserRoles(userRoles)
  )
    ? false
    : isRestricted;

  // Handlers
  const handleSubmit = async () => {
    if (!testInput.PHONE_NUMBER(organization.contactPhone)) {
      messageError(getTranslation("phoneNumberValidation", t, i18n));
      return;
    }

    try {
      const data = await updateOrganization({
        organizationData: organization,
        organizationId: organizationId,
      }).unwrap();

      if (data) {
        let filteredOrganizations = user?.organizations?.filter(
          (o) => o.id !== data.id
        );

        filteredOrganizations.push(data);
        dispatch(
          setUser({
            ...user,
            organizations: filteredOrganizations,
          })
        );
      }

      messageSuccess(
        getTranslation("successfulUpdateOrganizationPhone", t, i18n)
      );

      navigate(redirectUri);
    } catch (error) {
      messageError(getTranslation("failedUpdateOrganizationPhone", t, i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const cancelHandler = () => {
    navigate(redirectUri);
  };

  const handleContactPhoneChange = (value) => {
    setOrganization({
      ...organization,
      contactPhone: value,
    });
  };

  // Effects
  useEffect(() => {
    if (data) {
      setOrganization({
        contactPhone: data.contactPhone,
      });
    }
  }, [data]);

  // Other variables
  const transitionDirection = searchParams.get("direction");

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={
          isLoadingUpdateOrganization || isLoading || isLoadingUserRoles
        }
        isError={isError}
      >
        <Access
          oneOf={[permissions.ORG_MANAGEMENT_EDIT]}
          redirectUri={redirectUri}
        >
          <PageTransition direction={transitionDirection}>
            <UpdateOrganizationPhoneHeader
              handleAction={handleSubmit}
              handleCancelAction={cancelHandler}
              disableDone={
                !organizationPhoneValid || isUpdateOrganizationRestricted
              }
            />
            <HomePagePadding>
              <div>
                {/* Organization Phone */}
                <PhoneInput
                  containerClass={currentTheme}
                  specialLabel={getTranslation("phoneNumber", t, i18n)}
                  inputClass={"phone-input"}
                  inputStyle={{
                    marginTop: "15px",
                    backgroundColor: theme.palette.primary.contrastText,
                    color: theme.palette.text.secondary,
                    width: "100%",
                  }}
                  buttonStyle={{ marginTop: "15px" }}
                  dropdownStyle={{
                    backgroundColor: theme.palette.primary.contrastText,
                    color: theme.palette.primary.main,
                  }}
                  id="phone-input"
                  placeholder={getTranslation("phoneNumber", t, i18n)}
                  value={organization.contactPhone}
                  onChange={handleContactPhoneChange}
                  isValid={organizationPhoneValid}
                  defaultErrorMessage={getTranslation("phoneNumber", t, i18n)}
                  error={!organizationPhoneValid}
                  inputProps={{
                    "data-testid": "organization-phone-input-onboarding",
                  }}
                />
                <Box>
                  {!organizationPhoneValid && (
                    <ValidationText variant="body5" align="center">
                      {getTranslation("phoneNumberValidation", t, i18n)}
                    </ValidationText>
                  )}
                </Box>
              </div>
            </HomePagePadding>
          </PageTransition>
        </Access>
      </ErrorHandling>
    </AppAccess>
  );
};

export default UpdateOrganizationPhone;
