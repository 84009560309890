import React from "react";
import {
  DoughnutStack,
  StyledDoughnut,
} from "../../../styles/reports/ChartWidgets.styles";

const DonutChartWidgetContent = ({ data }) => {
  return (
    <DoughnutStack id="donut-chart-widget">
      <StyledDoughnut
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: function (context) {
                  const labelIndex =
                    context[0].datasetIndex * context[0].dataset.data.length +
                    context[0].dataIndex;

                  return (
                    context[0].chart.data.labels[labelIndex] +
                    ": " +
                    context[0].formattedValue
                  );
                },
              },
            },
          },
        }}
        data={data}
      />
    </DoughnutStack>
  );
};

export default DonutChartWidgetContent;
