import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { getTranslation } from "../../../util/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  editResultField,
  removeResultField,
  selectFurtherResultFields,
  selectGroupResultFields,
  selectReportingFields,
  selectResultFields,
  setShouldExecute,
} from "../../../store/slices/reportsSlice";
import { Draggable } from "react-beautiful-dnd";
import { AGGREGATION_TYPES } from "../../../util/reports-utils";
import {
  CustomValueEditorAutocomplete,
  CustomValueEditorTextField,
  DeleteFieldButtonWrapper,
  DragIndicatorIconWrapper,
  FieldsStack,
  ReportsSecondaryText,
  ResultCheckboxDesktop,
  ResultFieldsListItemGridContainer,
} from "../../styles/reports/Reports.styles";
import { useState } from "react";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { getSvgIcon } from "../../../util/icons";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const options = AGGREGATION_TYPES.map((item) => item.name);

const ResultFieldsListItem = ({ resultField, index, isAggregationField }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const reportingFields = useSelector(selectReportingFields);
  const allResultFields = useSelector(selectResultFields);
  const resultFields = useSelector(selectFurtherResultFields);
  const groupFields = useSelector(selectGroupResultFields);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  const [openConfirm, setOpenConfirm] = useState(false);

  // Handlers
  const handleEditResultFieldDisplayName = (e) => {
    const displayName = e.target.value;
    dispatch(editResultField({ ...resultField, displayName }));
    dispatch(setShouldExecute(false));
  };

  const handleEditResultFieldField = (e) => {
    const field = e.target.value;

    dispatch(
      editResultField({
        ...resultField,
        field,
        displayName: getTranslation(field, t, i18n),
      })
    );

    dispatch(setShouldExecute(false));
  };

  const handleEditResultFieldOrder = (e) => {
    const orderIndex = e.target.value;

    if (!resultField.orderIndex || resultField.orderIndex < 0) {
      dispatch(
        editResultField({ ...resultField, orderIndex, sortMethod: "asc" })
      );
    } else {
      dispatch(editResultField({ ...resultField, orderIndex }));
    }

    dispatch(setShouldExecute(false));
  };

  const handleEditResultFieldSortMethod = (e) => {
    const sortMethod = e.target.value;
    dispatch(editResultField({ ...resultField, sortMethod }));
    dispatch(setShouldExecute(false));
  };

  const handleEditResultFieldAggregationType = (event, newValue) => {
    const aggregationTypes = newValue;
    dispatch(editResultField({ ...resultField, aggregationTypes }));
    dispatch(setShouldExecute(false));
  };

  const handleRemoveResultField = () => {
    dispatch(removeResultField(resultField.uniqueId));
    dispatch(setShouldExecute(false));
  };

  const handleReturnCountForColumnsMenuItems = () => {
    const menuItems = [];

    for (let i = 1; i <= resultFields.length; i++) {
      menuItems.push(i);
    }

    return menuItems;
  };

  const handleReturnCountForGroupingMenuItems = () => {
    const menuItems = [];

    for (let i = 1; i <= groupFields.length; i++) {
      menuItems.push(i);
    }

    return menuItems;
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    handleRemoveResultField();
    setOpenConfirm(false);
  };

  // Other variables
  const alert = {
    content: getTranslation("REMOVE_RESULT_FIELD_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("REMOVE_RESULT_FIELD_ALERT_TITLE", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };
  const iconSize = globalFontSize * 1.2;

  return (
    <>
      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={alert}
        label="delete-filter-group"
        handleConfirm={handleConfirm}
      />

      <Draggable
        key={resultField.uniqueId}
        draggableId={resultField.uniqueId}
        index={index}
      >
        {(provided) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <ResultFieldsListItemGridContainer
              alignItems="center"
              container
              columns={22}
              spacing={2}
            >
              <Grid item xs={1}>
                <DragIndicatorIconWrapper>
                  {getSvgIcon(
                    "DRAG_POINT",
                    iconSize,
                    iconSize,
                    theme.palette.primary.main
                  )}
                </DragIndicatorIconWrapper>
              </Grid>

              <Grid item xs={5}>
                <FieldsStack rowGap={2}>
                  <ReportsSecondaryText variant="caption">
                    {getTranslation("CHARACTERISTIC", t, i18n)}
                  </ReportsSecondaryText>

                  <Select
                    fullWidth
                    labelId="characteristic-select-label"
                    id="characteristic-select"
                    label={getTranslation("FIELD_SELECT_LABEL", t, i18n)}
                    variant="standard"
                    value={resultField.field}
                    onChange={handleEditResultFieldField}
                  >
                    {reportingFields.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.name}
                        disabled={allResultFields.some(
                          (r) => r.field === option.name
                        )}
                      >
                        {getTranslation(option.label, t, i18n)}
                      </MenuItem>
                    ))}
                  </Select>
                </FieldsStack>
              </Grid>

              <Grid item xs={5}>
                <FieldsStack rowGap={2}>
                  <ReportsSecondaryText variant="caption">
                    {getTranslation("COLUMN_TITLE", t, i18n)}
                  </ReportsSecondaryText>

                  <CustomValueEditorTextField
                    id="column-title"
                    name="column-title"
                    placeholder={getTranslation("COLUMN_TITLE", t, i18n)}
                    type="text"
                    variant="standard"
                    fullWidth
                    value={resultField.displayName}
                    onChange={handleEditResultFieldDisplayName}
                    error={!resultField.displayName}
                  />
                </FieldsStack>
              </Grid>

              <Grid item xs={4}>
                <FieldsStack rowGap={2}>
                  <ReportsSecondaryText variant="caption">
                    {resultField.usedForGrouping || groupFields?.length === 0
                      ? getTranslation("SORTING", t, i18n)
                      : getTranslation("SORTING_INSIDE_GROUP", t, i18n)}
                  </ReportsSecondaryText>

                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Select
                        fullWidth
                        labelId="sort-index-select-label"
                        id="sort-index"
                        variant="standard"
                        value={resultField.orderIndex}
                        onChange={handleEditResultFieldOrder}
                      >
                        <MenuItem disabled value={null}>
                          -
                        </MenuItem>

                        {resultField.usedForGrouping
                          ? handleReturnCountForGroupingMenuItems().map(
                              (item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              )
                            )
                          : handleReturnCountForColumnsMenuItems().map(
                              (item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              )
                            )}
                      </Select>
                    </Grid>

                    <Grid item xs>
                      <Select
                        fullWidth
                        labelId="sort-type-select-label"
                        id="sort-type-select"
                        variant="standard"
                        value={resultField.sortMethod}
                        onChange={handleEditResultFieldSortMethod}
                        disabled={!resultField.orderIndex}
                        error={
                          Boolean(resultField.orderIndex) &&
                          !resultField.sortMethod
                        }
                      >
                        <MenuItem disabled value={null}>
                          -
                        </MenuItem>
                        <MenuItem value={"asc"}>A to Z</MenuItem>
                        <MenuItem value={"desc"}>Z to A</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </FieldsStack>
              </Grid>

              <Grid item xs>
                {isAggregationField && (
                  <FieldsStack rowGap={2}>
                    <ReportsSecondaryText variant="caption">
                      {getTranslation("FUNCTION_FOR_GROUP", t, i18n)}
                    </ReportsSecondaryText>

                    <CustomValueEditorAutocomplete
                      fullWidth
                      multiple
                      id="function"
                      options={options}
                      disableCloseOnSelect
                      getOptionLabel={(option) =>
                        getTranslation(option, t, i18n)
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <ResultCheckboxDesktop
                            icon={getSvgIcon(
                              "CHECKBOX",
                              iconSize,
                              iconSize,
                              theme.palette.primary.main
                            )}
                            checkedIcon={getSvgIcon(
                              "CHECKBOX_DONE",
                              iconSize,
                              iconSize,
                              theme.palette.primary.main
                            )}
                            checked={selected}
                          />

                          {getTranslation(option, t, i18n)}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) =>
                          index >= tagValue?.length - 1
                            ? getTranslation(option, t, i18n)
                            : getTranslation(option, t, i18n) + ", "
                        );
                      }}
                      value={resultField.aggregationTypes}
                      onChange={handleEditResultFieldAggregationType}
                    />
                  </FieldsStack>
                )}
              </Grid>

              <Grid item>
                <DeleteFieldButtonWrapper>
                  <IconButton
                    id="delete-result-field"
                    onClick={handleOpenConfirm}
                  >
                    {getSvgIcon(
                      "DELETE",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.contrastText
                    )}
                  </IconButton>
                </DeleteFieldButtonWrapper>
              </Grid>
            </ResultFieldsListItemGridContainer>
          </div>
        )}
      </Draggable>
    </>
  );
};

export default ResultFieldsListItem;
