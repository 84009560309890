import { Box, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";
import DoubleValueWidgetContent from "../categorized-widgets/DoubleValueWidgetContent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  addWidget,
  selectResultFields,
  selectWidgetById,
  setWidgetAggregations,
} from "../../../../store/slices/reportsSlice";
import {
  constructAggregations,
  getMeasurementUnitForField,
  WIDGET_TYPE,
} from "../../../../util/reports-utils";
import { DragDropContext } from "react-beautiful-dnd";
import WidgetAggregationsList from "../WidgetAggregationsList";
import {
  WidgetContainer,
  WidgetTitle,
  WidgetWrapper,
} from "../../../styles/reports/ChartWidgets.styles";
import { measurementDisplay } from "../../../../util/utils";
import { selectUser } from "../../../../store/slices/authSlice";

const DoubleValueWidgetForm = ({ rows }) => {
  // General hooks
  const dispatch = useDispatch();

  // Selectors
  const user = useSelector(selectUser);
  const resultFields = useSelector(selectResultFields);
  const widget = useSelector((state) =>
    selectWidgetById(state, WIDGET_TYPE.DOUBLE_VALUE)
  );

  // Other variables
  const finalAggregations = constructAggregations(resultFields);

  // States
  const [title, setTitle] = useState(widget?.title);
  const [displayHome, setDisplayHome] = useState(false);

  // Other variables
  const dataSources = widget?.dataSources ?? [];

  const aggregationIds =
    dataSources?.map((ds) => ds.generatedAggregationId) ?? [];

  const value1AggregationId =
    aggregationIds?.length > 0 ? aggregationIds[0] : "";

  const value2AggregationId =
    aggregationIds?.length > 1 ? aggregationIds[1] : "";

  const value1Aggregation = finalAggregations?.find(
    (a) => a.id === value1AggregationId
  );

  const value1Key =
    value1Aggregation?.field?.name +
    `.aggregation-${value1Aggregation?.aggregationType?.toLowerCase()}`;

  const value1Row = rows?.length > 0 ? rows[0] : {};
  const value1AggregationValue = value1Row[value1Key];

  const value2Aggregation = finalAggregations?.find(
    (a) => a.id === value2AggregationId
  );

  const value2Key =
    value2Aggregation?.field?.name +
    `.aggregation-${value2Aggregation?.aggregationType?.toLowerCase()}`;

  const value2Row = rows?.length > 0 ? rows[0] : {};
  const value2AggregationValue = value2Row[value2Key];

  const { unit: unit1, value: convertedValue1 } = measurementDisplay({
    value: value1AggregationValue,
    unit: getMeasurementUnitForField(
      value1Aggregation?.field?.name,
      value1Aggregation?.aggregationType
    ),
    region: user?.region,
  });

  const unit1Label = unit1 ? ` (in ${unit1})` : "";
  const final1Value = convertedValue1 + unit1Label;

  const { unit: unit2, value: convertedValue2 } = measurementDisplay({
    value: value2AggregationValue,
    unit: getMeasurementUnitForField(
      value2Aggregation?.field?.name,
      value2Aggregation?.aggregationType
    ),
    region: user?.region,
  });

  const unit2Label = unit2 ? ` (in ${unit2})` : "";
  const final2Value = convertedValue2 + unit2Label;

  // Handlers
  const handleChangeTitle = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    dispatch(addWidget({ ...widget, title: newTitle }));
  };

  const handleChangeDisplayHome = (e) => {
    const newDisplayHome = e.target.checked;
    setDisplayHome(newDisplayHome);
  };

  const handleRearangeArr = (arr, sourceIndex, destIndex) => {
    const arrCopy = [...arr];
    const [removed] = arrCopy.splice(sourceIndex, 1);
    arrCopy.splice(destIndex, 0, removed);

    return arrCopy;
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const items = handleRearangeArr(
      widget?.dataSources,
      source.index,
      destination.index
    );

    dispatch(
      setWidgetAggregations({ widgetTypeId: WIDGET_TYPE.DOUBLE_VALUE, items })
    );
  };

  return (
    <Stack spacing={3}>
      <Box>
        <SecondaryText gutterBottom>Configure Double Value</SecondaryText>
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            label="Title"
            id="title"
            value={title}
            size="small"
            onChange={handleChangeTitle}
          />
        </Box>
      </Box>

      <DragDropContext onDragEnd={handleDragEnd}>
        <WidgetAggregationsList widgetTypeId={WIDGET_TYPE.DOUBLE_VALUE} />
      </DragDropContext>

      <Box>
        <SecondaryText gutterBottom>Preview</SecondaryText>
        <WidgetWrapper>
          <WidgetContainer>
            <WidgetTitle variant="body2" textAlign="center">
              {title}
            </WidgetTitle>

            <DoubleValueWidgetContent
              value1={final1Value}
              value2={final2Value}
              convertToUnit={true}
            />
          </WidgetContainer>
        </WidgetWrapper>
      </Box>
    </Stack>
  );
};

export default DoubleValueWidgetForm;
